import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, Grid, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CustomFieldValidatorState,
  FieldValidator,
  NumberUtils,
  Text,
} from '@pbt/pbt-ui-components'

import { ModifiedChargeSheetLineItemInput } from '~/api/graphql/generated/types'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import { discountEditRoleBlacklist } from '~/constants/roleNames'
import {
  editChargeSheetSectionPercentageDiscount,
  fetchClientFinanceCharges,
  getChargeSheetSubItemById,
  getClientFinanceLoading,
  updateSectionAdditionalDiscount,
} from '~/store/duck/clientFinanceData'
import { getCurrentUserId } from '~/store/reducers/auth'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useHasRole from '~/utils/useHasRole'

import { formatMoneyRange } from '../../invoices/invoiceUtils'
import DiscountItems from './DiscountItems'

const useStyles = makeStyles(
  (theme) => ({
    collapse: {
      paddingLeft: theme.spacing(4),
    },
    badgeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      padding: 0,
    },
    editIcon: {
      fontSize: '2rem',
      color: theme.colors.editIconColor,
    },
  }),
  { name: 'Discounts' },
)

type DiscountsProp = {
  isGlobalFooter?: boolean
  sectionId?: string
}

const Discounts = ({ isGlobalFooter, sectionId }: DiscountsProp) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])
  const dispatch = useDispatch()

  const modifierId = useSelector(getCurrentUserId)
  const chargeSheetItem = useSelector(getChargeSheetSubItemById(sectionId))
  const [openMissingSectionIdDialog] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const [totalExpanded, setTotalExpanded] = useState(false)

  const canEditDiscount = !useHasRole(discountEditRoleBlacklist)

  const fetchAfterPercentageDiscountChanged = useCloseAfterCreation(
    () =>
      chargeSheetItem &&
      dispatch(fetchClientFinanceCharges({ id: chargeSheetItem.clientId })),
    getClientFinanceLoading,
  )

  if (!chargeSheetItem) {
    return null
  }

  const {
    additionalDiscount,
    bundleAdditionalDiscount,
    discount,
    estimateAdditionalDiscount,
    itemDiscount,
    modificationDate,
    groupedItems,
    subtotal,
    totalTax,
  } = chargeSheetItem

  const handleSaveAdittionalDiscount = (additionalDiscountValue: number) => {
    if (!modifierId || !sectionId) {
      return
    }
    if (sectionId.includes('_')) {
      openMissingSectionIdDialog({
        message: t('Invoices:ITEM_DISCOUNT_MISSING_SECTION_ID'),
      })
      return
    }
    dispatch(
      updateSectionAdditionalDiscount({
        modifierId,
        updateSectionsDiscountInputs: [
          {
            id: sectionId,
            additionalDiscount: additionalDiscountValue,
            expectedModificationDate: modificationDate,
          },
        ],
      }),
    )
  }

  const handleSaveItemsPercentageDiscount = (discountPerc: number) => {
    if (groupedItems) {
      const flattenGrouppedItems = groupedItems.flatMap(
        (item) => R.prop('items', item) || item,
      )
      fetchAfterPercentageDiscountChanged()
      dispatch(
        editChargeSheetSectionPercentageDiscount({
          updateItemInput: flattenGrouppedItems.map(
            ({
              id,
              modificationDate: modificationDateProp,
              soapLogModificationDate,
            }) =>
              ({
                discountPerc: discountPerc ? discountPerc / 100 : 0,
                id,
                expectedModification: modificationDateProp,
                expectedSoapLogModification:
                  soapLogModificationDate || modificationDateProp,
              }) as ModifiedChargeSheetLineItemInput,
          ),
        }),
      )
    }
  }

  const getFormattedItemDiscount = (discountValue: number | undefined) =>
    discountValue
      ? discountValue > 0
        ? `${NumberUtils.formatMoney(discountValue, true)}`
        : NumberUtils.formatMoney(discountValue).replace(/[()]/g, '')
      : 0

  const additionalDiscountValidator = {
    validator: ({ state, name }: CustomFieldValidatorState) =>
      state[name] >=
        -1 * (estimateAdditionalDiscount + bundleAdditionalDiscount) &&
      state[name] <=
        subtotal +
          totalTax -
          (itemDiscount +
            estimateAdditionalDiscount +
            bundleAdditionalDiscount),
    validatorName: 'additionalDiscountValidator',
  } as FieldValidator

  const discounts = [
    {
      id: ConfirmAlertType.ITEM_DISCOUNT,
      name: t('Invoices:ITEM_DISCOUNT'),
      value: getFormattedItemDiscount(itemDiscount),
      editable: canEditDiscount,
      tooltipText: null,
      description: t('Invoices:ITEM_DISCOUNT_POPER_DESCRIPTION'),
      confirmationDialogText: t('Invoices:ITEM_DISCOUNT_DIALOG_CONFIRMATION'),
      InputProps: {
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      },
      save: handleSaveItemsPercentageDiscount,
    },
    {
      id: 'estimateDiscount',
      name: t('Invoices:ESTIMATE_DISCOUNT'),
      value: getFormattedItemDiscount(estimateAdditionalDiscount),
      editable: canEditDiscount,
      tooltipText: t('Invoices:ESTIMATE_DISCOUNT_TOOLTIP'),
    },
    {
      id: 'bundleDiscount',
      name: t('Invoices:BUNDLE_DISCOUNT'),
      value: getFormattedItemDiscount(bundleAdditionalDiscount),
      editable: canEditDiscount,
      tooltipText: t('Invoices:BUNDLE_DISCOUNT_TOOLTIP'),
    },
    {
      id: ConfirmAlertType.ADDITIONAL_DISCOUNT,
      name: t('Invoices:ADDITIONAL_DISCOUNT'),
      value: getFormattedItemDiscount(additionalDiscount),
      editable: canEditDiscount,
      tooltipText: null,
      description: '',
      confirmationDialogText: t(
        'Invoices:ADDITIONAL_DISCOUNT_DIALOG_CONFIRMATION',
      ),
      InputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      },
      save: handleSaveAdittionalDiscount,
      customValidators: [additionalDiscountValidator],
    },
  ]

  return (
    <Grid
      container
      flexDirection={totalExpanded ? 'column' : 'row'}
      ml={isGlobalFooter ? 0 : -3}
    >
      {discounts.length > 0 && (
        <Collapse unmountOnExit className={classes.collapse} in={totalExpanded}>
          <DiscountItems discounts={discounts} />
        </Collapse>
      )}
      <Grid
        container
        item
        justifyContent="space-between"
        onClick={() => !isGlobalFooter && setTotalExpanded(!totalExpanded)}
      >
        <Grid container item md={6} sm={6} wrap="nowrap" xl={6} xs={6}>
          {isGlobalFooter ? null : totalExpanded ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowRight />
          )}
          <Text variant="subheading3">{t('Common:DISCOUNTS')}</Text>
        </Grid>
        <Text variant="subheading3">
          {discount
            ? discount > 0
              ? `${formatMoneyRange(discount, true)}`
              : formatMoneyRange(discount).replace(/[()]/g, '')
            : 0}
        </Text>
      </Grid>
    </Grid>
  )
}

export default Discounts
