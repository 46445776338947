import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'

import AlertLabel from '~/components/common/labels/AlertLabel'
import InvoiceType from '~/constants/InvoiceType'
import { InvoiceViewStates } from '~/constants/refund'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import {
  getInvoiceV3,
  getInvoiceV3Error,
  getInvoiceV3Loading,
  getInvoiceV3SubItemsMap,
} from '~/store/reducers/invoiceV3'
import { CustomNavigationPrevState } from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import { isRefundInvoice } from '~/utils/refundUtils'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import ChargesHeader from '../components/ChargesHeader'
import { InvoiceChewyItemsSection } from './InvoiceChewyItemsSection'
import { InvoiceDetails } from './InvoiceDetails'
import { InvoiceDetailsForRefund } from './InvoiceDetailsForRefund'
import InvoiceFooter from './InvoiceFooter'
import { InvoiceFooterForRefunds } from './InvoiceFooterForRefund'
import { RefundContextProvider } from './RefundContext'

const useStyles = makeStyles(() => ({
  alert: {
    height: 32,
  },
  alertLink: {
    color: 'inherit',
  },
}))

const ViewInvoice = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { invoiceId } = useParams()
  const classes = useStyles()

  const { fromParams } = (location.state as CustomNavigationPrevState) || {}
  const { soapId } = fromParams || {}

  const { t } = useTranslation(['Common', 'Payments'])

  const [viewState, setViewState] = useState<InvoiceViewStates>(
    InvoiceViewStates.DEFAULT,
  )
  const isInvoiceLoading = useSelector(getInvoiceV3Loading)
  const isError = useSelector(getInvoiceV3Error)
  const subItemsMap = useSelector(getInvoiceV3SubItemsMap)

  const invoice = useSelector(getInvoiceV3(invoiceId)) as InvoiceV3
  const isInvoiceCVCLayout = useIsChewyCheckoutEnabled()

  const isRefund = isRefundInvoice(invoice?.invoiceNo)

  const isChargeSheet = invoice?.type === InvoiceType.CHARGE_SHEET

  const soapGroupId =
    invoice?.groups?.find((groupId: string) => {
      const group = subItemsMap[groupId]
      return group?.soapId === soapId
    }) || ''

  useWSTopic({
    wsTopic: WSTopics.INVOICE,
    context: {
      retrySubscription: false,
      topicParams: {
        invoiceId,
      },
      actionParams: {
        sessionId: null,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.INVOICE_UNPOSTED,
    context: {
      retrySubscription: false,
      topicParams: {
        invoiceId,
      },
      actionParams: {
        sessionId: null,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGE_SHEET_SPECIAL_PROCEDURE,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId: invoice?.clientId,
      },
    },
    unsubscribe: true,
  })

  useEffect(() => {
    if (isChargeSheet) {
      navigate(`/chargesheet/${invoice.clientId}`)
    }
  }, [isChargeSheet])

  useEffect(() => {
    if (isRefund && viewState === InvoiceViewStates.DEFAULT) {
      setViewState(InvoiceViewStates.REFUND_INVOICE)
    }
  }, [isRefund, viewState])

  const handleSwitchInvoiceViewState = (newViewState: InvoiceViewStates) => {
    setViewState(newViewState)
  }

  const refundView =
    viewState === InvoiceViewStates.REFUND_LANDING ||
    viewState === InvoiceViewStates.REFUND_SUMMARY ||
    viewState === InvoiceViewStates.REFUND_INVOICE

  const titlePrefix =
    viewState === InvoiceViewStates.DEFAULT
      ? t('Common:INVOICE')
      : viewState === InvoiceViewStates.REFUND_LANDING
        ? t('Invoices:REFUND_LANDING_TITLE')
        : viewState === InvoiceViewStates.REFUND_SUMMARY
          ? t('Invoices:REFUND_SUMMARY_TITLE')
          : t('Common:REFUND') // Refund invoice
  t('Invoices:ALERT.PENDING_REFUND')
  return (
    <>
      <ChargesHeader
        isInvoice
        clientId={invoice?.clientId}
        headerAlert={
          invoice?.pendingRefundInvoice ? (
            <Grid item xs={4}>
              <AlertLabel
                className={classes.alert}
                message={
                  <Trans
                    components={{
                      linkToRefund: (
                        <Link
                          className={classes.alertLink}
                          to={`/refund/${invoice.pendingRefundInvoice.id}`}
                        />
                      ),
                    }}
                    i18nKey="Invoices:ALERT.PENDING_REFUND"
                    values={{
                      invoice: invoice.pendingRefundInvoice.invoiceNo,
                    }}
                  />
                }
                variant="warning"
              />
            </Grid>
          ) : undefined
        }
        isError={Boolean(isError)}
        isLoading={isInvoiceLoading}
        soapClientId={subItemsMap[soapGroupId]?.clientId}
        soapPatientId={subItemsMap[soapGroupId]?.patientId}
        title={`${titlePrefix} ${invoice?.invoiceNo || ''}`}
        useInvoiceCVCLayout={isInvoiceCVCLayout}
      />
      {viewState === InvoiceViewStates.DEFAULT && !isRefund && (
        <>
          <InvoiceDetails useInvoiceCVCLayout={isInvoiceCVCLayout} />
          {isInvoiceCVCLayout && <InvoiceChewyItemsSection />}
          <InvoiceFooter
            onSwitchInvoiceViewState={handleSwitchInvoiceViewState}
          />
        </>
      )}
      {refundView && (
        <RefundContextProvider invoiceId={invoiceId} viewState={viewState}>
          <>
            <InvoiceDetailsForRefund />
            <InvoiceFooterForRefunds
              onSwitchInvoiceViewState={handleSwitchInvoiceViewState}
            />
          </>
        </RefundContextProvider>
      )}
    </>
  )
}

export default ViewInvoice
