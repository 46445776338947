/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import {
  AddressUtils,
  DateUtils,
  DrugDeliveryAction,
  LanguageUtils,
  Nil,
  PhoneUtils,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { SentenceFormatter } from '@pbt/pbt-ui-components/src/types'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import {
  getHighValue,
  isRetailOrderLineItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import {
  getLengthOfCourseString,
  getPrescriptionQuantityUnits,
  getVariationField,
} from '~/components/dashboard/soap/utils/prescriptionUtils'
import { getFirstTabOccurrenceUrl } from '~/components/dashboard/soapV2/utils/useSoapPageRedirect'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { InventoryCategoryName } from '~/constants/inventoryCategory'
import { MESSAGE_CAN_NOT_REMOVE_PARENT_PRESCRIPTION } from '~/constants/prescription'
import {
  DeclinableLabTestsStates,
  DeletableLabOrderStates,
  InventoryItemState,
  LabTestState,
  OrderType,
  PrescriptionItemState,
  ProcedureState,
} from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import { useCreateOrUpdatePrescription } from '~/store/hooks/prescription'
import {
  CRUDOrderOptions,
  GlobalInventoryCatalogItem,
  InventoryItem,
  InvoiceLineItem,
  Order,
  OrderCallbackWithTypeCheck,
  PendingPrescriptionParams,
  Prescription,
  PrescriptionAdministrationInfo,
  PrescriptionPrintInfo,
  SaveOrderHandler,
  SoapWidgetName,
  Variation,
} from '~/types'
import {
  addOriginalBusinessId,
  getFullBreedName,
  getManualInputSelectValue,
  getUrlSearchParam,
  joinString,
} from '~/utils'
import {
  findOrderItemByItem,
  getInventoryItemCategoryId,
  getIsRefill,
  getMissingPdmpReportingFields,
  getOrdersEqual,
  getOrderStateKey,
  getOrderWithNotes,
  isBundle,
  isLabTestUnderOrder,
  isProcedure,
  isWellnessPlanItem,
  zeroUsedInvoiceItemExists,
} from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import {
  clearCurrentUnifiedOrder,
  clearOrderValidationErrorMessage,
  createOrder,
  fetchUnifiedOrder,
  orderBundle,
  partialEditOrder,
  removeOrder,
  removeOrderUpdatePatient,
  removeUnifiedOrder,
  unorderBundle,
} from '../actions/orders'
import {
  getCurrentChargeSheetLineItem,
  getEditingLineItemState,
} from '../duck/clientFinanceData'
import { getHasOpenDialogs } from '../duck/dialogs'
import { registerWarnAlert } from '../duck/uiAlerts'
import {
  getBreed,
  getDrugAdministrationFrequencies,
  getDrugDeliveryMethod,
  getDrugStrengthColor,
  getDrugStrengthUnit,
  getEventType,
  getFeatureToggle,
  getInventoryCategory,
  getInventoryProductSizeUnit,
  getInventoryStatuses,
  getLabOrderStatuses,
  getLabTestsStates,
  getPrescriptionProductSizeUnit,
  getPrescriptionStates,
  getProcedureStatus,
  getProductForm,
  getProductSizeUnit,
  getSpecies,
  getStaticSoapWidget,
} from '../reducers/constants'
import { getAppointmentLineItemsMap } from '../reducers/finance'
import {
  getOrdersIsSending,
  getOrdersIsSendingOrReceiving,
  getOrdersSearchResults,
  getPendingSelectionOrders,
  getPendingUnSelectionOrders,
  getSelectedOrder,
  getSelectedOrders,
  getUnassignedDoctorOrderError,
  getUnifiedOrder,
  getUnifiedOrderIsLoading,
} from '../reducers/orders'
import { getSoapBusinessId, getSoapId } from '../reducers/soap'
import {
  getSoapActiveTemplateTabs,
  getSoapUncoveredWidgetIds,
} from '../reducers/soapV2'
import { getUser } from '../reducers/users'
import { useVariationWeightString } from './prescriptions'

export const useIsOrderDeclined = (order: Order | null) => {
  const LabTestStates = useSelector(getLabTestsStates)
  const ProcedureStatus = useSelector(getProcedureStatus)
  const PrescriptionStates = useSelector(getPrescriptionStates)
  const InventoryStatuses = useSelector(getInventoryStatuses)

  if (R.isNil(order)) {
    return false
  }

  const InventoryDeclinedState = Utils.findConstantIdByName(
    InventoryItemState.DECLINED,
    InventoryStatuses,
  )
  const ProcedureDeclinedState = Utils.findConstantIdByName(
    ProcedureState.DECLINED,
    ProcedureStatus,
  )
  const LabTestDeclinedState = Utils.findConstantIdByName(
    LabTestState.DECLINED,
    LabTestStates,
  )
  const PrescriptionDeclinedState = Utils.findConstantIdByName(
    PrescriptionItemState.DECLINED,
    PrescriptionStates,
  )

  const DeclinedStatesMap: Partial<Record<OrderType, string>> = {
    [OrderType.INVENTORY]: InventoryDeclinedState,
    [OrderType.PROCEDURE]: ProcedureDeclinedState,
    [OrderType.LAB_TEST]: LabTestDeclinedState,
    [OrderType.PRESCRIPTION]: PrescriptionDeclinedState,
  }

  const stateKey = getOrderStateKey(order.prescriptionType)

  return (
    Boolean(DeclinedStatesMap[order.type]) &&
    order[stateKey] === DeclinedStatesMap[order.type]
  )
}

export const useSetFinalOrderState = (orderKeysToPick?: (keyof Order)[]) => {
  const LabTestStates = useSelector(getLabTestsStates)
  const ProcedureStatus = useSelector(getProcedureStatus)
  const PrescriptionStates = useSelector(getPrescriptionStates)
  const InventoryStatuses = useSelector(getInventoryStatuses)
  const EventType = useSelector(getEventType)

  const TaskType = Utils.findConstantByName('Task', EventType) || {}
  const TaskStates = TaskType.states

  const InventoryDispensedState = Utils.findConstantIdByName(
    InventoryItemState.DISPENSED,
    InventoryStatuses,
  )
  const ProcedureCompletedState = Utils.findConstantIdByName(
    ProcedureState.COMPLETED,
    ProcedureStatus,
  )
  const LabTestCompletedState = Utils.findConstantIdByName(
    LabTestState.COMPLETED,
    LabTestStates,
  )
  const LabTestResultReceivedState = Utils.findConstantIdByName(
    LabTestState.RESULTS_RECEIVED,
    LabTestStates,
  )
  const PrescriptionPrescribedState = Utils.findConstantIdByName(
    PrescriptionItemState.PRESCRIBED,
    PrescriptionStates,
  )
  const TaskDoneState = Utils.findConstantIdByName('Done', TaskStates)

  const canCompleteLabTest = (order: Order) =>
    !(
      isLabTestUnderOrder(order) && order.stateId !== LabTestResultReceivedState
    )

  const FinalOrderStatesMap: Partial<
    Record<OrderType, (order: Order) => string>
  > = {
    [OrderType.INVENTORY]: R.always(InventoryDispensedState),
    [OrderType.PROCEDURE]: R.always(ProcedureCompletedState),
    [OrderType.LAB_TEST]: (order: Order) =>
      canCompleteLabTest(order) ? LabTestCompletedState : order.stateId,
    [OrderType.TASK]: R.always(TaskDoneState),
    [OrderType.PRESCRIPTION]: (order: Order) =>
      getPrescriptionType(order.prescriptionType)?.isInHouse
        ? InventoryDispensedState
        : PrescriptionPrescribedState,
  }

  return (order: Order) => {
    const stateGetter = FinalOrderStatesMap[order.type]

    if (!stateGetter) {
      return order
    }

    const stateId = stateGetter(order)
    const stateKey = getOrderStateKey(order.prescriptionType)

    const handledOrder =
      orderKeysToPick && !R.isEmpty(orderKeysToPick)
        ? R.pick(orderKeysToPick, order)
        : order

    return {
      ...handledOrder,
      [stateKey]: stateId,
    }
  }
}

type OrderItem =
  | Order
  | Prescription
  | InventoryItem
  | InvoiceLineItem
  | RetailOrderLineItem
  | GlobalInventoryCatalogItem
  | Nil

const createGetIsInventoryCategory =
  (...categoryNames: string[]) =>
  (item: OrderItem) => {
    const InventoryCategory = useSelector(getInventoryCategory)
    const isFoodCatalogEnabled = useSelector(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    return R.any((categoryName) => {
      const categoryId = Utils.findConstantIdByName(
        categoryName,
        InventoryCategory,
      )
      return categoryId
        ? categoryId === getInventoryItemCategoryId(item, isFoodCatalogEnabled)
        : false
    }, categoryNames)
  }

export const useIsInventoryCategory = (...categoryNames: string[]) => {
  const InventoryCategory = useSelector(getInventoryCategory)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  return (item: OrderItem) =>
    R.any((categoryName) => {
      const categoryId = Utils.findConstantIdByName(
        categoryName,
        InventoryCategory,
      )
      return categoryId
        ? categoryId === getInventoryItemCategoryId(item, isFoodCatalogEnabled)
        : false
    }, categoryNames)
}

export const useIsFood = createGetIsInventoryCategory(
  InventoryCategoryName.FOOD,
)
export const useIsVaccine = createGetIsInventoryCategory(
  InventoryCategoryName.VACCINES,
)
export const useIsDrug = createGetIsInventoryCategory(
  InventoryCategoryName.DRUG,
)
export const useIsVetDiet = createGetIsInventoryCategory(
  InventoryCategoryName.VET_DIET,
)
export const useIsGlobalInventoryItem = createGetIsInventoryCategory(
  InventoryCategoryName.DRUG,
  InventoryCategoryName.VET_DIET,
)
export const useIsInjectableDrug = (item: OrderItem) => {
  const isDrug = useIsDrug(item)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const injectableMethodIds = DrugDeliveryMethod.filter(
    (method) => method.action === DrugDeliveryAction.INJECT,
  ).map((method) => method.id)

  return (
    isDrug &&
    item &&
    'variation' in item &&
    item.variation?.deliveryMethodId &&
    injectableMethodIds.includes(item.variation?.deliveryMethodId)
  )
}

export const useGetVariationDescriptionString = (
  variation: Variation | Nil,
  flavor: string | Nil = '',
) => {
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const ProductForm = useSelector(getProductForm)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)

  const weightStr = useVariationWeightString(variation)

  if (!variation) {
    return flavor
  }

  const {
    strength,
    strengthCombo,
    strengthUnitsId,
    formId,
    customForm,
    deliveryMethodId,
    strengthColorId,
    saleUnitOfMeasure,
    perPackageAmount,
    perPackageUnitsId,
  } = variation

  const perPackageUnits = LanguageUtils.getConstantTranslatedName(
    perPackageUnitsId,
    InventoryProductSizeUnit,
    '',
  )

  const strengthValue = strength || strengthCombo
  const strengthStr = strengthValue
    ? `${strengthValue} ${LanguageUtils.getConstantTranslatedName(
        strengthUnitsId,
        DrugStrengthUnit,
      )}`
    : ''
  const formStr = getManualInputSelectValue(ProductForm, formId, customForm)
  const routeStr = LanguageUtils.getConstantTranslatedName(
    deliveryMethodId,
    DrugDeliveryMethod,
  )
  const strengthColorStr = LanguageUtils.getConstantTranslatedName(
    strengthColorId,
    DrugStrengthColor,
  )

  const strengthPart = LanguageUtils.getSentence([
    strengthStr,
    formStr,
    routeStr,
  ])

  const packagePart = LanguageUtils.getSentence([
    perPackageAmount,
    perPackageUnits,
    saleUnitOfMeasure,
  ])

  const weightPart =
    weightStr && `${i18n.t('Time:TIME_SELECTOR.FROM')} ${weightStr}`

  return LanguageUtils.getSentence(
    [strengthPart, strengthColorStr, weightPart, packagePart, flavor],
    SentenceFormatter.CAPITALIZE,
    ' | ',
  )
}

export const usePrescriptionAdminInstructions = ({
  administrationQuantity,
  administrationRouteId,
  administrationFrequencyId,
  administrationFrequencyNotes,
  administrationQuantityUnitId,
  administrationQuantityCustomUnit,
  variation,
}: PrescriptionAdministrationInfo) => {
  const DrugAdministrationFrequencies = useSelector(
    getDrugAdministrationFrequencies,
  )
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const isAdminNotesActionsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ADMIN_NOTES_ACTIONS),
  )

  const { administrationQuantityUnit } = getPrescriptionQuantityUnits(
    {
      administrationQuantityUnitId,
      administrationQuantityCustomUnit,
      variation,
    },
    {
      ProductSizeUnit,
      PrescriptionProductSizeUnit,
    },
  )

  const adminRouteAction = LanguageUtils.getTranslatedFieldName(
    DrugDeliveryMethod.find(
      (deliveryMethod) => deliveryMethod.id === administrationRouteId,
    ),
    'action',
  )

  const adminRouteStr = LanguageUtils.getConstantTranslatedName(
    administrationRouteId,
    DrugDeliveryMethod,
    '',
  )

  const frequencyString = LanguageUtils.getConstantTranslatedName(
    administrationFrequencyId,
    DrugAdministrationFrequencies,
    '',
  )
  const adminFrequency =
    frequencyString === 'Other'
      ? administrationFrequencyNotes || ''
      : frequencyString

  if (isAdminNotesActionsEnabled) {
    return LanguageUtils.getSentence([
      adminRouteAction,
      administrationQuantity,
      administrationQuantityUnit,
      adminRouteStr,
      adminFrequency,
    ])
  }

  return LanguageUtils.getSentence([
    administrationQuantity,
    administrationQuantityUnit,
    adminRouteStr,
    adminFrequency,
  ])
}

export const useGetInHousePrescriptionLabel = (
  printInfo: PrescriptionPrintInfo,
  { isGlobalItem, isFood }: { isFood: boolean; isGlobalItem: boolean },
) => {
  const Breed = useSelector(getBreed)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )
  const ProductForm = useSelector(getProductForm)
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const Species = useSelector(getSpecies)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const isDrug = useIsDrug(printInfo)

  const { name } = printInfo.inventory || {}

  const { deliveryMethodId, strengthColorId } = printInfo.variation || {}

  const { quantityUnit, quantityPerRefillUnit, administrationQuantityUnit } =
    getPrescriptionQuantityUnits(printInfo, {
      ProductSizeUnit,
      PrescriptionProductSizeUnit,
    })

  const formUnit = Utils.getConstantName(
    getVariationField('formId', printInfo, isFoodCatalogEnabled) as string,
    ProductForm,
    '',
  )
  const processingGlobalVariation =
    printInfo[
      isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation'
    ]
  const flavor =
    printInfo.variation?.flavor || processingGlobalVariation?.flavor
  const strength =
    printInfo.variation?.strength || processingGlobalVariation?.strength
  const strengthCombo = printInfo.variation?.strengthCombo
  const strengthUnitsId =
    printInfo.variation?.strengthUnitsId ||
    processingGlobalVariation?.strengthUnitsId
  const strengthStr = `${strength || ''} ${Utils.getConstantName(
    strengthUnitsId,
    DrugStrengthUnit,
    '',
  )}`.trim()
  const routeStr = Utils.getConstantName(
    deliveryMethodId,
    DrugDeliveryMethod,
    '',
  )
  const strengthColorStr = Utils.getConstantName(
    strengthColorId,
    DrugStrengthColor,
    '',
  )
  const weightStr = useVariationWeightString(printInfo.variation)

  const strengthValue = strengthStr || strengthCombo
  const strengthPart = strengthValue
    ? `${strengthValue} ${formUnit} ${routeStr}`
    : ''
  const colorPart = strengthColorStr ? ` | ${strengthColorStr}` : ''
  const weightPart = weightStr
    ? ` | ${i18n.t('Time:TIME_SELECTOR.FROM')} ${weightStr}`
    : ''

  const description = `${strengthPart} ${colorPart} ${weightPart}`
  const { business, doctor = {}, client, patient } = printInfo

  const address = AddressUtils.formatAddress(business)
  const species = LanguageUtils.getConstantTranslatedName(
    patient?.species,
    Species,
  )
  const breedString = getFullBreedName(
    patient?.species,
    patient?.breeds,
    Breed,
    ', ',
  )
  const breed = breedString ? ` | ${breedString}` : ''
  const expirationDate = printInfo.quantityDrugExpirationDate
  const expirationDateFormatted = DateUtils.formatDate(expirationDate)
  const expirationDateString = expirationDate
    ? `${i18n.t('Common:EXPIRES')}: ${expirationDateFormatted}`
    : ''
  const quantityRefillExpirationDate =
    printInfo.quantityRefillExpirationDate ||
    printInfo.parent?.quantityRefillExpirationDate
  const refillExpirationDate = DateUtils.formatDate(
    quantityRefillExpirationDate,
  )
  const creationDate = DateUtils.formatDate(printInfo.creationDate)

  const perRefillStr =
    printInfo.quantityPerRefill && quantityPerRefillUnit
      ? `${printInfo.quantityPerRefill} ${quantityPerRefillUnit} ${i18n.t(
          'Common:PER_REFILL',
        )} | `
      : ''

  const expirationStr = refillExpirationDate
    ? ` | ${i18n.t('Common:REFILL_BY')}: ${refillExpirationDate}`
    : ''

  const refillNumbersStr = i18n.t('Plurals:Z_ICU_WORKAROUND.NUMBER_REFILLS', {
    number: printInfo.quantityNumbRefills,
  })
  const refills =
    printInfo.quantityNumbRefills > 0
      ? `${perRefillStr}${refillNumbersStr}${expirationStr}`
      : undefined

  const administration = usePrescriptionAdminInstructions(printInfo)

  const lengthOfCourse = getLengthOfCourseString(printInfo)

  const doctorName = Utils.getPersonString(printInfo.doctor)
  // For now we're just showing the doctorName
  const doctorNameAndLicense = doctorName

  const administrationNotes =
    isGlobalItem &&
    (printInfo.administrationInstructionsAutofill || R.isNil(printInfo.notes))
      ? administration
      : printInfo.notes

  const drugNameLabelLine = joinString(
    [
      name,
      strengthStr || strengthCombo,
      formUnit,
      strengthColorStr ? `(${strengthColorStr})` : '',
    ],
    ' ',
  )

  const labelLines = [
    `${business?.name} | ${PhoneUtils.formatPhoneNumber(business?.phone)}`,
    `${address}`,
    `${Utils.getPersonString(doctor)}`,
    `${i18n.t('Common:CLIENT')}: ${Utils.getPersonString(client)}`,
    `${i18n.t('Common:PATIENT')}: ${patient?.name || ''} (${species}${breed})`,
    `${drugNameLabelLine} | ${i18n.t('Abbreviations:COMMON.QUANTITY')}: ${
      printInfo.totalQuantity
    } | ${expirationDateString}`,
    `${i18n.t('Common:PRESCRIBED')}: ${creationDate}`,
    refills,
    administrationNotes
      ? `${i18n.t('Common:ADMINISTRATION')}: ${administrationNotes}`
      : '',
    lengthOfCourse
      ? i18n.t('Common:LENGTH_OF_COURSE_DURATION', { lengthOfCourse })
      : '',
    printInfo.bottomWarning,
  ].filter(Boolean) as string[]

  const instructionsLengthOfCourse = lengthOfCourse
    ? `| (${i18n.t('Common:COURSE')}: ${lengthOfCourse})`
    : ''
  const instructions =
    isDrug &&
    (printInfo.administrationInstructionsAutofill || R.isNil(printInfo.notes))
      ? `${administration} ${instructionsLengthOfCourse}`
      : printInfo.notes

  const isExposeFlavorEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EXPOSE_FLAVOR),
  )
  const flavorIfExposed =
    isExposeFlavorEnabled && flavor ? `(${flavor})`.toUpperCase() : ''

  // in case of empty strength and form units use variation name, it may contain this fields
  const drugName =
    !strengthStr && !formUnit
      ? `${name || ''} ${printInfo?.variation?.name || ''}`.trim().toUpperCase()
      : name?.toUpperCase()

  const formattedData = {
    patientName: patient?.name?.toUpperCase(),
    patientSpecies: species?.toUpperCase(),
    patientNameAndSpecies: species
      ? `${patient?.name?.toUpperCase()} (${species?.toUpperCase()})`
      : patient?.name?.toUpperCase(),
    drugName: `${drugName} ${flavorIfExposed}`.trim(),
    drugForm: isFood ? quantityUnit?.toUpperCase() : formUnit?.toUpperCase(),
    drugStrength: strengthStr?.toUpperCase(),
    drugStrengthColor: strengthColorStr,
    drugStrengthCombo: strengthCombo,
    quantityUnit: administrationQuantityUnit?.toUpperCase(),
    prescriptionNumber: printInfo.rxNo,
    expirationDate: expirationDateFormatted,
    expirationDateString,
    instructions,
    quantity: `${printInfo.totalQuantity} ${quantityUnit}`,
    refills: printInfo.quantityNumbRefills
      ? `${i18n.t('Common:REFILL_OTHER')}: ${printInfo.quantityNumbRefills}`
      : '',
    filledOn: creationDate,
    clientName: Utils.getPersonString(client),
    quantityRow: [
      `${i18n.t('Abbreviations:COMMON.QUANTITY')}: ${
        printInfo.totalQuantity
      } ${quantityUnit}`,
      i18n.t('Common:FILLED_ON_DATE', { date: creationDate }),
      ...(printInfo.quantityNumbRefills
        ? [`${i18n.t('Common:REFILL_OTHER')}: ${printInfo.quantityNumbRefills}`]
        : []),
      ...(refillExpirationDate
        ? [
            i18n.t('Clients:TIMELINE.PRESCRIPTION_CARD.REFILL_BY', {
              refillExpirationDate,
            }),
          ]
        : []),
    ]
      .filter(Boolean)
      .join('  |  '),
    businessName: business?.name,
    businessAddress: address,
    businessPhone: PhoneUtils.formatPhoneNumber(business?.phone),
    doctorName,
    doctorNameAndLicense,
    warning: printInfo.warning,
    ndc: printInfo.variation?.nationalDrugCode,
    refillExpirationDate,
    quantityNumbRefills:
      printInfo.quantityNumbRefills || printInfo.parent?.refillRemains,
  }

  return {
    labelLines,
    name,
    description,
    formattedData,
  }
}

export const useUpdateOrderNotes = (order: Order) => {
  const dispatch = useDispatch()

  const [openNotesDialog] = useDialog(DialogNames.NOTES)

  return () => {
    openNotesDialog({
      name: order.name,
      notes: order.notes,
      onUpdateNotes: (notes: string) => {
        dispatch(
          partialEditOrder({
            id: order.id,
            type: order.type,
            notes,
          }),
        )
      },
      isLoadingSelector: getOrdersIsSendingOrReceiving,
    })
  }
}

export const useLogItemStateGetter = () => {
  const InventoryStates = useSelector(getInventoryStatuses)
  const LabTestStates = useSelector(getLabTestsStates)
  const ProcedureStatus = useSelector(getProcedureStatus)
  const PrescriptionStates = useSelector(getPrescriptionStates)

  return (order: Order, state?: string): Order | null => {
    switch (order.type.toUpperCase()) {
      case OrderType.INVENTORY:
        return {
          ...order,
          quantity: getHighValue(order.quantity) || 1,
          stateId: Utils.findConstantIdByName(
            state || InventoryItemState.ORDERED,
            InventoryStates,
          ),
        }
      case OrderType.LAB_TEST:
        return {
          ...order,
          quantity: getHighValue(order.quantity) || 1,
          stateId: Utils.findConstantIdByName(
            state || LabTestState.SELECTED,
            LabTestStates,
          ),
        }
      case OrderType.PROCEDURE:
        return {
          ...order,
          quantity: getHighValue(order.quantity) || 1,
          stateId: Utils.findConstantIdByName(
            state || ProcedureState.ORDERED,
            ProcedureStatus,
          ),
        }
      case OrderType.BUNDLE:
      case OrderType.TASK:
        return order
      case OrderType.PRESCRIPTION:
        return {
          ...order,
          [getOrderStateKey(order.prescriptionType)]:
            Utils.findConstantIdByName(
              state || PrescriptionItemState.ORDERED,
              PrescriptionStates,
            ),
        }
      default:
        return null
    }
  }
}

const useInventoryDialog = (
  hookParams: any = {},
  onClose?: () => void,
  dialogProps?: any,
): [(openParams: any) => void, () => void, boolean] => {
  const { isRefill } = hookParams
  const InventoryCategory = useSelector(getInventoryCategory)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const DrugId = Utils.findConstantIdByName(
    InventoryCategoryName.DRUG,
    InventoryCategory,
  )
  const VetDietId = Utils.findConstantIdByName(
    InventoryCategoryName.VET_DIET,
    InventoryCategory,
  )
  const FoodId = Utils.findConstantIdByName(
    InventoryCategoryName.FOOD,
    InventoryCategory,
  )

  const drugDialogName = isRefill
    ? DialogNames.DRUG_REFILL_DIALOG
    : DialogNames.DRUG_PRESCRIPTION_DIALOG
  const foodDialogName = isRefill
    ? DialogNames.FOOD_REFILL_DIALOG
    : DialogNames.FOOD_PRESCRIPTION_DIALOG

  const [openDrugDialog, closeDrugDialog, isDrugDialogOpen] = useDialog(
    drugDialogName,
    onClose,
    dialogProps,
  )
  const [openFoodDialog, closeFoodDialog, isFoodDialogOpen] = useDialog(
    foodDialogName,
    onClose,
    dialogProps,
  )

  const closeDialog = () => {
    if (isDrugDialogOpen) {
      closeDrugDialog()
    }

    if (isFoodDialogOpen) {
      closeFoodDialog()
    }
  }

  const isDialogOpen = isDrugDialogOpen || isFoodDialogOpen

  const openDialog = (openParams: any) => {
    const categoryId = getInventoryItemCategoryId(
      openParams.prescription,
      isFoodCatalogEnabled,
    )

    if (categoryId === DrugId) {
      openDrugDialog(openParams)
    } else if ([FoodId, VetDietId].includes(categoryId)) {
      openFoodDialog(openParams)
    }
  }

  return [openDialog, closeDialog, isDialogOpen]
}

export const usePrescriptionDialog = (
  onClose?: () => void,
  dialogProps?: any,
) => useInventoryDialog({}, onClose, dialogProps)

export const useRefillDialog = (onClose?: () => void, dialogProps?: any) =>
  useInventoryDialog({ isRefill: true }, onClose, dialogProps)

const useGetIsDeclinedLabTest = () => {
  const selectedOrders = useSelector(getSelectedOrders)
  const LabTestsStatesList = useSelector(getLabTestsStates)

  return (item: Order) => {
    const order = findOrderItemByItem(item, selectedOrders)

    const labTestStatus = Utils.getConstantName(
      order?.stateId,
      LabTestsStatesList,
    )
    return labTestStatus === LabTestState.DECLINED
  }
}

const useIsDisabledItem = () => {
  const pendingSelectionOrders = useSelector(getPendingSelectionOrders)
  const pendingUnSelectionOrders = useSelector(getPendingUnSelectionOrders)
  const selectedOrders = useSelector(getSelectedOrders)
  const getIsDeclinedLabTest = useGetIsDeclinedLabTest()

  return (item: Order) => {
    const isPendingSelection =
      findOrderItemByItem(item, pendingSelectionOrders) !== undefined
    const isPendingUnSelection =
      findOrderItemByItem(item, pendingUnSelectionOrders) !== undefined
    const order = findOrderItemByItem(item, selectedOrders)
    const isLabTestInOrder =
      item.type === OrderType.LAB_TEST && order && isLabTestUnderOrder(order)
    const isUnderOrderAndNotDeclinedLabTest =
      Boolean(isLabTestInOrder) && !getIsDeclinedLabTest(item)
    return (
      isPendingSelection ||
      isPendingUnSelection ||
      isUnderOrderAndNotDeclinedLabTest
    )
  }
}

export const useGetDisabledOrderWeakMap = (ordersWithNotes: Order[]) => {
  const searchResults = useSelector(getOrdersSearchResults)

  const isDisabledItem = useIsDisabledItem()

  const currentOrders = R.isEmpty(searchResults)
    ? ordersWithNotes
    : searchResults.map(({ item }) => item)

  const disabledWeakMap = currentOrders.reduce((weakMap, order) => {
    ;(order.items || [order]).map((item) =>
      weakMap.set(item, isDisabledItem(item)),
    )
    return weakMap
  }, new WeakMap<Order, boolean>())

  return disabledWeakMap
}

export const useGetIsCheckedItem = () => {
  const appointmentLineItemsMap = useSelector(getAppointmentLineItemsMap)
  const pendingSelectionOrders = useSelector(getPendingSelectionOrders)
  const pendingUnSelectionOrders = useSelector(getPendingUnSelectionOrders)
  const selectedOrders = useSelector(getSelectedOrders)
  const getIsDeclinedLabTest = useGetIsDeclinedLabTest()

  return (item: Order) => {
    const order = findOrderItemByItem(item, selectedOrders)
    const isSelected = order !== undefined
    const isPendingSelection =
      findOrderItemByItem(item, pendingSelectionOrders) !== undefined
    const isPendingUnSelection =
      findOrderItemByItem(item, pendingUnSelectionOrders) !== undefined
    const hasZeroUsedInInvoice = zeroUsedInvoiceItemExists(
      appointmentLineItemsMap || {},
      item,
    )
    const isDeclinedLabTest = getIsDeclinedLabTest(item)
    const isLabTestInOrder =
      item.type === OrderType.LAB_TEST && order && isLabTestUnderOrder(order)
    const isDeclinedLabTestUnderOrder = isDeclinedLabTest && isLabTestInOrder
    return (
      hasZeroUsedInInvoice ||
      ((isSelected || isPendingSelection) &&
        !isPendingUnSelection &&
        !isDeclinedLabTestUnderOrder)
    )
  }
}

export const useNavigateOrderToPrescription = (params?: {
  businessId?: string
  soapId?: string
}) => {
  const { businessId, soapId } = params ?? {}
  const navigate = useNavigate()

  const soapTabs = useSelector(getSoapActiveTemplateTabs)
  const uncoveredWidgetIds = useSelector(getSoapUncoveredWidgetIds)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isSoapCustomizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SOAP_CUSTOMIZATION),
  )
  const StaticSoapWidget = useSelector(getStaticSoapWidget)

  return (
    category: InventoryCategoryName,
    order: Pick<Order | Prescription, 'id' | 'group' | 'soapId' | 'type'>,
    originalBusinessIdParam?: string | Nil,
  ) => {
    const { id, group, soapId: orderSoapId, type } = order

    const objectParams = R.reject(R.isNil)({
      type,
      itemId: id,
      categoryName: encodeURIComponent(category),
      groupId: group,
    })
    const searchParams = new URLSearchParams(objectParams).toString()

    const originalBusinessId = isPatientSharingEnabled
      ? (originalBusinessIdParam ?? businessId)
      : null
    const search = { search: `?${searchParams}` }

    if (isSoapCustomizationEnabled && soapTabs) {
      const chargesWidgetId = Utils.findConstantIdByName(
        SoapWidgetName.CHARGES_WIDGET,
        StaticSoapWidget,
      )

      const tabName = getFirstTabOccurrenceUrl({
        tabs: soapTabs,
        uncoveredWidgetIds,
        widgetId: chargesWidgetId,
      })

      navigate(
        addOriginalBusinessId(
          `/soap/${soapId ?? orderSoapId}/tab/${tabName}`,
          originalBusinessId,
          search,
        ),
      )
      return
    }

    navigate(
      addOriginalBusinessId(
        `/soap/${soapId ?? orderSoapId}/order`,
        originalBusinessId,
        search,
      ),
    )
  }
}

export const useHandleOrderRefill = (
  client: User | undefined,
  logItemStateGetter: (order: Order, state?: string) => Order | null,
  onCloseCallback?: (...args: any[]) => void,
  areChargesPostedAndEditable?: boolean,
) => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()

  const urlOrderType = getUrlSearchParam('type', location.search) as OrderType
  const urlItemId = getUrlSearchParam('itemId', location.search)

  const orderLogItemFromUrl = useSelector(
    getSelectedOrder(urlOrderType, urlItemId),
  )
  const unassignedDoctorError = useSelector(getUnassignedDoctorOrderError)
  const hasOpenedDialogs = useSelector(getHasOpenDialogs)

  const [pendingPrescriptionParams, setPendingPrescriptionParams] =
    useState<PendingPrescriptionParams>()

  const onPrescriptionDialogClose = () => {
    navigate(location.pathname)
  }

  const handleRefillDialogClose = () => {
    // if refill dialog was open with url, clean it
    navigate(location.pathname)
  }
  const [openPrescriptionMissingFieldDialog] = useDialog(
    DialogNames.PRESCRIPTION_MISSING_FIELDS_DIALOG,
  )
  const [openRefillDialog, closeRefillDialog, isRefillDialogOpen] =
    useRefillDialog(handleRefillDialogClose)
  const [openPrescriptionDialog, closePrescriptionDialog] =
    usePrescriptionDialog(onPrescriptionDialogClose)
  const createOrUpdatePrescription = useCreateOrUpdatePrescription()

  const handlePrescriptionDialogClose = () => {
    closePrescriptionDialog()
    closeRefillDialog()
    onPrescriptionDialogClose()
    onCloseCallback?.()
  }

  const closePrescriptionDialogAfterSave = useCloseAfterCreation(
    () => !unassignedDoctorError && handlePrescriptionDialogClose(),
    getOrdersIsSending,
  )

  const clearOrderValidationError = () => {
    dispatch(clearOrderValidationErrorMessage())
  }

  const checkValidationError = (saveHandler: SaveOrderHandler) => {
    if (unassignedDoctorError) {
      openPrescriptionMissingFieldDialog({
        missingAssignedDoctor: true,
        client,
        onOk: () => {
          const { prescription, options, onAfterSave } =
            pendingPrescriptionParams || {}
          if (prescription) {
            saveHandler(prescription, options, onAfterSave)
          }
        },
      })
      clearOrderValidationError()
    }
  }

  const checkValidationAfterSave = useCloseAfterCreation(
    checkValidationError,
    getOrdersIsSending,
  )

  const savePrescription = (
    prescription: Order,
    options: CRUDOrderOptions = {},
    handleCloseAfterSaving: () => void,
  ) => {
    if (handleCloseAfterSaving) {
      handleCloseAfterSaving()
    }
    checkValidationAfterSave(savePrescription)
    const prescriptionWithState = !prescription.stateId
      ? logItemStateGetter(prescription)
      : prescription

    createOrUpdatePrescription(prescriptionWithState as Order, options)
  }

  const handleSavePrescription = (
    prescription: Order,
    options: CRUDOrderOptions = {},
    closeAfterSaving: boolean,
  ) => {
    const isPrescription = prescription.type === OrderType.PRESCRIPTION
    const signatureDoctorId = options?.signatureInfo?.signerId
    const missingAssignedDoctor =
      isPrescription && !options.doctorId && !signatureDoctorId
    const missingPdmpReportingFields = isPrescription
      ? getMissingPdmpReportingFields(prescription, client)
      : []

    setPendingPrescriptionParams({
      prescription,
      options,
      onAfterSave: closeAfterSaving
        ? closePrescriptionDialogAfterSave
        : () => {},
    })

    if (missingAssignedDoctor || !R.isEmpty(missingPdmpReportingFields)) {
      openPrescriptionMissingFieldDialog({
        missingAssignedDoctor,
        ...missingPdmpReportingFields,
        inventoryId: prescription.inventory?.id,
        variation: prescription.variation,
        client,
        onOk: () =>
          savePrescription(
            prescription,
            options,
            closeAfterSaving ? closePrescriptionDialogAfterSave : () => {},
          ),
      })
    } else {
      savePrescription(
        prescription,
        options,
        closeAfterSaving ? closePrescriptionDialogAfterSave : () => {},
      )
    }
  }

  const handleRefill = (prescription: Order) => {
    openRefillDialog({
      prescription,
      onSave: (newPrescription: Order, options: any) =>
        savePrescription(
          newPrescription,
          options,
          closePrescriptionDialogAfterSave,
        ),
    })
  }

  useEffect(() => {
    if (orderLogItemFromUrl && client?.id) {
      if (orderLogItemFromUrl.parentId) {
        // refill
        if (!isRefillDialogOpen) {
          handleRefill(orderLogItemFromUrl)
        }
      } else if (!hasOpenedDialogs) {
        // regular order or prescription
        openPrescriptionDialog({
          areChargesPostedAndEditable,
          clientId: client?.id,
          prescription: orderLogItemFromUrl,
          onSave: handleSavePrescription,
        })
      }
    }
  }, [orderLogItemFromUrl?.id, client?.id])

  return {
    clearOrderValidationError,
    handleRefill,
    handleSavePrescription,
    isRefillDialogOpen,
    openPrescriptionDialog,
  }
}

export const useOrderSelectedItems = () => {
  const [selectedItems, setSelectedItems] = useState<Order[]>([])
  const [selectedSubItem, setSelectedSubItem] = useState<Order>()

  const getSelectedItemsHaveOrder = (order: Order) =>
    // ignore items field since it may have nested orders which might have changed but we don't care about that
    selectedItems.some((item) => getOrdersEqual(item, order))

  const isSelectedItem = (order: Order) => {
    if (isBundle(order)) {
      return selectedItems.some((item) => item.bundleId === order.bundleId)
    }

    if (isWellnessPlanItem(order)) {
      // for wellness plan items it's reversed since items are expanded by default - selected means collapsed
      return selectedItems.every((item) => item.id !== order.id)
    }

    return R.equals(selectedSubItem, order) || getSelectedItemsHaveOrder(order)
  }

  const getNewSelectedItems = (order: Order) => {
    // for benefits it's reversed since benefits are expanded by default - selected means collapsed
    const wasSelected = isWellnessPlanItem(order)
      ? !isSelectedItem(order)
      : isSelectedItem(order)

    if (isBundle(order) && wasSelected) {
      return selectedItems.filter((item) => item.bundleId !== order.bundleId)
    }

    return wasSelected
      ? selectedItems.filter((item) => !getOrdersEqual(item, order))
      : selectedItems.concat(order)
  }

  const updateSelectedItem = (order: Order) => {
    setSelectedItems(getNewSelectedItems(order))
    setSelectedSubItem(undefined)
  }

  return {
    isSelectedItem,
    selectedSubItem,
    setSelectedSubItem,
    updateSelectedItem,
  }
}

interface UseOrderCheckItem {
  handleSavePrescription: (
    prescription: Order,
    options: CRUDOrderOptions,
    closeAfterSaving: boolean,
  ) => void
  logItemStateGetter: (order: Order, state?: string) => Order | null
  openPrescriptionDialog: (params: any) => void
}

export const useOrderCheckItem = ({
  handleSavePrescription,
  logItemStateGetter,
  openPrescriptionDialog,
}: UseOrderCheckItem) => {
  const dispatch = useDispatch()

  const handleListItemCheck: OrderCallbackWithTypeCheck = (
    order,
    { isPrescribable } = {},
  ) => {
    if (isPrescribable) {
      openPrescriptionDialog({
        prescription: order,
        onSave: handleSavePrescription,
      })
    } else {
      const newOrder =
        order?.usedQuantity === 0 ? { ...order, usedQuantity: 1 } : order
      const preparedNewOrder = logItemStateGetter(newOrder)
      if (preparedNewOrder) {
        dispatch(createOrder(preparedNewOrder))
      }
    }
  }

  return handleListItemCheck
}

export const useOrderCheckAllListItems = () => {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)

  const { t } = useTranslation('Soap')

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_ORDER,
  })

  const handleCheckAllListItems = (order: Order, checked: boolean) => {
    if (!soapId) {
      return
    }

    if (checked) {
      dispatch(orderBundle(order, soapId))
    } else {
      openConfirmAlert({
        message: t('Soap:SOAP_ORDERS.CONFIRM_ALERT_MESSAGE'),
        onConfirm: (proceed) =>
          proceed && dispatch(unorderBundle(order, soapId)),
      })
    }
  }

  return handleCheckAllListItems
}

export const useOrderUncheckItem = (onUncheckCallback?: Function) => {
  const dispatch = useDispatch()

  const selectedOrders = useSelector(getSelectedOrders)

  const { t } = useTranslation('Common')

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_ORDER,
  })

  const prepareForRemove = (item: Order): Order => {
    const order = findOrderItemByItem(item, selectedOrders)
    return order ? { ...item, id: order.id, type: order.type } : item
  }

  const handleListItemUncheck: OrderCallbackWithTypeCheck = (
    order,
    { isDrug, isFood } = {},
  ) => {
    const proceedToRemoveOrder = () => {
      const removingOrder = prepareForRemove(order)

      if (removingOrder.id) {
        const removeAction =
          isDrug || isFood
            ? removeUnifiedOrder
            : isProcedure(order)
              ? removeOrderUpdatePatient
              : removeOrder

        const fullOrder = findOrderItemByItem(order, selectedOrders, true)
        const hasChildRefills = Boolean(fullOrder?.refills?.length)

        if (hasChildRefills) {
          dispatch(
            registerWarnAlert(MESSAGE_CAN_NOT_REMOVE_PARENT_PRESCRIPTION),
          )
        } else {
          dispatch(removeAction(removingOrder))
        }
        onUncheckCallback?.()
      }
    }

    openConfirmAlert({
      message: t('Common:YOU_WILL_REMOVE_SOMETHING', { something: order.name }),
      onConfirm: (proceed) => proceed && proceedToRemoveOrder(),
    })
  }

  return handleListItemUncheck
}

export const useOrderEditListItem = () => {
  const selectedOrders = useSelector(getSelectedOrders)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const getNavigateToPrescriptionDialog = useNavigateOrderToPrescription({
    businessId: soapBusinessId,
    soapId,
  })

  const handleEditListItem: OrderCallbackWithTypeCheck = (
    order,
    { isPrescribable, isDrug, isVetDiet, isFood } = {},
  ) => {
    if (isPrescribable) {
      const orderItem = findOrderItemByItem(order, selectedOrders)

      if (orderItem) {
        const openPrescriptionDialog = R.cond([
          [
            R.always(Boolean(isDrug)),
            () =>
              getNavigateToPrescriptionDialog(
                InventoryCategoryName.DRUG,
                orderItem,
              ),
          ],
          [
            R.always(Boolean(isVetDiet)),
            () =>
              getNavigateToPrescriptionDialog(
                InventoryCategoryName.VET_DIET,
                orderItem,
              ),
          ],
          [
            R.always(Boolean(isFood)),
            () =>
              getNavigateToPrescriptionDialog(
                InventoryCategoryName.FOOD,
                orderItem,
              ),
          ],
          [R.T, () => {}],
        ])

        if (openPrescriptionDialog) {
          openPrescriptionDialog()
        }
      }
    }
  }

  return handleEditListItem
}

export const useGetSelectedOrderWithNotes = () => {
  const selectedOrders = useSelector(getSelectedOrders)

  return (order: Order) =>
    order.items
      ? {
          ...order,
          items: order.items.map((orderItem) =>
            getOrderWithNotes(orderItem, selectedOrders),
          ),
        }
      : getOrderWithNotes(order, selectedOrders)
}

export const useGetProcedureStateId = () => {
  const ProcedureStatus = useSelector(getProcedureStatus)
  return (name: string) => Utils.findConstantIdByName(name, ProcedureStatus)
}

export const useGetInventoryStateId = () => {
  const InventoryStatuses = useSelector(getInventoryStatuses)
  return (name: string) => Utils.findConstantIdByName(name, InventoryStatuses)
}

export const useGetLabTestStateId = () => {
  const LabTestStates = useSelector(getLabTestsStates)
  return (name: string) => Utils.findConstantIdByName(name, LabTestStates)
}

export const useGetLabOrderStatusName = () => {
  const LabOrderStatuses = useSelector(getLabOrderStatuses)
  return (id: string) => Utils.getConstantName(id, LabOrderStatuses)
}

export const useGetPrescriptionStateId = () => {
  const PrescriptionStates = useSelector(getPrescriptionStates)
  return (name: string) => Utils.findConstantIdByName(name, PrescriptionStates)
}

export const useOrderStateId = (
  item: Order | InvoiceLineItem | RetailOrderLineItem,
) => {
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const editingLineItemState = useSelector(getEditingLineItemState)
  const currentChargeSheetLineItem = useSelector(getCurrentChargeSheetLineItem)

  // TODO: M1 - Remove ts-ignore after https://chewyinc.atlassian.net/browse/CVC-9380
  // @ts-ignore
  const { stateId } = item

  return isChargeSheetEnabled
    ? R.prop('id', currentChargeSheetLineItem) === R.prop('id', item)
      ? editingLineItemState || stateId
      : stateId
    : stateId
}

export const useLabTestItemDecline = (
  order: Order | InvoiceLineItem | RetailOrderLineItem | Nil | undefined,
  disabled?: boolean,
) => {
  const isItemRetailOrderLineItem = isRetailOrderLineItem(order)
  const LabTestStates = useSelector(getLabTestsStates)
  const getLabTestStateId = useGetLabTestStateId()

  const isUnderOrder =
    R.pathSatisfies(
      R.allPass([R.complement(R.isEmpty), R.complement(R.isNil)]),
      ['labTest', 'orderId'],
    )(order) ||
    R.propSatisfies(
      R.allPass([R.complement(R.isEmpty), R.complement(R.isNil)]),
      'labTestOrderId',
    )(order)
  const state = isItemRetailOrderLineItem
    ? null
    : Utils.getConstantName(order?.stateId, LabTestStates)

  const canBeDeclined = DeclinableLabTestsStates.includes(state)

  const isDeclinedState = isItemRetailOrderLineItem
    ? order.declined || false
    : order?.stateId === getLabTestStateId(LabTestState.DECLINED)

  return {
    declineDisabled:
      disabled || isDeclinedState || (isUnderOrder && !canBeDeclined),
    isDeclinedState,
    declinedLabTestStateId: getLabTestStateId(LabTestState.DECLINED),
    isUnderOrder,
  }
}

export const useGetIsOrderDeletable = () => {
  const isDeleteFinalizedLabTestsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
  )
  const getLabOrderStatusName = useGetLabOrderStatusName()

  return (order: Order) => {
    if (!order.stateId) {
      return false
    }
    const isUnderOrder = Boolean(order.labTest?.labOrderId)
    if (!isUnderOrder) {
      return true
    }
    const isDeletableState = DeletableLabOrderStates.includes(
      getLabOrderStatusName(order.orderStateId!),
    )
    return isDeleteFinalizedLabTestsEnabled && isDeletableState
  }
}

export const useOpenPrescriptionDialogAfterFetchUnifiedOrder = ({
  onClose,
  overrideParams,
  prescriptionDialogProps,
  isChargeSheet,
  isInvoice,
}: {
  isChargeSheet?: boolean
  isInvoice?: boolean
  onClose?: () => void
  overrideParams?: object
  prescriptionDialogProps?: object
}) => {
  const dispatch = useDispatch()

  const unifiedOrder = useSelector(getUnifiedOrder)
  const unifiedOrderLineItem = unifiedOrder?.lineItem
  const clientId = isRetailOrderLineItem(unifiedOrderLineItem)
    ? unifiedOrderLineItem.client.id
    : unifiedOrder?.clientId || unifiedOrderLineItem?.clientId

  const client = useSelector(getUser(clientId))

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const areChargesPostedAndEditable = isEditPostedChargesEnabled && isInvoice

  const isRefill = unifiedOrder ? getIsRefill(unifiedOrder) : false

  const handleCloseWithClearUnifiedOrder = () => {
    dispatch(clearCurrentUnifiedOrder())
    onClose?.()
  }

  const [openModalPrescriptionDialog, closeModalPrescriptionDialog] =
    usePrescriptionDialog(handleCloseWithClearUnifiedOrder, {
      ...prescriptionDialogProps,
      partialUpdate: areChargesPostedAndEditable,
    })
  const [openRefillDialog, closeRefillDialog] = useRefillDialog(
    handleCloseWithClearUnifiedOrder,
    prescriptionDialogProps,
  )
  const [openPrescriptionMissingFieldDialog] = useDialog(
    DialogNames.PRESCRIPTION_MISSING_FIELDS_DIALOG,
  )

  const openDialog = isRefill ? openRefillDialog : openModalPrescriptionDialog
  const closeDialog = isRefill
    ? closeRefillDialog
    : closeModalPrescriptionDialog

  const createOrUpdatePrescription =
    useCreateOrUpdatePrescription(isChargeSheet)
  const logItemStateGetter = useLogItemStateGetter()

  const setCloseAfterConvertPrescriptionUpdate = useCloseAfterCreation(
    () => closeDialog(),
    getOrdersIsSending,
  )

  const handleSavePrescription = (
    prescription: Order,
    options: CRUDOrderOptions = {},
    closeAfterSaving?: boolean,
  ) => {
    if (closeAfterSaving) {
      setCloseAfterConvertPrescriptionUpdate()
    }

    const isPrescription = prescription.type === OrderType.PRESCRIPTION
    const signatureDoctorId = options?.signatureInfo?.signerId
    const missingAssignedDoctor =
      isPrescription && !options.doctorId && !signatureDoctorId
    const missingPdmpReportingFields = isPrescription
      ? getMissingPdmpReportingFields(prescription, client)
      : []

    const prescriptionWithState =
      !areChargesPostedAndEditable && !prescription.stateId
        ? logItemStateGetter(prescription)
        : prescription

    const handleCreateOrUpdatePrescription = () => {
      createOrUpdatePrescription(
        {
          ...prescriptionWithState!,
          previousEntityType: areChargesPostedAndEditable
            ? prescriptionWithState?.previousEntityType
            : prescriptionWithState?.previousEntityType ||
              OrderType.PRESCRIPTION,
        },
        {
          outsideSoap: true,
          invoiceId:
            isRefill && isChargeSheetEnabled
              ? unifiedOrder?.invoiceId ||
                (isRetailOrderLineItem(unifiedOrder?.lineItem)
                  ? unifiedOrder?.lineItem?.invoice?.id
                  : unifiedOrder?.lineItem?.invoiceId)
              : undefined,
          allowEditPostedItem: areChargesPostedAndEditable,
          fromInvoice: isInvoice,
          ...options,
        },
      )
    }

    if (
      !areChargesPostedAndEditable &&
      (missingAssignedDoctor || !R.isEmpty(missingPdmpReportingFields))
    ) {
      openPrescriptionMissingFieldDialog({
        missingAssignedDoctor,
        ...missingPdmpReportingFields,
        inventoryId: prescription.inventory?.id,
        variation: prescription.variation,
        client,
        onOk: handleCreateOrUpdatePrescription,
      })
    } else {
      handleCreateOrUpdatePrescription()
    }
  }

  const openPrescriptionDialogAfterFetch = useCloseAfterCreation(
    () =>
      openDialog({
        skipOrderFetching: true,
        soapId: unifiedOrder?.soapId,
        outsideSoap: !unifiedOrder?.soapId,
        clientId,
        patientId: unifiedOrder?.patientId,
        prescription: {
          ...unifiedOrder,
          invoiceId:
            unifiedOrder?.invoiceId ||
            (isRetailOrderLineItem(unifiedOrder?.lineItem)
              ? unifiedOrder?.lineItem?.invoice?.id
              : unifiedOrder?.lineItem?.invoiceId),
        },
        onSave: handleSavePrescription,
        areChargesPostedAndEditable:
          isEditPostedChargesEnabled &&
          Boolean(
            unifiedOrder?.invoiceId ||
              (isRetailOrderLineItem(unifiedOrder?.lineItem)
                ? unifiedOrder?.lineItem?.invoice?.id
                : unifiedOrder?.lineItem?.invoiceId),
          ) &&
          isInvoice,
        ...overrideParams,
      }),
    getUnifiedOrderIsLoading,
  )

  const fetchUnifiedOrderAndOpenPrescription = (
    logId: string,
    logType = OrderType.PRESCRIPTION,
  ) => {
    dispatch(fetchUnifiedOrder(logId, logType))
    openPrescriptionDialogAfterFetch()
  }

  return {
    fetchUnifiedOrderAndOpenPrescription,
    handleSavePrescription,
  }
}

export const useGetIsSendingOrder = () => {
  const pendingSelectionOrders = useSelector(getPendingSelectionOrders)
  const pendingUnSelectionOrders = useSelector(getPendingUnSelectionOrders)
  return (item: Order) =>
    findOrderItemByItem(item, pendingSelectionOrders) ||
    findOrderItemByItem(item, pendingUnSelectionOrders)
}
