import React, { useEffect, useMemo, useRef, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Moment } from 'moment'
import * as R from 'ramda'
import { moment, Nil, PermissionArea } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  SchedulerColumnType,
  SchedulerColumnTypesToProp,
  SlotType,
} from '~/constants/schedulerConstants'
import {
  getAppointmentTypesList,
  getAppointmentTypesMap,
} from '~/store/reducers/appointmentTypes'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getSchedulingClientId,
  getSchedulingPatientId,
} from '~/store/reducers/timetable'
import { Schedule, TimetableEvent } from '~/types'
import { BusinessAppointmentType } from '~/types/entities/businessAppointmentType'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below', 2),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    cursor: {
      cursor: 'pointer',
    },
    newAppointmentBox: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below'),
      position: 'absolute',
      backgroundColor: theme.colors.tableBackground,
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.10), 3px 3px 20px 0 rgba(168,163,163,0.20)',
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing',
      },
    },
    newAppointmentBoxDragging: {
      boxShadow:
        '0 2px 4px 0 rgba(0,0,0,0.20), 3px 3px 20px 0 rgba(168,163,163,0.50)',
    },
  }),
  { name: 'ScheduleMouseHandler' },
)

const DEFAULT_APPOINTMENT_DURATION = 30
const DEFAULT_APPOINTMENT_STEP = 5

// eslint-disable-next-line prefer-regex-literals
const COLUMN_ID_REGEXP = new RegExp('column-\\S*')
const PERSON_ID_REGEXP = new RegExp(
  `column-${SchedulerColumnType.PERSON}-(\\S*)`,
)
const EVENT_TYPE_ID_REGEXP = new RegExp(
  `column-${SchedulerColumnType.EVENT_TYPE}-(\\S*)`,
)
const EVENT_STATE_ID_REGEXP = new RegExp(
  `column-${SchedulerColumnType.EVENT_STATE}-(\\S*)`,
)

const findConflictingSlots = (
  column: Schedule,
  schedules: Schedule[],
  startTime: Moment,
  endTime: Moment,
) => {
  const prop = SchedulerColumnTypesToProp[column.columnType] || 'columnType'
  const matchingSchedule = schedules.find(
    (schedule) => schedule[prop] === column[prop],
  )
  // we allow creating appointments on top of not available slots
  const slots = (matchingSchedule?.slots || []).filter(
    (slot) => slot.type !== SlotType.NOT_AVAILABLE,
  )

  return {
    startSlot: slots.find(({ interval: { from, to } }) =>
      startTime.isBetween(from, to, 'minute', '[)'),
    ),
    endSlot: slots.find(({ interval: { from, to } }) =>
      endTime.isBetween(from, to, 'minute', '(]'),
    ),
  }
}

const findColumn = (x: number | Nil, y: number | Nil) => {
  if (!document.elementsFromPoint || R.isNil(x) || R.isNil(y)) {
    return undefined
  }

  const elements = document.elementsFromPoint.call(document, x, y)

  return R.find((el) => COLUMN_ID_REGEXP.test(el.id), elements)
}

const findDefaultOrFirstAppointmentTypeIdByEventTypeId = (
  eventTypeId: string | undefined,
  typesList: string[],
  typesMap: Record<string, BusinessAppointmentType>,
): string | undefined => {
  if (!eventTypeId) {
    return undefined
  }
  let firstTypeId: string | undefined

  const defaultTypeId = typesList.find((id) => {
    const type = typesMap[id]
    if (type.enabled === false || type.eventTypeId !== eventTypeId) {
      return false
    }
    if (!firstTypeId) {
      firstTypeId = id
    }
    return type.defaultType
  })
  return defaultTypeId || firstTypeId
}

export interface ScheduleMouseHandlerProps {
  columns: Schedule[]
  contentRef: React.RefObject<HTMLDivElement>
  onAppointmentOk?: () => void
  schedules: Schedule[]
  stepInterval: number
  steps: string[]
}

const ScheduleMouseHandler = ({
  contentRef,
  stepInterval,
  steps,
  schedules,
  columns,
  onAppointmentOk,
}: ScheduleMouseHandlerProps) => {
  const classes = useStyles()
  const {
    constants: { schedulerRowHeight, schedulerNotLastChildPadding },
  } = useTheme()

  const permissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const schedulingClientId = useSelector(getSchedulingClientId)
  const schedulingPatientId = useSelector(getSchedulingPatientId)
  const appointmentTypesMap = useSelector(getAppointmentTypesMap)
  const appointmentTypesList = useSelector(getAppointmentTypesList)

  const [mouseOverTimer, setMouseOverTimer] = useState<number>()
  const [newAppointmentBoxVisible, setNewAppointmentBoxVisible] =
    useState(false)
  const [yOffset, setYOffset] = useState(0)
  const [clientX, setClientX] = useState(0)
  const [clientY, setClientY] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [wasDragged, setWasDragged] = useState(false)
  const [deltaPositionY, setDeltaPositionY] = useState(0)

  const boxRef = useRef(null)

  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const boxHeight = useMemo(
    () =>
      (DEFAULT_APPOINTMENT_DURATION * schedulerRowHeight) / stepInterval -
      schedulerNotLastChildPadding,
    [stepInterval],
  )
  const precision = useMemo(
    () =>
      (DEFAULT_APPOINTMENT_STEP * schedulerRowHeight) /
      DEFAULT_APPOINTMENT_DURATION,
    [schedulerRowHeight],
  )
  const roughTop = useMemo(
    () =>
      Math.min(Math.max(yOffset, 0), (steps.length - 1) * schedulerRowHeight),
    [yOffset, steps, schedulerRowHeight],
  )

  let top
  let left
  let minutes
  let startTime: Moment
  let endTime
  let personId: string | undefined
  let eventTypeId: string | undefined
  let appointmentStateId: string | undefined
  let hideBox

  const columnDiv = findColumn(clientX, clientY)
  const boxWidth = columnDiv?.clientWidth

  top = Math.round(roughTop / precision) * precision

  if (columnDiv) {
    left =
      columnDiv.getBoundingClientRect().left -
      (contentRef.current?.getBoundingClientRect()?.left ?? 0)
    if (!isDragging) {
      minutes = (top * stepInterval) / schedulerRowHeight

      startTime = moment(steps[0]).add(minutes, 'minutes')
      endTime = moment(startTime).add(DEFAULT_APPOINTMENT_DURATION, 'minutes')

      personId = columnDiv.id.match(PERSON_ID_REGEXP)?.[1]
      eventTypeId = columnDiv.id.match(EVENT_TYPE_ID_REGEXP)?.[1]
      appointmentStateId = columnDiv.id.match(EVENT_STATE_ID_REGEXP)?.[1]

      const column = personId
        ? columns.find(
            (columnItem) =>
              columnItem.personId === personId &&
              !columnItem.deleted &&
              columnItem.active,
          )
        : eventTypeId
          ? columns.find(
              ({ eventTypeId: columnEventTypeId }) =>
                columnEventTypeId === eventTypeId,
            )
          : appointmentStateId
            ? columns.find(
                ({ eventStateId }) => eventStateId === appointmentStateId,
              )
            : columns.find(
                ({ columnType }) =>
                  columnType === SchedulerColumnType.UNASSIGNED,
              )

      if (column) {
        const { startSlot, endSlot } = findConflictingSlots(
          column,
          schedules,
          startTime,
          endTime,
        )

        if (endSlot) {
          endTime = moment(endSlot.interval.from)
          startTime = moment(endTime).add(
            -DEFAULT_APPOINTMENT_DURATION,
            'minutes',
          )

          top =
            (endTime.diff(steps[0], 'minutes') * schedulerRowHeight) /
              stepInterval -
            boxHeight -
            schedulerNotLastChildPadding
        }

        if (startSlot) {
          startTime = moment(startSlot.interval.to)
          endTime = moment(startTime).add(
            DEFAULT_APPOINTMENT_DURATION,
            'minutes',
          )

          top =
            (startTime.diff(steps[0], 'minutes') * schedulerRowHeight) /
            stepInterval
        }

        const stillConflicting = findConflictingSlots(
          column,
          schedules,
          startTime,
          endTime,
        )

        hideBox =
          Boolean(stillConflicting.startSlot) ||
          Boolean(stillConflicting.endSlot) ||
          startTime.isBefore(R.head(steps), 'minutes') ||
          endTime.isAfter(R.last(steps), 'minutes')
      } else {
        hideBox = true
      }
    }
  }

  useEffect(() => {
    if (!columnDiv) {
      setNewAppointmentBoxVisible(false)
    }
  }, [columnDiv])

  const createScheduledAppointment = () =>
    ({
      scheduledStartDatetime: startTime && startTime.toISOString(),
    }) as TimetableEvent

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const topOffset = event.currentTarget.getBoundingClientRect().top

    if (mouseOverTimer) {
      clearTimeout(mouseOverTimer)
    }
    if (permissions.create) {
      setYOffset(event.clientY - topOffset)

      setClientX(event.clientX)
      setClientY(event.clientY)
      setWasDragged(false)

      const timer = window.setTimeout(() => {
        setNewAppointmentBoxVisible(true)
      }, 300)

      setMouseOverTimer(timer)
    }
  }

  const handleOpen = () => {
    openAppointmentDialog({
      appointment: createScheduledAppointment(),
      appointmentStateId,
      appointmentTypeId: findDefaultOrFirstAppointmentTypeIdByEventTypeId(
        eventTypeId,
        appointmentTypesList,
        appointmentTypesMap,
      ),
      clientId: schedulingClientId,
      patientId: schedulingPatientId,
      personId,
      onOk: onAppointmentOk,
    })
  }

  const onRootClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const topOffset = event.currentTarget.getBoundingClientRect().top

    clearTimeout(mouseOverTimer)
    if (permissions.create) {
      setYOffset(event.clientY - topOffset)

      setClientX(event.clientX)
      setClientY(event.clientY)

      setNewAppointmentBoxVisible(true)
      setWasDragged(false)
      handleOpen()
    }
  }

  const hide = (event: React.MouseEvent<HTMLDivElement>) => {
    if (boxRef.current !== event.relatedTarget) {
      clearTimeout(mouseOverTimer)
      setNewAppointmentBoxVisible(false)
    }
  }

  const onBoxClick = () => {
    handleOpen()
  }

  const onDragStart = () => {
    setDeltaPositionY(0)
  }

  const onDrag = (event: DraggableEvent, ui: DraggableData) => {
    clearTimeout(mouseOverTimer)
    setIsDragging(true)
    setWasDragged(true)
    if (ui.deltaY > 1 || ui.deltaY < -1) {
      setDeltaPositionY(deltaPositionY + ui.deltaY)
    }
  }

  const onDragStop = (e: DraggableEvent) => {
    const event = e as MouseEvent
    if (isDragging) {
      setClientX(event.clientX)
      setClientY(event.clientY)

      setYOffset(yOffset + deltaPositionY)

      setIsDragging(false)
    } else {
      onBoxClick()
    }
  }

  return (
    <>
      <div
        aria-hidden="true"
        className={classNames(classes.root, {
          [classes.cursor]: permissions.create,
        })}
        onClick={isDragging || hideBox ? undefined : onRootClick}
        onMouseLeave={isDragging ? undefined : hide}
        onMouseMove={isDragging ? undefined : onMouseMove}
        onMouseOut={isDragging ? undefined : hide}
      />
      <Draggable
        position={{
          x: left ?? 0,
          y: top ?? 0,
        }}
        onDrag={onDrag}
        onStart={onDragStart}
        onStop={onDragStop}
      >
        <div
          className={classNames(classes.newAppointmentBox, {
            [classes.newAppointmentBoxDragging]: isDragging,
          })}
          ref={boxRef}
          style={{
            height: !newAppointmentBoxVisible || hideBox ? 0 : boxHeight,
            width: !newAppointmentBoxVisible || hideBox ? 0 : boxWidth,
          }}
          onMouseLeave={isDragging || wasDragged ? undefined : hide}
        />
      </Draggable>
    </>
  )
}

export default ScheduleMouseHandler
