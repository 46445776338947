import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import {
  RetailOrderLineItem,
  RxPrescriptionOrigin,
} from '~/api/graphql/generated/types'
import { isRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionType } from '~/constants/prescription'
import {
  InventoryItemState,
  LabTestState,
  OrderType,
  PrescriptionItemState,
  ProcedureState,
} from '~/constants/SOAPStates'
import { editRetailOrderLineItem } from '~/store/actions/finance'
import {
  declineLabOrder,
  editOrder,
  partialEditOrder,
} from '~/store/actions/orders'
import {
  editChargeSheetItem,
  editChargeSheetOrder,
} from '~/store/duck/clientFinanceData'
import {
  useGetInventoryStateId,
  useGetLabTestStateId,
  useGetPrescriptionStateId,
  useGetProcedureStateId,
  useLabTestItemDecline,
  useOrderStateId,
} from '~/store/hooks/orders'
import { useIsUsedQuantityEmpty } from '~/store/hooks/prescription'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import { InvoiceLineItem, Order } from '~/types'
import {
  convertInvoiceLineItemToSoapLog,
  convertRetailOrderLineItemToSoapLog,
  getOrderStateKey,
} from '~/utils/orderUtils'
import {
  getIsCreatedPrescriptionChewyActiveRx,
  getPrescriptionType,
} from '~/utils/prescription'
import useDialog from '~/utils/useDialog'

export const useDeclineOrderProcedureItem = (order: Order | Nil) => {
  const dispatch = useDispatch()

  const getProcedureStateId = useGetProcedureStateId()

  const onDecline = () => {
    if (!order) {
      return
    }

    dispatch(
      partialEditOrder({
        id: order.id,
        type: order.type,
        stateId:
          getProcedureStateId(ProcedureState.DECLINED) === order.stateId
            ? getProcedureStateId(ProcedureState.ORDERED)
            : getProcedureStateId(ProcedureState.DECLINED),
      }),
    )
  }

  return onDecline
}

export const useDeclineOrderInventoryItem = (order: Order | Nil) => {
  const dispatch = useDispatch()

  const getInventoryStateId = useGetInventoryStateId()

  const onDecline = () => {
    if (!order) {
      return
    }

    dispatch(
      editOrder({
        ...order,
        stateId:
          getInventoryStateId(InventoryItemState.DECLINED) === order.stateId
            ? getInventoryStateId(InventoryItemState.ORDERED)
            : getInventoryStateId(InventoryItemState.DECLINED),
      }),
    )
  }

  return onDecline
}

export const useDeclineOrderLabTestItem = (
  order: Order | InvoiceLineItem | Nil,
  declineTest: () => void,
) => {
  const dispatch = useDispatch()
  const selectedOrders = useSelector(getSelectedOrders)
  const isDeleteFinalizedLabTestsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
  )
  const [
    openDeclineFinalizedLabTestDialog,
    closeDeclineFinalizedLabTestDialog,
  ] = useDialog(DialogNames.DECLINE_FINALIZED_LAB_TEST)
  const [openDeclineLabOrderDialog, closeDeclineLabOrderDialog] = useDialog(
    DialogNames.DECLINE_LAB_ORDER,
  )
  const { declinedLabTestStateId, isUnderOrder } = useLabTestItemDecline(
    order as Order,
  )

  const getOrderHasSingleNotDeclinedLabTest = (
    item: Order | InvoiceLineItem | Nil,
  ) =>
    selectedOrders.filter(
      (selectedOrder) =>
        R.path(['labTest', 'labOrderId'], selectedOrder) ===
          R.path(['labTest', 'labOrderId'], item) &&
        selectedOrder.stateId !== declinedLabTestStateId,
    ).length === 1 || R.prop('labTestOrderId', item)

  const handleDeclineForFinalizedLabOrder = () => {
    const vendorId: string | undefined =
      R.path(['labTest', 'vendorId'], order) || R.prop('labTestVendorId', order)
    const labOrderId: string | undefined =
      R.path(['labTest', 'labOrderId'], order) ||
      R.prop('labTestOrderId', order)

    if (!order || !vendorId || !labOrderId) {
      return
    }
    if (getOrderHasSingleNotDeclinedLabTest(order)) {
      openDeclineLabOrderDialog({
        vendorId,
        onClose: closeDeclineLabOrderDialog,
        declineOrder: () => dispatch(declineLabOrder(vendorId, labOrderId)),
      })
      return
    }
    openDeclineFinalizedLabTestDialog({
      onClose: closeDeclineFinalizedLabTestDialog,
      declineTest: () => dispatch(declineTest()),
      declineOrder: () => dispatch(declineLabOrder(vendorId, labOrderId)),
    })
  }

  const onDecline = () => {
    if (!order) {
      return
    }
    if (isDeleteFinalizedLabTestsEnabled && isUnderOrder) {
      handleDeclineForFinalizedLabOrder()
      return
    }
    dispatch(declineTest())
  }

  return onDecline
}

export const useDeclineOrderPrescriptionItem = ({
  id,
  inventoryLogStatusId,
  origin,
  prescriptionType,
  stateId: stateIdParam,
}: {
  id?: string
  inventoryLogStatusId: string | Nil
  origin?: RxPrescriptionOrigin | Nil
  prescriptionType: PrescriptionType | Nil
  stateId: string | undefined
}) => {
  const dispatch = useDispatch()

  const getPrescriptionStateId = useGetPrescriptionStateId()
  const getInventoryStateId = useGetInventoryStateId()

  const onDecline = () => {
    if (!id) {
      return
    }

    const { isInHouse, isChewyActiveRx } = getPrescriptionType(
      prescriptionType,
      origin,
    )
    const stateKey = getOrderStateKey(prescriptionType)

    const orderedStateId = isInHouse
      ? getInventoryStateId(InventoryItemState.ORDERED)
      : getPrescriptionStateId(PrescriptionItemState.ORDERED)

    const stateId = isInHouse
      ? getInventoryStateId(InventoryItemState.DECLINED)
      : getPrescriptionStateId(PrescriptionItemState.DECLINED)

    const isDeclined = stateIdParam === stateId
    const orderStateKey = isInHouse ? inventoryLogStatusId : stateIdParam

    dispatch(
      partialEditOrder(
        {
          id,
          type: OrderType.PRESCRIPTION,
          [stateKey]: stateId === orderStateKey ? orderedStateId : stateId,
        },
        isChewyActiveRx
          ? {
              isActiveRxDecline: !isDeclined,
              isActiveRxUndecline: isDeclined,
            }
          : undefined,
      ),
    )
  }

  return onDecline
}

export const useDeclineOrderItem = (order: Order | Nil) => {
  const { declinedLabTestStateId } = useLabTestItemDecline(order)
  const onDeclineProcedureItem = useDeclineOrderProcedureItem(order)
  const onDeclineOrderInventoryItem = useDeclineOrderInventoryItem(order)
  const onDeclineOrderLabTestItem = useDeclineOrderLabTestItem(order, () =>
    editOrder({ ...order!, stateId: declinedLabTestStateId }),
  )
  const onDeclineOrderPrescriptionItem = useDeclineOrderPrescriptionItem({
    id: order?.id,
    inventoryLogStatusId: order?.inventoryLogStatusId,
    prescriptionType: order?.prescriptionType,
    origin: order?.origin,
    stateId: order?.stateId,
  })

  const OnDeclineMap: Record<string, () => void> = {
    [OrderType.PROCEDURE]: onDeclineProcedureItem,
    [OrderType.INVENTORY]: onDeclineOrderInventoryItem,
    [OrderType.LAB_TEST]: onDeclineOrderLabTestItem,
    [OrderType.PRESCRIPTION]: onDeclineOrderPrescriptionItem,
    DEFAULT: () => {},
  }

  return order
    ? (OnDeclineMap[order.type?.toUpperCase()] ?? OnDeclineMap.DEFAULT)
    : OnDeclineMap.DEFAULT
}

const editChargeSheetLineItemStatus = (
  item: InvoiceLineItem,
  stateId: string,
) =>
  editChargeSheetOrder({
    id: item.logId!,
    type: item.logType,
    order: { stateId },
    soapLogModificationDate: item.soapLogModificationDate!,
    options: {
      declined: item.declined,
      isChewyActiveRx: getIsCreatedPrescriptionChewyActiveRx(
        item.prescriptionType,
        item.origin,
      ),
    },
  })

export const useDeclineLineItem = (
  item: InvoiceLineItem | RetailOrderLineItem,
) => {
  const dispatch = useDispatch()
  const getPrescriptionStateId = useGetPrescriptionStateId()
  const getInventoryStateId = useGetInventoryStateId()
  const getProcedureStateId = useGetProcedureStateId()
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const { declinedLabTestStateId } = useLabTestItemDecline(item)
  const stateId = useOrderStateId(item)
  const isItemRetailOrderLineItem = isRetailOrderLineItem(item)
  const onDeclineOrderLabTestItem = useDeclineOrderLabTestItem(
    item as InvoiceLineItem, // Never will be RetailOrderLineItem
    () =>
      editChargeSheetOrder({
        id: (item as InvoiceLineItem).logId!,
        type: (item as InvoiceLineItem).logType,
        order: { stateId: declinedLabTestStateId },
        soapLogModificationDate: (item as InvoiceLineItem)
          .soapLogModificationDate!,
      }),
  )

  const isUsedQuantityEmpty = useIsUsedQuantityEmpty(
    isItemRetailOrderLineItem
      ? convertRetailOrderLineItemToSoapLog(item)
      : convertInvoiceLineItemToSoapLog(item),
  )

  if (isItemRetailOrderLineItem) {
    return () => {
      dispatch(
        editRetailOrderLineItem({
          id: item.id,
          quantity: item.quantity,
          declined: !item.declined,
          partNumber: item.partNumber,
          expectedModificationDate: item.modificationDate,
        }),
      )
    }
  }
  if (isUsedQuantityEmpty && isChargeSheetEnabled) {
    return () => {
      dispatch(
        editChargeSheetItem({
          updateItemInput: [
            {
              id: item.id,
              expectedModification: item.modificationDate,
              expectedSoapLogModification: item.soapLogModificationDate,
              declined: !item.declined,
            },
          ],
        }),
      )
    }
  }

  const OnDeclineMap: Record<string, () => void> = {
    [OrderType.LAB_TEST]: onDeclineOrderLabTestItem,
    [OrderType.PROCEDURE]: () => {
      const newStateId =
        getProcedureStateId(ProcedureState.DECLINED) === stateId
          ? getProcedureStateId(ProcedureState.ORDERED)
          : getProcedureStateId(ProcedureState.DECLINED)
      if (!isItemRetailOrderLineItem) {
        dispatch(editChargeSheetLineItemStatus(item, newStateId))
      }
    },
    [OrderType.INVENTORY]: () => {
      const newStateId =
        getInventoryStateId(InventoryItemState.DECLINED) === stateId
          ? getInventoryStateId(InventoryItemState.ORDERED)
          : getInventoryStateId(InventoryItemState.DECLINED)
      if (!isItemRetailOrderLineItem) {
        dispatch(editChargeSheetLineItemStatus(item, newStateId))
      }
    },
    [OrderType.PRESCRIPTION]: () => {
      const { isInHouse } = isItemRetailOrderLineItem
        ? { isInHouse: false }
        : getPrescriptionType(item.prescriptionType, item.origin)

      const orderedStateId = isInHouse
        ? getInventoryStateId(InventoryItemState.ORDERED)
        : getPrescriptionStateId(PrescriptionItemState.ORDERED)

      const stateIdDeclined = isInHouse
        ? getInventoryStateId(InventoryItemState.DECLINED)
        : getPrescriptionStateId(PrescriptionItemState.DECLINED)

      if (!isItemRetailOrderLineItem) {
        dispatch(
          editChargeSheetLineItemStatus(
            item,
            stateIdDeclined === stateId ? orderedStateId : stateIdDeclined,
          ),
        )
      }
    },
    DEFAULT: () => {},
  }

  return item
    ? (OnDeclineMap[item.logType?.toUpperCase()] ?? OnDeclineMap.DEFAULT)
    : OnDeclineMap.DEFAULT
}

export const useGetIsOrderStateDeclined = (stateId: string | Nil) => {
  const getPrescriptionStateId = useGetPrescriptionStateId()
  const getInventoryStateId = useGetInventoryStateId()
  const getProcedureStateId = useGetProcedureStateId()
  const getLabTestStateId = useGetLabTestStateId()
  const declinedLabTestStateId = getLabTestStateId(LabTestState.DECLINED)

  return stateId
    ? R.anyPass([
        R.equals(declinedLabTestStateId),
        R.equals(getProcedureStateId(ProcedureState.DECLINED)),
        R.equals(getInventoryStateId(InventoryItemState.DECLINED)),
        R.equals(getPrescriptionStateId(PrescriptionItemState.DECLINED)),
      ])(stateId)
    : false
}

export const useGetIsDeclinedLineItem = (
  item: InvoiceLineItem | RetailOrderLineItem,
) => {
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const stateId = useOrderStateId(item)
  const isDeclinedCS = useGetIsOrderStateDeclined(stateId)
  return isRetailOrderLineItem(item) || !isChargeSheetEnabled
    ? item.declined
    : isDeclinedCS
}
