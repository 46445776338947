import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchDocument } from '~/store/actions/documents'
import { getDocument } from '~/store/reducers/documents'

import { DocumentCreation } from './DocumentCreation'
import DocumentDetails from './DocumentDetails'
import { DocumentDetailsNew } from './DocumentDetailsNew'

export interface DocumentDetailsContainerProps {
  itemId: string
  onClose: () => void
}

export const DocumentDetailsContainer = ({
  itemId,
  onClose,
}: DocumentDetailsContainerProps) => {
  const dispatch = useDispatch()

  const document = useSelector(getDocument(itemId))

  const isNewVersion = Boolean(document?.template?.documentElements?.length)

  const isDocumentToCreate = itemId === 'new'

  useEffect(() => {
    if (itemId && !isDocumentToCreate) {
      dispatch(fetchDocument(itemId))
    }
  }, [itemId])

  if (!document) return null

  return isNewVersion ? (
    isDocumentToCreate ? (
      <DocumentCreation onClose={onClose} />
    ) : (
      <DocumentDetailsNew document={document} onClose={onClose} />
    )
  ) : (
    <DocumentDetails itemId={itemId} onClose={onClose} />
  )
}
