import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MoreHoriz } from '@mui/icons-material'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ChewySubmark } from '@pbt/pbt-ui-components/src/icons'

import { AutoshipFrequencyOption } from '~/constants/autoship'
import { getLabOrdersByVendorId } from '~/store/duck/labOrders'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { useIsOrderDeclined } from '~/store/hooks/orders'
import {
  getClientId,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { Order } from '~/types'
import { isLabTest, isTask } from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import {
  isGroupedInvoiceItem,
  isRetailOrderLineItem,
} from '../../invoices/invoiceUtils'
import LabOrderPreviewButtons from '../../lab-tests-dashboard/LabOrderPreviewButtons'
import LabResultPreviewButtons from '../../lab-tests-dashboard/LabResultPreviewButtons'
import { AutoshipInfo } from '../../prescription/elements/AutoshipInfo/AutoshipInfo'
import {
  OrderComponentsActions,
  OrderComponentsContents,
} from '../../soap/rail/summary/orders/orderSummaryUtils'
import OrderStatusSelect from '../../soap/to-do/OrderStatusSelect'

const useStyles = makeStyles(
  (theme) => ({
    autoshipInfo: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tableRow: {
      height: 40,
      border: theme.constants.tabBorder,
    },
    tableCell: {
      padding: theme.spacing(0, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    firstCellChewyContainer: {
      display: 'flex',
    },
    chewyIcon: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      height: '24px',
      width: '24px',
    },
    denseCell: {
      padding: theme.spacing(0, 0.5),
    },
    declinedCell: {
      opacity: 0.5,
    },
    statusSelect: {
      width: 'fit-content',
      fontSize: '1rem',
    },
    spacer: {
      marginLeft: theme.spacing(1),
    },
    label: {
      fontSize: '1.4rem',
    },
    contentComponent: {
      padding: 0,
      margin: 0,
    },
    icon: {
      color: theme.colors.lowAccentText,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  { name: 'ChargesWidgetLockedItem' },
)

export interface SoapOrdersLockedItemProps {
  areChargesPostedAndEditable?: boolean
  onPrint: (order: Order, vendorId?: string) => void
  onSelectItem: (order: Order) => void
  order: Order
}

const ChargesWidgetLockedItem = ({
  areChargesPostedAndEditable,
  order,
  onPrint,
  onSelectItem,
}: SoapOrdersLockedItemProps) => {
  const vendorId = order.labTest?.vendorId

  const classes = useStyles()
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const labOrdersForVendor = useSelector(getLabOrdersByVendorId(vendorId))
  const { t } = useTranslation('Common')

  const labOrders = R.flatten(R.pluck('orders', labOrdersForVendor))

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const priceUnits = useGetFormattedPriceUnit(order.price, order.variation)
  const isOrderLineItemRetailOrderLineItem = isRetailOrderLineItem(
    order.lineItem,
  )

  const { isChewyActiveRx } = isOrderLineItemRetailOrderLineItem
    ? { isChewyActiveRx: true }
    : getPrescriptionType(order.prescriptionType, order.origin)

  const isTaskOrder = isTask(order)
  const isLabTestOrder = isLabTest(order)
  const labOrder = isLabTestOrder
    ? labOrders.find(({ id }) => id === order.labTest?.labOrderId)
    : null

  const type = order.type || order.lineItem?.logType

  const ContentComponent = OrderComponentsContents[type]
  const ActionsComponent = OrderComponentsActions[type]

  const isDeclined = useIsOrderDeclined(order)

  const displayChewyRxInfo = isChewyActiveRx && isChewyCheckoutEnabled

  return (
    <TableRow className={classes.tableRow}>
      <TableCell
        className={classNames(classes.tableCell, {
          [classes.declinedCell]: isDeclined,
        })}
      >
        <div className={classes.firstCellChewyContainer}>
          {displayChewyRxInfo && <ChewySubmark className={classes.chewyIcon} />}
          <Grid
            container
            item
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Grid item md={11} xl={11}>
              {`${order.name}${
                isDeclined ? ` (${t('Common:DECLINED').toLowerCase()})` : ''
              }`}
              {ContentComponent && (
                <ContentComponent
                  className={classes.contentComponent}
                  order={order}
                />
              )}
            </Grid>
            {displayChewyRxInfo &&
              isRetailOrderLineItem(order.lineItem) &&
              order.lineItem.autoshipFrequency &&
              order.lineItem.autoshipUnit?.name && (
                <AutoshipInfo
                  classes={{ container: classes.autoshipInfo }}
                  frequency={
                    {
                      uom: order.lineItem.autoshipUnit.name,
                      value: order.lineItem.autoshipFrequency,
                    } as AutoshipFrequencyOption
                  }
                />
              )}
            {areChargesPostedAndEditable && !isGroupedInvoiceItem(order) && (
              <MoreHoriz
                className={classes.icon}
                onClick={() => onSelectItem(order)}
              />
            )}
          </Grid>
        </div>
      </TableCell>
      <TableCell
        className={classNames(classes.tableCell, {
          [classes.declinedCell]: isDeclined,
        })}
      >
        {`${order.quantity ? `${order.quantity} ` : ''}${priceUnits}`}
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.denseCell)}>
        {!isTaskOrder && (
          <OrderStatusSelect
            disabled
            classes={{ statusSelect: classes.statusSelect }}
            order={order}
          />
        )}
      </TableCell>
      <TableCell className={classNames(classes.tableCell, classes.denseCell)}>
        <Grid container item alignItems="center" direction="column">
          <Grid container item alignItems="center" wrap="nowrap">
            {ActionsComponent && <ActionsComponent readOnly order={order} />}
            {isLabTestOrder && (
              <LabOrderPreviewButtons
                classes={{
                  root: classes.spacer,
                  label: classes.label,
                }}
                order={labOrder}
                soapBusinessId={soapBusinessId}
                soapId={soapId}
                vendorId={vendorId}
                onLabelPrint={() => onPrint(order, vendorId)}
              />
            )}
          </Grid>
          {isLabTestOrder && (
            <LabResultPreviewButtons
              classes={{
                label: classes.label,
              }}
              clientId={clientId}
              order={labOrder}
              patientId={patientId}
              soapId={soapId}
              vendorId={vendorId}
            />
          )}
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default ChargesWidgetLockedItem
