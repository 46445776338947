import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  DateFormat,
  FileTemplate,
  IFrameEvent,
  moment,
  Nil,
  PuiDialog,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { matchImageExtension } from '~/constants/extensions'
import FeatureToggle from '~/constants/featureToggle'
import { createDocument } from '~/store/actions/documents'
import {
  clearDocumentValidationError,
  fetchSoap,
  updateDocumentOnSoap,
} from '~/store/actions/soap'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getDocumentsIsSending,
  getLastCreatedDocumentId,
} from '~/store/reducers/documents'
import {
  getDocumentValidationError,
  getIsAttachingDocuments,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import {
  BaseDataHandleWithUnsavedChanges,
  Document as DocumentType,
  SoapFile,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useErrorAlert from '~/utils/useErrorAlert'

import Document, { DocumentHandle } from './Document'
import DocumentEditor, { DocumentEditorHandle } from './DocumentEditor'
import DocumentEditorActions from './DocumentEditorActions'
// @ts-ignore
import DocumentPreview from './DocumentPreview'
import DocumentPreviewActions from './DocumentPreviewActions'
import { createTemplateFromFile } from './documentUtils'

const safeGetHasChanges =
  (ref: React.RefObject<BaseDataHandleWithUnsavedChanges>) => () =>
    ref.current?.hasUnsavedChanges() || false

const useStyles = makeStyles(
  () => ({
    paper: {
      overflowY: 'visible',
      minWidth: 660,
      maxWidth: '100%',
    },
    paperOld: {
      overflowY: 'visible',
      width: 660,
      maxWidth: 660,
    },
    itemsPaper: {
      width: 976,
      maxWidth: 976,
    },
  }),
  { name: 'DocumentDialog' },
)

interface DocumentDialogProps extends BasePuiDialogProps {
  PreviewProps?: any
  conversationId?: string
  disableCloseAfterUpdate?: boolean
  document?: DocumentType
  previewOnly?: boolean
  step?: DocumentDialogStates
  view?: boolean
}

const DocumentDialog = ({
  previewOnly = false,
  PreviewProps = {},
  step: stepProp,
  document,
  open,
  view,
  onClose,
  conversationId,
  disableCloseAfterUpdate,
}: DocumentDialogProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const lastCreatedDocumentId = useSelector(getLastCreatedDocumentId)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const isDocumentFullScreenEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DOCUMENT_FULL_SCREEN),
  )

  const { t } = useTranslation(['Common', 'Dialogs'])

  const [documentCandidate, setDocumentCandidate] = useState(
    document as DocumentType,
  )
  const [step, setStep] = useState(stepProp || DocumentDialogStates.INITIAL)
  const [iframeDocument, setIframeDocument] = useState<Document | null>(null)
  const [isLoading, setIsLoading] = useState()

  const documentRef = useRef<DocumentHandle>(null)
  const editorRef = useRef<DocumentEditorHandle>(null)

  const isImage = matchImageExtension(document?.extension)
  const formattedDate = moment(document?.creationDate).format(
    DateFormat.FULL_DATE_COMMA_PIPE_TIME,
  )

  const isInputFormsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INPUT_FORMS_DOCUMENT),
  )

  useErrorAlert({
    errorSelector: getDocumentValidationError,
    onCloseAction: clearDocumentValidationError(),
    onOpenAction: soapId ? fetchSoap(soapId, soapBusinessId) : null,
  })

  const onIframeLoad = (event: IFrameEvent) => {
    const newDocument = event?.target?.contentDocument
    setIframeDocument(newDocument)
  }

  const onPreviewBackRequested = () => {
    setStep(DocumentDialogStates.EDITOR)
  }

  const doClose = () => {
    setStep(stepProp || DocumentDialogStates.INITIAL)
    setDocumentCandidate(document as DocumentType)
    setIframeDocument(null)
    if (onClose) {
      onClose()
    }
  }

  const handleClose = () => {
    if (step === DocumentDialogStates.PREVIEW && !previewOnly) {
      onPreviewBackRequested()
    } else {
      doClose()
    }
  }

  const onDocumentCreated = () => {
    doClose()
    if (lastCreatedDocumentId) {
      navigate(`/admin/catalog/documents/${lastCreatedDocumentId}`)
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onDocumentCreated,
    getDocumentsIsSending,
  )
  const setCloseAfterUpdateOn = useCloseAfterCreation(
    doClose,
    getIsAttachingDocuments,
  )

  useEffect(() => {
    setDocumentCandidate(document as DocumentType)
  }, [document])

  useEffect(() => {
    setStep(stepProp || DocumentDialogStates.INITIAL)
  }, [stepProp])

  const onDocumentUpdate = (file: FileTemplate) => {
    if (!disableCloseAfterUpdate) {
      setCloseAfterUpdateOn()
    }
    if (soapId) {
      dispatch(updateDocumentOnSoap(soapId, soapBusinessId, file as SoapFile))
    }
  }

  const onDocumentCandidateReady = (newDocument: DocumentType) => {
    if (isInputFormsEnabled) {
      navigate(`/admin/catalog/documents/new`, {
        state: {
          newDocument,
        },
      })
      doClose()
    } else {
      setDocumentCandidate(newDocument)
      setStep(DocumentDialogStates.EDITOR)
    }
  }

  const onEditorProceedRequested = () => {
    const newDocument = editorRef.current?.get()
    setDocumentCandidate(newDocument)
    setCloseAfterCreationOn()
    dispatch(createDocument(newDocument))
  }

  const onEditorPreviewRequested = () => {
    const newDocument = editorRef.current?.get()
    setDocumentCandidate(newDocument)
    setStep(DocumentDialogStates.PREVIEW)
  }

  const onEditorBackRequested = () => {
    setStep(DocumentDialogStates.INITIAL)
  }

  const onEditorFileSelected = (file: FileTemplate) => {
    const template = createTemplateFromFile(file)

    setDocumentCandidate({ ...documentCandidate, template })
    setStep(DocumentDialogStates.PREVIEW)
  }

  const onPreviewProceedRequested = (newDocument: DocumentType) => {
    setDocumentCandidate(newDocument)
    setCloseAfterCreationOn()
    dispatch(createDocument(newDocument))
  }

  const onPreviewFileSelected = (file: FileTemplate) => {
    const template = createTemplateFromFile(file)

    setDocumentCandidate({ ...documentCandidate, template })
  }

  const onDocumentSigned = (newDocumentCandidate: DocumentType | Nil) => {
    if (newDocumentCandidate) {
      setDocumentCandidate(newDocumentCandidate)
    }
  }

  const getHasUnsavedChangesForStep = R.cond([
    [R.equals(DocumentDialogStates.INITIAL), safeGetHasChanges(documentRef)],
    [R.equals(DocumentDialogStates.PREVIEW), R.F],
    [R.equals(DocumentDialogStates.EDITOR), safeGetHasChanges(editorRef)],
    [R.T, R.F],
  ])

  const getHasUnsavedChanges = () => getHasUnsavedChangesForStep(step)

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        title: t('Dialogs:DOCUMENT_DIALOG.CONFIRM_CLOSE_DIALOG_TITLE'),
        okLabel: t('Common:NO'),
        notOkLabel: t('Common:YES'),
        onOk: R.F,
      }}
      actions={
        <>
          {step === DocumentDialogStates.PREVIEW && (
            <DocumentPreviewActions
              conversationId={conversationId}
              document={documentCandidate}
              iframeDocument={iframeDocument}
              showSaveButton={!isImage}
              view={view}
              onBack={onPreviewBackRequested}
              onDocumentSigned={onDocumentSigned}
              onDocumentUpdate={onDocumentUpdate}
              onFileSelected={onPreviewFileSelected}
              onProceed={onPreviewProceedRequested}
              {...PreviewProps}
            />
          )}
          {step === DocumentDialogStates.EDITOR && (
            <DocumentEditorActions
              document={documentCandidate}
              onBack={onEditorBackRequested}
              onFileSelected={onEditorFileSelected}
              onPreview={onEditorPreviewRequested}
              onProceed={onEditorProceedRequested}
            />
          )}
        </>
      }
      aria-labelledby="document-dialog"
      classes={{
        paper: isDocumentFullScreenEnabled ? classes.paper : classes.paperOld,
      }}
      disableClose={isLoading}
      hasUnsavedChanges={getHasUnsavedChanges}
      open={open}
      scroll="paper"
      title={
        <>
          {step === DocumentDialogStates.INITIAL &&
            t('Dialogs:DOCUMENT_DIALOG.NEW_DOCUMENT')}
          {step === DocumentDialogStates.EDITOR &&
            t('Dialogs:DOCUMENT_DIALOG.CREATE_YOUR_DOCUMENT')}
          {step === DocumentDialogStates.PREVIEW && t('Common:PREVIEW_NOUN')}
        </>
      }
      onClose={handleClose}
    >
      {step === DocumentDialogStates.INITIAL && (
        <Document
          document={documentCandidate}
          ref={documentRef}
          onProceed={onDocumentCandidateReady}
        />
      )}
      {step === DocumentDialogStates.EDITOR && (
        <DocumentEditor document={documentCandidate} ref={editorRef} />
      )}
      {step === DocumentDialogStates.PREVIEW && (
        <>
          <DocumentPreview
            hideDownloadButton
            document={documentCandidate}
            fullScreen={isDocumentFullScreenEnabled}
            setIsLoading={setIsLoading}
            view={view}
            onFileSelected={onPreviewFileSelected}
            onIframeLoad={onIframeLoad}
            {...PreviewProps}
          />
          <Grid container alignItems="flex-end" direction="column" pr={2}>
            {isImage && document?.author && (
              <Text inline variant="lowAccent2">
                {t('Common:ADDED_BY_PERSON_NAME', {
                  personName: Utils.getPersonString(document.author),
                })}
              </Text>
            )}
            {isImage && document?.creationDate && (
              <Text inline variant="lowAccent2">
                {formattedDate}
              </Text>
            )}
          </Grid>
        </>
      )}
    </PuiDialog>
  )
}

export default DocumentDialog
