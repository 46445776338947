import * as R from 'ramda'
import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { ApiError, AtLeast } from '@pbt/pbt-ui-components'
import { GraphQLError } from '@pbt/pbt-ui-components/src/utils/errorTypes'

import * as API from '~/api'
import { InvoiceLineItem } from '~/api/graphql/generated/types'
import FeatureToggle from '~/constants/featureToggle'
import { Invoice } from '~/types'

import { updateInvoices } from '../actions/finance'
import { getStatus } from '../duck/errors'
import { DISMISS_ALERT, registerWarnAlert } from '../duck/uiAlerts'
import { getCurrentBusinessIsOmniChannel } from '../reducers/auth'
import { getFeatureToggle } from '../reducers/constants'
import { getFinanceInvoice } from '../reducers/finance'
import {
  fetchInvoiceV3,
  fetchInvoiceV3Failure,
  fetchInvoiceV3Success,
  getInvoiceV3Id,
  postInvoice,
  postInvoiceFailure,
  postInvoiceSuccess,
  updateInvoiceLineItemProducerBatch,
  updateInvoiceLineItemProducerFailure,
  updateInvoiceLineItemProducerSuccess,
  updateInvoiceNotesOrStatus,
  updateInvoiceNotesOrStatusFailure,
  updateInvoiceNotesOrStatusSuccess,
  voidInvoice,
  voidInvoiceFailure,
  voidInvoiceSuccess,
} from '../reducers/invoiceV3'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchInvoiceSaga({
  payload,
}: ReturnType<typeof fetchInvoiceV3>) {
  const isIpoM1CheckoutEnabled: boolean = yield select(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )
  const isOmniChannelClinic: boolean = yield select(
    getCurrentBusinessIsOmniChannel,
  )
  const isChewyCheckoutEnabled = isOmniChannelClinic && isIpoM1CheckoutEnabled
  try {
    const invoiceId: string = yield select(getInvoiceV3Id)
    const { entities, result } = yield requestAPI(
      API.fetchInvoiceV3(isChewyCheckoutEnabled),
      payload?.id || invoiceId,
      payload?.includeDeleted,
    )

    yield put(fetchInvoiceV3Success(result))
    yield call(updateEntities, entities)
  } catch (error) {
    yield put(fetchInvoiceV3Failure({ error: error as ApiError }))
  }
}

export function* postInvoiceSaga({ payload }: ReturnType<typeof postInvoice>) {
  const isIpoM1CheckoutEnabled: boolean = yield select(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )
  const isOmniChannelClinic: boolean = yield select(
    getCurrentBusinessIsOmniChannel,
  )
  const isChewyCheckoutEnabled = isOmniChannelClinic && isIpoM1CheckoutEnabled
  try {
    const { entities, result } = yield requestAPI(
      API.postInvoice(isChewyCheckoutEnabled),
      payload,
    )
    yield put(postInvoiceSuccess(result))
    yield call(updateEntities, entities)
  } catch (err) {
    const error = err as GraphQLError
    const status = getStatus({ error, type: postInvoice.type })
    if (status === 409) {
      yield put(registerWarnAlert(error.message || error.originalMessage))
    }
    yield put(postInvoiceFailure({ error: error as ApiError }))
  }
}

export function* updateInvoiceLineItemProducerSaga({
  payload,
}: ReturnType<typeof updateInvoiceLineItemProducerBatch>) {
  try {
    const lineItem: InvoiceLineItem = yield requestAPI(
      API.updateInvoiceLineItemProducerBatch,
      { input: payload },
    )
    yield put(updateInvoiceLineItemProducerSuccess([lineItem]))
  } catch (error: any) {
    if (
      getStatus({ error, type: updateInvoiceLineItemProducerFailure.type }) ===
      409
    ) {
      const {
        message,
        payload: { data },
      } = error as GraphQLError
      const items = R.pipe<
        (typeof data)[],
        AtLeast<any, 'name'>[],
        string[],
        string
      >(
        R.prop('updateInvoiceLineItemProducerBatch'),
        R.pluck('name'),
        R.join(','),
      )(data)
      yield put(registerWarnAlert(`${items}\n${message}`))
    } else {
      yield put(
        updateInvoiceLineItemProducerFailure({ error: error as ApiError }),
      )
    }
  }
}

function* handleVoidFinanceInvoice(invoiceId: string) {
  const financeInvoice: Invoice | undefined = yield select(
    getFinanceInvoice(invoiceId),
  )

  if (financeInvoice) {
    yield put(
      updateInvoices({
        [invoiceId]: {
          ...financeInvoice,
          posted: false,
        },
      }),
    )
  }
}

export function* voidInvoiceSaga({ payload }: ReturnType<typeof voidInvoice>) {
  const isIpoM1CheckoutEnabled: boolean = yield select(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )
  const isOmniChannelClinic: boolean = yield select(
    getCurrentBusinessIsOmniChannel,
  )
  const isChewyCheckoutEnabled = isOmniChannelClinic && isIpoM1CheckoutEnabled
  try {
    const { entities, result } = yield requestAPI(
      API.voidInvoice(isChewyCheckoutEnabled),
      payload,
    )
    yield put(voidInvoiceSuccess(result))
    yield handleVoidFinanceInvoice(payload.invoiceId)
    yield call(updateEntities, entities)
  } catch (error) {
    yield put(voidInvoiceFailure({ error: error as ApiError }))
  }
}

export function* updateInvoiceStatusOrNotesSaga({
  payload,
}: ReturnType<typeof updateInvoiceNotesOrStatus>) {
  const isIpoM1CheckoutEnabled: boolean = yield select(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )
  const isOmniChannelClinic: boolean = yield select(
    getCurrentBusinessIsOmniChannel,
  )
  const isChewyCheckoutEnabled = isOmniChannelClinic && isIpoM1CheckoutEnabled
  try {
    const { entities, result } = yield requestAPI(
      API.udpateInvoiceStatusAndNotes(isChewyCheckoutEnabled),
      payload,
    )
    yield call(updateEntities, entities)
    yield put(updateInvoiceNotesOrStatusSuccess(result))
  } catch (error) {
    yield put(updateInvoiceNotesOrStatusFailure({ error: error as ApiError }))
  }
}

export function* refetchInvoice() {
  const invoiceId: string = yield select(getInvoiceV3Id)
  if (invoiceId) {
    yield put(fetchInvoiceV3({ id: invoiceId }))
  }
}

function* watchFetchInvoice() {
  yield takeLeading(fetchInvoiceV3.type, fetchInvoiceSaga)
}

function* watchPostInvoice() {
  yield takeLeading(postInvoice.type, postInvoiceSaga)
}

function* watchUpdateInvoiceLineItemProducer() {
  yield takeLeading(
    updateInvoiceLineItemProducerBatch.type,
    updateInvoiceLineItemProducerSaga,
  )
}

function* watchVoidInvoice() {
  yield takeLeading(voidInvoice.type, voidInvoiceSaga)
}

function* watchUpdateInvoiceNotesOrStatus() {
  yield takeLeading(
    updateInvoiceNotesOrStatus.type,
    updateInvoiceStatusOrNotesSaga,
  )
}

function* watchRefetchInvoice() {
  yield takeLeading(DISMISS_ALERT, refetchInvoice)
}

export default function* invoiceV3Saga() {
  yield all([
    watchFetchInvoice(),
    watchPostInvoice(),
    watchUpdateInvoiceLineItemProducer(),
    watchVoidInvoice(),
    watchUpdateInvoiceNotesOrStatus(),
    watchRefetchInvoice(),
  ])
}
