import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BillingAddress, Nil, Text, Utils } from '@pbt/pbt-ui-components'

import { PaymentTypes } from '~/constants/financeConstants'
import PaymentProcessors from '~/constants/PaymentProcessors'
import PaymentType, { PaymentAdjustment } from '~/constants/paymentTypes'
import {
  chargePayment,
  clearProcessedBillingAddress,
  createStripePayment,
  rhapsodyGoAuthorization,
} from '~/store/actions/payments'
import {
  getRhapsodyPayConfig,
  getRhapsodyPayIsLoading,
} from '~/store/duck/rhapsodyPay'
import {
  getCurrentBusiness,
  getCurrentBusinessId,
  getCurrentUserId,
} from '~/store/reducers/auth'
import {
  getPaymentsIsLoading,
  getProcessedBillingAddress,
} from '~/store/reducers/payments'
import { getPatientId } from '~/store/reducers/soap'
import { getMultipleUsers, getUser } from '~/store/reducers/users'
import { ExtendPayment } from '~/types'
import { filterUsers } from '~/utils'

import BillingAddresses from '../BillingAddresses'
import ManualPayment from './ManualPayment'
import PaymentCardDetails from './PaymentCardDetails'
import PaymentInitialization from './PaymentInitialization'
import PaymentInvoiceSelection from './PaymentInvoiceSelection'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: 650,
      maxWidth: 650,
    },
    header: {
      padding: theme.spacing(3, 3, 1, 3),
      borderBottom: theme.constants.tabBorder,
    },
  }),
  { name: 'PaymentForm' },
)

export const Steps = {
  INITIALIZATION: 'INITIALIZATION',
  MANUAL_PAYMENT_DETAILS: 'MANUAL_PAYMENT_DETAILS',
  INVOICE_SELECTION: 'INVOICE_SELECTION',
  BILLING_ADDRESSES: 'BILLING_ADDRESSES',
  CARD_DETAILS: 'CARD_DETAILS',
}

export interface PaymentFormProps {
  ComponentProps: any
  clientId: string | Nil
  depositExceedsInvoiceAmountRefund?: boolean
  isAdjustment?: boolean
  isInvoiceRefund?: boolean
  isRefund?: boolean
  isReverseGo?: boolean
  isReversePayment?: boolean
  isVoid?: boolean
  onClose: () => void
  onOk?: () => void
  payment: ExtendPayment | Nil
}

const PaymentForm = ({
  payment,
  clientId,
  ComponentProps,
  onClose,
  onOk = R.identity<void>,
  isInvoiceRefund = false,
  isAdjustment = false,
  depositExceedsInvoiceAmountRefund = false,
  isRefund = depositExceedsInvoiceAmountRefund,
  isReverseGo = false,
  isReversePayment = false,
  isVoid = false,
}: PaymentFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { patientId: patientIdFromRoute } = useParams()
  const { t } = useTranslation('Common')

  const isFinanceLoading = useSelector(getPaymentsIsLoading)
  const isRhapsodyPayConfigLoading = useSelector(getRhapsodyPayIsLoading)
  const client = useSelector(getUser(clientId))
  const coparents = useSelector(getMultipleUsers(client?.coparents))
  const isLoading = isFinanceLoading || isRhapsodyPayConfigLoading
  const processedBillingAddress = useSelector(getProcessedBillingAddress)
  const businessId = useSelector(getCurrentBusinessId)
  const rhapsodyPayConfig = useSelector(getRhapsodyPayConfig(businessId))
  const serviceFee = rhapsodyPayConfig?.serviceFee || 0
  const userId = useSelector(getCurrentUserId)
  const soapPatientId = useSelector(getPatientId)
  const currentBusiness = useSelector(getCurrentBusiness)

  const patientId = soapPatientId || patientIdFromRoute || ''

  const isStripePayment =
    rhapsodyPayConfig?.goProcessor === PaymentProcessors.STRIPE
  const hasSelectedInvoice =
    ComponentProps?.assignedInvoiceId || ComponentProps?.invoiceIds?.length > 0

  const balancePaymentAmount =
    client?.balance && client.balance > 0 ? client.balance : 0
  const defaultInitialPaymentAmount =
    ComponentProps?.paymentTypeName === PaymentType.DEPOSIT
      ? 0
      : balancePaymentAmount
  const initialPaymentAmount =
    ComponentProps?.invoiceAmount || defaultInitialPaymentAmount
  const isReverse = isReverseGo || isReversePayment

  const getInitialStep = () =>
    (hasSelectedInvoice && !ComponentProps?.forceInvoiceSelection) ||
    isAdjustment ||
    isInvoiceRefund ||
    isRefund ||
    isReverse ||
    isVoid
      ? Steps.INITIALIZATION
      : Steps.INVOICE_SELECTION

  const [step, setStep] = useState(getInitialStep())
  const [prevStep, setPrevStep] = useState(getInitialStep())
  const [paymentAmount, setPaymentAmount] = useState(initialPaymentAmount)
  const [procedureType, setProcedureType] = useState('')
  const [paymentType, setPaymentType] = useState(
    ComponentProps?.paymentType || PaymentTypes.CREDIT_CARD,
  )
  const [adjustment, setAdjustment] = useState<PaymentAdjustment>({
    type: 'Credit',
    notes: '',
  })
  const [invoiceIds, setInvoiceIds] = useState(ComponentProps?.invoiceIds || [])

  const [searchQuery, setSearchQuery] = useState('')
  const [fakeIsLoading, setFakeIsLoading] = useState(false)

  const searchList = filterUsers([client || {}].concat(coparents), searchQuery)

  useEffect(() => {
    if (initialPaymentAmount && !paymentAmount) {
      setPaymentAmount(initialPaymentAmount)
    }
  }, [initialPaymentAmount])

  useEffect(() => {
    setPaymentType(ComponentProps?.paymentType || PaymentTypes.CREDIT_CARD)
  }, [ComponentProps?.paymentType])

  useEffect(() => {
    setInvoiceIds(ComponentProps?.invoiceIds || [])
  }, [ComponentProps?.invoiceIds])

  const goToStep = (newStep: string) => {
    setPrevStep(step)
    setStep(newStep)
  }

  const onOkAndClose = () => {
    onOk()
    setSearchQuery('')
    dispatch(clearProcessedBillingAddress())
    onClose()
  }

  const setInitialPaymentAmount = () => {
    setPaymentAmount(Utils.round(paymentAmount / (1 + serviceFee), 2))
  }

  const onSearchQueryChange = (newQuery: string, userChanged?: boolean) => {
    setSearchQuery(newQuery)
    if (userChanged) {
      setFakeIsLoading(true)
      setTimeout(() => {
        setFakeIsLoading(false)
      })
    }
  }

  const onContinueToBillingAddresses = (type?: string) => {
    if (type) {
      setProcedureType(type)
      goToStep(Steps.BILLING_ADDRESSES)
    } else {
      goToStep(Steps.BILLING_ADDRESSES)
    }
  }

  const onContinueToPaymentDetails = () => {
    if (currentBusiness?.posPayEnabled) {
      setInitialPaymentAmount()
    }
    goToStep(Steps.MANUAL_PAYMENT_DETAILS)
  }

  const onContinueToPaymentInitialization = () => {
    goToStep(Steps.INITIALIZATION)
  }

  const charge = (
    cryptogram: string,
    transactionType: PaymentType,
    billingAddress: BillingAddress,
  ) => {
    if (!R.isEmpty(processedBillingAddress) || !R.isEmpty(billingAddress)) {
      const paymentData = {
        amount: Number(paymentAmount),
        billingAddressId: processedBillingAddress?.id || billingAddress.id,
        businessId,
        cryptogram,
        invoiceId: ComponentProps?.assignedInvoiceId,
        invoiceIds,
        notes: '',
        patientId,
        personId: clientId,
        userId,
      } as unknown as ExtendPayment

      if ((isRefund || isReverse) && payment?.goTxId) {
        paymentData.originGoTxId = payment.goTxId
      }

      dispatch(
        transactionType === PaymentType.AUTH
          ? isStripePayment
            ? createStripePayment(paymentData, transactionType)
            : rhapsodyGoAuthorization(paymentData)
          : isStripePayment
            ? createStripePayment(paymentData, transactionType)
            : chargePayment(paymentData, transactionType),
      )
    }
  }

  const onBack = () => {
    setStep(prevStep)
  }

  return (
    <Grid className={classes.container}>
      <Grid item className={classes.header}>
        <Text variant="h2">
          <>
            {step === Steps.INITIALIZATION &&
              !isReverse &&
              !isRefund &&
              !isVoid &&
              !isAdjustment &&
              t('Common:PAYMENTS.PAYMENT')}
            {step === Steps.INITIALIZATION &&
              isRefund &&
              !depositExceedsInvoiceAmountRefund &&
              t('Common:PAYMENTS.REFUND_NOUN')}
            {step === Steps.INITIALIZATION &&
              isReverse &&
              !depositExceedsInvoiceAmountRefund &&
              t('Common:PAYMENTS.REVERSE_NOUN')}
            {step === Steps.INITIALIZATION &&
              depositExceedsInvoiceAmountRefund &&
              t('Common:PAYMENTS.REFUND_DEPOSIT_GREATER_TITLE')}
            {step === Steps.INITIALIZATION &&
              isVoid &&
              t('Common:PAYMENTS.VOID')}
            {step === Steps.INITIALIZATION &&
              isAdjustment &&
              t('Common:ADJUSTMENT')}
            {step === Steps.INVOICE_SELECTION && t('Common:ADD_PAYMENT')}
            {(step === Steps.MANUAL_PAYMENT_DETAILS ||
              step === Steps.CARD_DETAILS) &&
              t('Common:PAYMENTS.PAYMENT_INFORMATION')}
            {step === Steps.BILLING_ADDRESSES &&
              t('Common:PAYMENTS.BILLING_ADDRESS')}
          </>
        </Text>
      </Grid>
      {step === Steps.INITIALIZATION && (
        <PaymentInitialization
          adjustment={adjustment}
          clientId={clientId}
          depositExceedsInvoiceAmountRefund={depositExceedsInvoiceAmountRefund}
          isAdjustment={isAdjustment}
          isInvoiceRefund={isInvoiceRefund}
          isLoading={isLoading}
          isRefund={isRefund}
          isReverseGo={isReverseGo}
          isReversePayment={isReversePayment}
          isVoid={isVoid}
          payment={payment}
          paymentAmount={paymentAmount}
          updateAdjustment={setAdjustment}
          updatePaymentAmount={setPaymentAmount}
          onBack={prevStep !== step ? onBack : undefined}
          onContinueToBillingAddresses={onContinueToBillingAddresses}
          onContinueToPaymentDetails={onContinueToPaymentDetails}
          onOk={onOkAndClose}
          {...ComponentProps}
          invoiceIds={invoiceIds}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      )}
      {step === Steps.MANUAL_PAYMENT_DETAILS && (
        <ManualPayment
          clientId={clientId}
          isLoading={isFinanceLoading}
          isLoadingSearchResults={fakeIsLoading}
          paymentAmount={paymentAmount}
          searchList={searchList}
          searchQuery={searchQuery}
          updatePaymentAmount={setPaymentAmount}
          onBack={onBack}
          onOk={onOkAndClose}
          onSearchQueryChange={onSearchQueryChange}
          {...ComponentProps}
          invoiceIds={invoiceIds}
          paymentType={paymentType}
          setInvoiceIds={setInvoiceIds}
          setPaymentType={setPaymentType}
        />
      )}
      {step === Steps.INVOICE_SELECTION && (
        <PaymentInvoiceSelection
          clientId={clientId}
          initialPaymentAmount={initialPaymentAmount}
          paymentAmount={paymentAmount}
          updatePaymentAmount={setPaymentAmount}
          onContinue={onContinueToPaymentInitialization}
          {...ComponentProps}
          invoiceIds={invoiceIds}
          paymentType={paymentType}
          setInvoiceIds={setInvoiceIds}
          setPaymentType={setPaymentType}
        />
      )}
      {step === Steps.BILLING_ADDRESSES && (
        <BillingAddresses
          clientId={clientId}
          onBack={() => {
            setInitialPaymentAmount()
            setProcedureType('')
            onBack()
          }}
          onProceed={() => goToStep(Steps.CARD_DETAILS)}
          {...ComponentProps}
        />
      )}
      {step === Steps.CARD_DETAILS && (
        <PaymentCardDetails
          clientId={clientId}
          isAchRefund={procedureType === PaymentType.ACH_REFUND}
          isAuth={procedureType === PaymentType.AUTH}
          paymentAmount={paymentAmount}
          onBack={() => {
            if (
              step === Steps.CARD_DETAILS &&
              prevStep === Steps.BILLING_ADDRESSES
            ) {
              setStep(Steps.BILLING_ADDRESSES)
              setPrevStep(Steps.INITIALIZATION)
            } else {
              onBack()
            }
            dispatch(clearProcessedBillingAddress())
          }}
          onOk={onOkAndClose}
          onProceed={charge}
          {...ComponentProps}
        />
      )}
    </Grid>
  )
}

export default PaymentForm
