import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { isRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType, ProcedureState } from '~/constants/SOAPStates'
import { getChargeSheetSubItemBySoapPatient } from '~/store/duck/clientFinanceData'
import { useIsIntegratedModality } from '~/store/hooks/imagingOrders'
import {
  useGetIsOrderDeletable,
  useGetProcedureStateId,
  useIsFood,
  useIsGlobalInventoryItem,
  useLabTestItemDecline,
} from '~/store/hooks/orders'
import {
  useGetPrescriptionStateType,
  useIsDeclinableOrDeletable,
} from '~/store/hooks/prescription'
import { getCurrentBusinessIsIdexxImagingEnabled } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getProcedureCategory,
} from '~/store/reducers/constants'
import { InvoiceLineItem } from '~/types'
import {
  convertInvoiceLineItemToSoapLog,
  convertRetailOrderLineItemToSoapLog,
} from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

type BasePermissionsParams = {
  disabled: boolean
  isEditPostedChargesEnabled: boolean
}

type PrescriptionOrInventoryPermissionsParams = BasePermissionsParams & {
  disabledDeclineToolTip: string
  disabledDeleteToolTip: string
  isChewyActiveRx: boolean
  isChewyReactiveRx?: boolean
  isContextItem: boolean
  isDeclinableOrDeletable: boolean
  isDeclined: boolean
  isDraft?: boolean
  isFood: boolean
  isGlobalItem?: boolean
  isInHouse: boolean
  isInventory?: boolean
  isPrepaidAndUsedZero?: boolean
  isSoapFinalized?: boolean
}

type LabtestPermissionsParamas = BasePermissionsParams & {
  disabledDeclineToolTip: string
  disabledDeleteToolTip: string
  isLabTestDeclined: boolean
  isLabTestDeletable: boolean
  isPrepaidAndUsedZero?: boolean
  isSoapFinalized?: boolean
  isUnderOrder: boolean
  labTestDeclineDisabled: boolean
}

type ProcedurePermissionsParams = BasePermissionsParams & {
  disabledDeclineToolTip: string
  disabledDeleteToolTip: string
  isEditable: boolean
  isImaging?: boolean
  isPrepaidAndUsedZero?: boolean
  isProcedureDeclined: boolean
  isSoapFinalized?: boolean
  showImagingEditButton?: boolean
}

const PROCEDURE_CATEGORY = {
  VACCINATION: 'Vaccinations',
  IMAGING: 'Imaging',
}

const getActionButtonsForPrescriptionOrInventoryInvoiceLineItem = ({
  disabled,
  isEditPostedChargesEnabled,
  isFood,
  isInHouse,
  isChewyActiveRx,
  isContextItem,
  isDeclinableOrDeletable,
  isGlobalItem,
  isDraft,
  isDeclined,
  isInventory,
  disabledDeleteToolTip,
  disabledDeclineToolTip,
  isSoapFinalized,
  isPrepaidAndUsedZero,
}: PrescriptionOrInventoryPermissionsParams) => {
  const isChewyDisabled = isChewyActiveRx && !isDraft

  return {
    Edit: {
      disabled: isEditPostedChargesEnabled ? isSoapFinalized : disabled,
      shouldRender:
        !isChewyDisabled && isGlobalItem
          ? isEditPostedChargesEnabled
            ? !isPrepaidAndUsedZero
            : !disabled && !isPrepaidAndUsedZero
          : false,
    },
    EditRX: {
      disabled: isEditPostedChargesEnabled ? isSoapFinalized : disabled,
      shouldRender:
        !isChewyDisabled && isFood
          ? isEditPostedChargesEnabled
            ? !isPrepaidAndUsedZero
            : !disabled && !isPrepaidAndUsedZero
          : false,
    },
    Print: {
      disabled: false,
      shouldRender: !isChewyActiveRx && isContextItem && !isInventory,
    },
    Delete: {
      disabled: disabled || isSoapFinalized,
      tooltip: disabledDeleteToolTip,
      shouldRender: isGlobalItem
        ? isEditPostedChargesEnabled
          ? true
          : isDeclinableOrDeletable
        : true,
    },
    Decline: {
      disabled: disabled || isSoapFinalized,
      tooltip: disabledDeclineToolTip,
      shouldRender: isEditPostedChargesEnabled
        ? true
        : !disabled || isDeclinableOrDeletable,
    },
    Status: {
      disabled: isEditPostedChargesEnabled
        ? (isDeclined && disabled) || isSoapFinalized
        : disabled,
      shouldRender: isEditPostedChargesEnabled
        ? !isPrepaidAndUsedZero
        : !disabled && !isPrepaidAndUsedZero,
    },
    View: {
      disabled: false,
      shouldRender: isChewyDisabled,
    },
    Info: {
      disabled: false,
      shouldRender: isInventory || (isGlobalItem ? isInHouse : true),
    },
  }
}

export const getActionButtonsForLabTestItem = ({
  disabled,
  isEditPostedChargesEnabled,
  labTestDeclineDisabled,
  isLabTestDeletable,
  isUnderOrder,
  isLabTestDeclined,
  disabledDeleteToolTip,
  disabledDeclineToolTip,
  isSoapFinalized,
  isPrepaidAndUsedZero,
}: LabtestPermissionsParamas) => ({
  Delete: {
    disabled: disabled || isSoapFinalized || !isLabTestDeletable,
    tooltip: disabledDeleteToolTip,
    shouldRender: isEditPostedChargesEnabled ? true : isLabTestDeletable,
  },
  Decline: {
    disabled:
      disabled || isSoapFinalized || (labTestDeclineDisabled && disabled),
    tooltip: disabledDeclineToolTip,
    shouldRender: isEditPostedChargesEnabled ? true : !labTestDeclineDisabled,
  },
  Info: {
    disabled: false,
    shouldRender: true,
  },
  Status: {
    disabled: isEditPostedChargesEnabled
      ? isSoapFinalized || (isLabTestDeclined && disabled)
      : disabled || isUnderOrder,
    shouldRender: !isPrepaidAndUsedZero,
  },
})

export const getActionButtonsForProcedureItem = ({
  disabled,
  isEditPostedChargesEnabled,
  isEditable,
  isProcedureDeclined,
  disabledDeleteToolTip,
  disabledDeclineToolTip,
  isSoapFinalized,
  isPrepaidAndUsedZero,
  showImagingEditButton,
  isImaging,
}: ProcedurePermissionsParams) => ({
  Edit: {
    disabled: isEditPostedChargesEnabled ? isSoapFinalized : disabled,
    shouldRender:
      isEditable && !isPrepaidAndUsedZero
        ? isImaging
          ? showImagingEditButton
          : true
        : false,
  },
  Info: {
    disabled: false,
    shouldRender: true,
  },
  Status: {
    disabled: isEditPostedChargesEnabled
      ? (isProcedureDeclined && disabled) || isSoapFinalized
      : disabled,
    shouldRender: !isPrepaidAndUsedZero,
  },
  Delete: {
    disabled: disabled || isSoapFinalized,
    tooltip: disabledDeleteToolTip,
    shouldRender: true,
  },
  Decline: {
    disabled: disabled || isSoapFinalized,
    tooltip: disabledDeclineToolTip,
    shouldRender: true,
  },
})

export const useChargeInformationPanelPermissions = (
  item: InvoiceLineItem | RetailOrderLineItem,
  isInvoice?: boolean,
  isPrepaidAndUsedZero?: boolean,
) => {
  const { t } = useTranslation(['Tooltips'])

  const disabled = isInvoice || false
  const isItemRetailOrderLineItem = isRetailOrderLineItem(item)

  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  const patientId = isItemRetailOrderLineItem ? item.patient.id : item.patientId
  const soapId = isItemRetailOrderLineItem ? item.soap?.id : item.soapId
  const chargeSheetSectionItem = useSelector(
    getChargeSheetSubItemBySoapPatient(
      patientId,
      soapId,
      isItemRetailOrderLineItem ? undefined : item?.chargeSheetSubItemId, // RetailOrderLineItem has no concept of this
    ),
  )
  const isFood = useIsFood(item)
  const isSoapFinalized: boolean = Boolean(
    R.path(['soap', 'finalized'], chargeSheetSectionItem),
  )

  const convertedToOrderItem = isItemRetailOrderLineItem
    ? convertRetailOrderLineItemToSoapLog(item)
    : convertInvoiceLineItemToSoapLog(item)

  const isGlobalItem = useIsGlobalInventoryItem(convertedToOrderItem)
  const { isInHouse, isChewyActiveRx } = isItemRetailOrderLineItem
    ? { isInHouse: false, isChewyActiveRx: true }
    : getPrescriptionType(item.prescriptionType, item.origin)
  const isContextItem = useIsCurrentContextItem(convertedToOrderItem)
  const isDeclinableOrDeletable = useIsDeclinableOrDeletable({
    orderType: convertedToOrderItem.type,
    stateId: convertedToOrderItem.stateId,
    prescriptionType: convertedToOrderItem.prescriptionType,
    origin: convertedToOrderItem.origin,
  })
  // TODO: M1 - Remove ts-ignore after https://chewyinc.atlassian.net/browse/CVC-9380
  // @ts-ignore
  const { isDraft, isDeclined } = useGetPrescriptionStateType()(item.stateId)

  const getIsDeletable = useGetIsOrderDeletable()
  const {
    declineDisabled: labTestDeclineDisabled,
    isDeclinedState: isLabTestDeclined,
  } = useLabTestItemDecline(convertedToOrderItem, disabled)

  const getProcedureStateId = useGetProcedureStateId()
  const ProcedureCategory = useSelector(getProcedureCategory)
  const isProcedureDeclined = isItemRetailOrderLineItem
    ? item.declined || false
    : item.stateId === getProcedureStateId(ProcedureState.DECLINED)
  const isIntegratedModality = useIsIntegratedModality(
    convertedToOrderItem?.procedure?.modalityId,
  )
  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )
  const showImagingEditButton = isImagingIntegrated && isIntegratedModality

  const isLabTestDeletable = getIsDeletable(convertedToOrderItem)
  const isUnderOrder = Boolean(
    R.path(['labTest', 'labOrderId'], convertedToOrderItem) ||
      R.prop('labOrderId', convertedToOrderItem),
  )

  const procedureCategoryId = isItemRetailOrderLineItem
    ? null
    : item.procedureCategoryId
  const isVaccination =
    Utils.findConstantIdByName(
      PROCEDURE_CATEGORY.VACCINATION,
      ProcedureCategory,
    ) === procedureCategoryId

  const isImaging =
    Utils.findConstantIdByName(
      PROCEDURE_CATEGORY.IMAGING,
      ProcedureCategory,
    ) === procedureCategoryId

  const disabledDeleteToolTip = t('Tooltips:DISBALED_DELETE_POSTED_CHARGE')
  const disabledDeclineToolTip = t('Tooltips:DISBALED_DECLINE_POSTED_CHARGE')
  const disabledFinalizedDeleteTooltip = t('Tooltips:DISBALED_DELETE_FINALIZED')
  const disabledFinalizedDeclineTooltip = t(
    'Tooltips:DISBALED_DECLINE_FINALIZED',
  )

  const prescriptionPermissions =
    getActionButtonsForPrescriptionOrInventoryInvoiceLineItem({
      disabled,
      isEditPostedChargesEnabled,
      isFood,
      isInHouse,
      isChewyActiveRx,
      isContextItem,
      isDeclinableOrDeletable,
      isGlobalItem,
      isDraft,
      isDeclined,
      disabledDeleteToolTip: isSoapFinalized
        ? disabledFinalizedDeleteTooltip
        : disabledDeleteToolTip,
      disabledDeclineToolTip: isSoapFinalized
        ? disabledFinalizedDeclineTooltip
        : disabledDeclineToolTip,
      isSoapFinalized,
      isPrepaidAndUsedZero,
    })

  const inventoryPermissions =
    getActionButtonsForPrescriptionOrInventoryInvoiceLineItem({
      disabled,
      isEditPostedChargesEnabled,
      isFood,
      isInHouse,
      isChewyActiveRx,
      isContextItem,
      isDeclinableOrDeletable,
      isGlobalItem,
      isDraft,
      isDeclined,
      isInventory: true,
      disabledDeleteToolTip: isSoapFinalized
        ? disabledFinalizedDeleteTooltip
        : disabledDeleteToolTip,
      disabledDeclineToolTip: isSoapFinalized
        ? disabledFinalizedDeclineTooltip
        : disabledDeclineToolTip,
      isSoapFinalized,
      isPrepaidAndUsedZero,
    })

  const labTestPermissions = getActionButtonsForLabTestItem({
    disabled,
    isLabTestDeletable,
    labTestDeclineDisabled,
    isEditPostedChargesEnabled,
    isLabTestDeclined,
    isUnderOrder,
    disabledDeleteToolTip: isSoapFinalized
      ? disabledFinalizedDeleteTooltip
      : disabledDeleteToolTip,
    disabledDeclineToolTip: isSoapFinalized
      ? disabledFinalizedDeclineTooltip
      : disabledDeclineToolTip,
    isSoapFinalized,
    isPrepaidAndUsedZero,
  })

  const procedurePermission = getActionButtonsForProcedureItem({
    disabled,
    isEditPostedChargesEnabled,
    isEditable: isImaging || isVaccination,
    isProcedureDeclined,
    disabledDeleteToolTip: isSoapFinalized
      ? disabledFinalizedDeleteTooltip
      : disabledDeleteToolTip,
    disabledDeclineToolTip: isSoapFinalized
      ? disabledFinalizedDeclineTooltip
      : disabledDeclineToolTip,
    isSoapFinalized,
    isPrepaidAndUsedZero,
    showImagingEditButton,
    isImaging,
  })

  const PermissionsMapLineItem = {
    [OrderType.PRESCRIPTION]: prescriptionPermissions,
    [OrderType.LAB_TEST]: labTestPermissions,
    [OrderType.PROCEDURE]: procedurePermission,
    [OrderType.INVENTORY]: inventoryPermissions,
  }

  return R.propOr(null, R.prop('logType', item), PermissionsMapLineItem)
}
