import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import NotesIcon from '@mui/icons-material/Notes'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import ViewStreamIcon from '@mui/icons-material/ViewStream'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import deepEqual from 'fast-deep-equal'
import { isEmpty } from 'ramda'
import {
  FileTemplate,
  PermissionArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'
import {
  Calendar,
  CheckList,
  Signature,
} from '@pbt/pbt-ui-components/src/icons'

import ActionsButton from '~/components/common/buttons/ActionsButton'
import PreviewButton from '~/components/common/buttons/PreviewButton'
import Expander from '~/components/common/lists/Expander'
import PanelAccordion from '~/components/common/PanelAccordion'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import {
  deleteDocument,
  editDocument,
  generateFileForDocumentTemplate,
} from '~/store/actions/documents'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getDocumentsIsDeleting,
  getDocumentsIsGenerating,
  getDocumentsIsReceiving,
  getDocumentsIsSending,
  getGeneratedDocumentTemplateFile,
} from '~/store/reducers/documents'
import { Document as DocumentType } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import Document, { DocumentHandle } from './Document'
import { DocumentPreviewHandle } from './DocumentPreview'
import { createTemplateFromFile } from './documentUtils'
import { useDocumentDetailsDisabled } from './useDocumentDeatilsDisabled'

const StyledActionsButton = styled(ActionsButton)`
  && {
    width: 125px;
    justify-content: space-around;
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
  }
`

export interface DocumentDetailsNewProps {
  document: DocumentType
  onClose: () => void
}

export const DocumentDetailsNew = ({
  document,
  onClose,
}: DocumentDetailsNewProps) => {
  const dispatch = useDispatch()

  const isSending = useSelector(getDocumentsIsSending)
  const isDeleting = useSelector(getDocumentsIsDeleting)
  const isReceiving = useSelector(getDocumentsIsReceiving)
  const generatedDocumentTemplateFile = useSelector(
    getGeneratedDocumentTemplateFile,
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.FORM))

  const { t } = useTranslation('Common')

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)
  const [openDeleteDocumentAlert, closeDeleteDocumentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const [documentCandidate, setDocumentCandidate] = useState(document)
  const [isDocumentChanged, setIsDocumentChanged] = useState(false)

  const setCloseOnDelete = useCloseAfterCreation(
    onClose,
    getDocumentsIsDeleting,
  )

  const documentRef = useRef<DocumentHandle>(null)
  const documentPreviewRef = useRef<DocumentPreviewHandle>(null)

  const disabled = useDocumentDetailsDisabled(document?.businessId)

  const getNewDocument = () =>
    ({
      ...documentRef.current?.get(),
      template: documentPreviewRef.current?.getTemplate(),
    } as DocumentType)

  const getUnsavedData = () => {
    const documentIsReady =
      Boolean(document) &&
      Boolean(documentCandidate?.template) &&
      Boolean(documentPreviewRef.current?.getTemplateReady()) &&
      !isEmpty(documentCandidate)

    return documentIsReady && !deepEqual(documentCandidate, getNewDocument())
  }

  const onDataChange = () => {
    setIsDocumentChanged(getUnsavedData())
  }

  useEffect(() => {
    setDocumentCandidate(document)
    onDataChange()
  }, [document])

  const validate = () => documentRef.current?.validate()

  const onSaveRequested = () => {
    if (validate()) {
      const newDocument = getNewDocument()
      dispatch(editDocument(newDocument))
    }
  }

  const onDeleteRequested = () => {
    openDeleteDocumentAlert({
      message: getDeleteConfirmMessage(t('Common:DOCUMENT_ONE').toLowerCase()),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteDocumentAlert(),
      onOk: () => {
        setCloseOnDelete()
        dispatch(deleteDocument(documentCandidate!.id))
        closeDeleteDocumentAlert()
      },
    })
  }

  const onCloneRequested = () => {
    openDocumentDialog({
      PreviewProps: { hideEmail: true, hidePrint: true },
      document: {
        ...documentCandidate,
        name: `${documentCandidate!.name}_copy`,
      } as DocumentType,
    })
  }

  const onPreviewFileSelected = (file: FileTemplate) => {
    const template = createTemplateFromFile(file) as FileTemplate

    setIsDocumentChanged(true)
    setDocumentCandidate({ ...documentCandidate!, template })
  }

  const previewDocument = (documentToPreview: DocumentType) => {
    openDocumentDialog({
      document: documentToPreview,
      previewOnly: true,
      PreviewProps: {
        resolvePlaceholders: true,
        hideEmail: true,
        hidePrint: true,
        onFileSelected: onPreviewFileSelected,
      },
      step: DocumentDialogStates.PREVIEW,
      view: true,
    })
  }

  const onGeneratedFileForTemplate = () => {
    previewDocument({
      ...documentCandidate!,
      template: generatedDocumentTemplateFile!,
    })
  }

  const previewAfterTemplateGeneration = useCloseAfterCreation(
    onGeneratedFileForTemplate,
    getDocumentsIsGenerating,
  )

  const onPreviewRequested = () => {
    const isTemplate = !documentCandidate?.template?.fileUrl
    if (isTemplate) {
      previewAfterTemplateGeneration()
      dispatch(generateFileForDocumentTemplate(document.id))
    } else {
      previewDocument(documentCandidate)
    }
  }

  const { fields } = useFields([
    {
      name: 'name',
      label: t('Common:NAME'),
      validators: ['required'],
      initialValue: document.name,
    },
  ])

  const { name } = fields

  const getActions = () => [
    {
      title: t('Common:CONTENT'),
      id: 'content',
      isGroup: true,
      items: [
        {
          id: 'page-break',
          label: t('Common:PAGE_BREAK'),
          Icon: ViewStreamIcon,
          onClick: () => {},
        },
        {
          id: 'rich-text',
          label: t('Common:RICH_TEXT'),
          Icon: NotesIcon,
          onClick: () => {},
        },
      ],
    },
    {
      title: t('Common:USER_INPUT'),
      id: 'user-input',
      isGroup: true,
      items: [
        {
          id: 'date',
          label: t('Common:DATE_TIME'),
          Icon: Calendar,
          onClick: () => {},
        },
        {
          id: 'date-time',
          label: t('Common:DATE_AND_TIME'),
          Icon: Calendar,
          onClick: () => {},
        },
        {
          id: 'multiple-choice',
          label: t('Common:MULTIPLE_CHOICE'),
          Icon: RadioButtonCheckedIcon,
          onClick: () => {},
        },
        {
          id: 'check-boxes',
          label: t('Common:CHECK_BOXES'),
          Icon: CheckList,
          onClick: () => {},
        },
        {
          id: 'single-checkbox',
          label: t('Common:SINGLE_CHECKBOX'),
          Icon: CheckBoxIcon,
          onClick: () => {},
        },
        {
          id: 'signature',
          label: t('Common:SIGNATURE'),
          Icon: Signature,
          onClick: () => {},
        },
        {
          id: 'text-field',
          label: t('Common:TEXT_FIELD'),
          Icon: TextFieldsIcon,
          onClick: () => {},
        },
      ],
    },
  ]

  return (
    <Expander
      expandedItemClass={t('Common:DOCUMENT_ONE').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isDocumentChanged}
      isDeleting={isDeleting}
      isFetching={isReceiving}
      isSaving={isSending}
      showButtons={permissions.update}
      onBack={onClose}
      onCloneRequested={onCloneRequested}
      onDeleteRequested={disabled ? undefined : onDeleteRequested}
      onSaveRequested={onSaveRequested}
    >
      <Grid>
        <Grid container pb={2} wrap="nowrap">
          <PuiTextField field={name} label={`${name.label}*`} />
          <Grid alignSelf="flex-end" pb={2} px={3}>
            <PreviewButton
              noWrap
              fontSize="1.4rem"
              label={t('Common:PREVIEW_FORM')}
              onClick={onPreviewRequested}
            />
          </Grid>
        </Grid>
        <Grid container wrap="nowrap">
          <PanelAccordion noPadding title={t('Common:FORM_CONFIGURATION')}>
            <Document
              view
              disabled={disabled}
              document={documentCandidate}
              ref={documentRef}
              onDocumentChange={onDataChange}
            />
          </PanelAccordion>
          <Grid alignSelf="flex-end" px={2} py={0.5}>
            <StyledActionsButton useNormalButton actions={getActions()}>
              {t('Common:ADD_ACTION')}
            </StyledActionsButton>
          </Grid>
        </Grid>
      </Grid>
    </Expander>
  )
}
