import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  CircularProgress,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  LanguageUtils,
  Nil,
  SentenceFormatter,
  Text,
} from '@pbt/pbt-ui-components'

import {
  LineItemPostDataInput,
  RetailOrderLineItem,
} from '~/api/graphql/generated/types'
import ColGroupList from '~/components/common/lists/ColGroupList'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { FINANCE_TABLE_PADDING_X_SPACING_VALUE } from '~/constants/financeTable'
import PaymentType from '~/constants/paymentTypes'
import { fetchClient } from '~/store/actions/clients'
import { closeSoap, fetchSoap } from '~/store/actions/soap'
import {
  checkPendingOutstandingOrdersForInvoices,
  getChargeSheet,
  getChargeSheetClientBalance,
  getChargeSheetSubItemsBySoapId,
  getClientFinanceError,
  getClientFinanceLoading,
  getHasPendingOutstandingOrdersWhilePostingInvoices,
  getIsCheckingPendingOutstandingOrdersWhilePostingInvoices,
  getMultipleChargeSheetSubItems,
  resetChargeSheet,
  useIsAnyPrepaid,
} from '~/store/duck/clientFinanceData'
import { fetchModality } from '~/store/duck/imagingOrders'
import {
  useChargeSheetSectionMap,
  useCheckFinalizedOrders,
  useGetOpenAddInvoiceDialogAfterOrderFiltersFetched,
} from '~/store/hooks/chargeSheet'
import {
  PostInvoiceAdditionalInput,
  useAddDiscountReasonDialog,
  useConfirmAlertForPostingInvoice,
} from '~/store/hooks/invoiceV3'
import { getCurrentUserId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getInvoiceV3,
  getInvoiceV3Error,
  getInvoiceV3Id,
  getInvoiceV3Loading,
  postInvoice,
} from '~/store/reducers/invoiceV3'
import { getPatientId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import {
  ChargeSheetItemSection,
  CustomNavigationPrevState,
  DiscountReason,
  InvoiceLineItem,
} from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import { getValidRefundPaymentForDeposit } from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import { isRetailOrderLineItem } from '../invoices/invoiceUtils'
import { useGetPaymentReversalOptions } from '../invoices/payment/hooks/useGetPaymentReversalOptions'
import { LineItemComponentsActions } from '../soap/rail/summary/orders/orderSummaryUtils'
import { CHARGES_COLUMNS } from '../soapV2/charges/table/Columns'
import ChargeSheetFooter from './ChargeSheetFooter'
import ChargeSheetTableFooter from './ChargeSheetTableFooter'
import ChargeSheetTableSubHeader from './ChargeSheetTableSubHeader'
import ChargesHeader from './components/ChargesHeader'
import ChargesPanel from './components/ChargesPanel'

const useFetchSoap = (soapId: string | Nil, patientId: string | Nil) => {
  const dispatch = useDispatch()
  const isChargeSheetLoading = useSelector(getClientFinanceLoading)

  useEffect(() => {
    if (soapId && !patientId && !isChargeSheetLoading) {
      dispatch(fetchSoap(soapId))
    }
  }, [soapId, patientId, isChargeSheetLoading])

  useEffect(
    () => () => {
      if (soapId) {
        dispatch(closeSoap())
      }
    },
    [],
  )
}

const useStyles = makeStyles(
  (theme) => ({
    tableTitle: {
      height: theme.spacing(3),
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    tableSubTitle: {
      padding: theme.spacing(0.5, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    header: {
      boxShadow: theme.constants.listItemHeaderShadow,
    },
    chargeInformationPanel: {
      top: theme.mixins.toolbar.minHeight,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
  }),
  { name: 'ViewChargeSheet' },
)

const ViewChargeSheet = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { clientId } = useParams()
  const { t } = useTranslation(['Common', 'Payments'])
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const getPaymentReversalOptions = useGetPaymentReversalOptions()
  const { fromParams } = (location.state as CustomNavigationPrevState) || {}
  const { soapId: soapIdParam } = fromParams || {}

  const isChargeSheetLoading = useSelector(getClientFinanceLoading)
  const isChargeSheetError = useSelector(getClientFinanceError)
  const client = useSelector(getUser(clientId))
  const patientIdState = useSelector(getPatientId)
  const chargeSheet = useSelector(getChargeSheet)
  const modifierId = useSelector(getCurrentUserId)
  const invoiceId = useSelector(getInvoiceV3Id)
  const invoice = useSelector(getInvoiceV3(invoiceId))
  const clientBalance = useSelector(getChargeSheetClientBalance)
  const soapSections = useSelector(getChargeSheetSubItemsBySoapId(soapIdParam))
  const isChewyRxFinalizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_RX_FINALIZATION),
  )
  const isIpoM0EstimatesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )
  const {
    activeRx: hasPendingActiveRx,
    imaging: hasPendingImaging,
    labTests: hasPendingLabTests,
  } = useSelector(getHasPendingOutstandingOrdersWhilePostingInvoices)
  const error = useSelector(getInvoiceV3Error)

  const openAddDialogAfterFetch =
    useGetOpenAddInvoiceDialogAfterOrderFiltersFetched()

  const sectionsIdsInOrder = chargeSheet?.sections
    ? [...chargeSheet.sections, 'clientBalanceSection']
    : []
  const sections = useChargeSheetSectionMap(clientId)
  // @ts-ignore
  const chargesGroupedItems = R.pluck('groupedItems', sections) as Record<
    string,
    InvoiceLineItem[]
  >
  const chargesGroupedItemsWithClientBalance = {
    ...chargesGroupedItems,
    clientBalanceSection: [],
  }

  const [openNotFinalizedDialog] = useDialog(DialogNames.NOT_FINALIZED_ORDERS)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openRefundPaymentDialog] = useDialog(DialogNames.REFUND_PAYMENT)

  const soapPatientId = R.head(soapSections)?.patientId || patientIdState
  const soapClientId = R.head(soapSections)?.clientId || clientId
  const isOTCInvoice = searchParams.get('isOtcInvoice')
  const patientIdProp = searchParams.get('patientId') || soapPatientId

  const [selectedSections, setSelectedSections] = useState([] as string[])
  const [shouldOpenAddDialog, setShouldOpenAddDialog] = useState(
    Boolean(isOTCInvoice) && Boolean(patientIdProp),
  )
  const areSelectedOrderFinalized = useCheckFinalizedOrders(
    clientId,
    selectedSections,
  )

  // fetch soap for soapPatientId if chargeSheet is empty
  useFetchSoap(soapIdParam, soapPatientId)

  const showPaidUsedRemainingLabel = useIsAnyPrepaid()

  useEffect(() => {
    if (!client && clientId) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  useEffect(() => {
    if (chargeSheet) {
      dispatch(fetchModality(chargeSheet?.businessId))
    }
  }, [chargeSheet])

  useEffect(
    () => () => {
      dispatch(resetChargeSheet())
    },
    [],
  )

  useEffect(() => {
    if (shouldOpenAddDialog) {
      const chargeSheetSection = R.pipe(
        R.values,
        R.find(
          R.allPass([
            R.propEq('patientId', patientIdProp),
            R.complement(R.propIs(String, 'soapId')),
          ]),
        ),
      )(sections) as ChargeSheetItemSection

      const { groupedItems, eventId } = chargeSheetSection || {}

      if (chargeSheet?.id && clientId && patientIdProp) {
        openAddDialogAfterFetch({
          id: chargeSheet?.id,
          clientId,
          patientId: patientIdProp,
          groupedItems,
          soapId: soapIdParam,
          eventId,
        })
        setSearchParams({})
        setShouldOpenAddDialog(false)
      }
    }
  }, [shouldOpenAddDialog, chargeSheet?.id])

  useWSTopic({
    wsTopic: WSTopics.CHARGE_SHEET_INVOICE_LINE_ITEM,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_POSTED_ON_CHARGE_SHEET,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CLIENT_BALANCE,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_SECTION,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_FINALIZED_ON_SOAP,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId,
      },
      actionParams: {
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_REOPEN_ON_SOAP,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId,
      },
      actionParams: {
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGE_SHEET_SPECIAL_PROCEDURE,
    context: {
      retrySubscription: false,
      topicParams: {
        clientId,
      },
    },
    unsubscribe: true,
  })

  const onHeaderClick = (sectionId?: string) => {
    if (sectionId) {
      const newSelectedSections = selectedSections.find(
        (id) => id === sectionId,
      )
        ? selectedSections.filter((id) => id !== sectionId)
        : [...selectedSections, sectionId]
      setSelectedSections(newSelectedSections)
    }
  }

  const openInvoiceAfterPost = useCloseAfterCreation(() => {
    if (invoiceId) {
      if (isIpoM0EstimatesEnabled) {
        const noDueToPay = (invoice as InvoiceV3)?.dueToPay === 0
        let validDepositRefundPayment = getValidRefundPaymentForDeposit(
          invoice as InvoiceV3,
        )
        // re-defining object because we are getting REST response on GQL call as of now
        validDepositRefundPayment = {
          ...validDepositRefundPayment,
          paymentType: PaymentType.DEPOSIT,
        }

        if (noDueToPay && validDepositRefundPayment) {
          const { canRefundNonIntegratedPayments, canRefundGoOrPos } =
            getPaymentReversalOptions(validDepositRefundPayment)
          if (canRefundNonIntegratedPayments) {
            openRefundPaymentDialog({
              clientId,
              payment: validDepositRefundPayment,
              depositExceedsInvoiceAmount: true,
            })
          } else if (canRefundGoOrPos) {
            openAddPaymentDialog({
              clientId,
              payment: validDepositRefundPayment,
              depositExceedsInvoiceAmountRefund: true,
            })
          }
        }
      }
      navigate(`/invoice/${invoiceId}`)
    }
  }, getInvoiceV3Loading)

  const checkFinalizedOrdersAndShowWarning = (): boolean => {
    if (areSelectedOrderFinalized) {
      return true
    }
    openNotFinalizedDialog()
    return false
  }

  const onAfterPostInvoice = useCloseAfterCreation(() => {
    if (!error) {
      setSelectedSections([])
    }
  }, getInvoiceV3Loading)

  const onPostInvoice = (
    postInvoiceAdditionalInput: PostInvoiceAdditionalInput,
  ) => {
    openInvoiceAfterPost()

    const isClientBalanceIncluded = selectedSections.find(
      (id) => id === 'clientBalanceSection',
    )
    const itemsToPost = R.pipe<
      (typeof selectedSections)[],
      string[],
      ChargeSheetItemSection[],
      InvoiceLineItem[],
      (InvoiceLineItem | RetailOrderLineItem)[],
      LineItemPostDataInput[]
    >(
      R.filter((item: string) => item !== 'clientBalanceSection'),
      R.map((id) => sections[id]),
      R.chain(({ groupedItems }) => groupedItems),
      R.chain(({ items, ...rest }) => items || [rest]),
      R.map((item) => ({
        id: item.id,
        patientId: isRetailOrderLineItem(item)
          ? item.patient.id
          : item.patientId,
        soapId: isRetailOrderLineItem(item) ? item.soap?.id : item.soapId,
        modificationDate: item.modificationDate,
        groupSnapshotId: isRetailOrderLineItem(item)
          ? null
          : item.groupSnapshotId,
      })),
    )(selectedSections)

    const postData = {
      chargeSheetId: chargeSheet?.id || '',
      showBalance: Boolean(isClientBalanceIncluded),
      clientBalance,
      itemsToPost,
      expectedModificationDate: chargeSheet?.modificationDate || '',
      postedById: modifierId || '',
      override: false,
      additionalDiscount: 0,
      discountReasonId: postInvoiceAdditionalInput?.discountReason?.id,
      discountReasonName: postInvoiceAdditionalInput?.discountReason?.name,
    }

    onAfterPostInvoice()
    dispatch(postInvoice(postData))
  }

  const chargeSheetItems = useSelector(
    getMultipleChargeSheetSubItems(selectedSections || []),
  )
  const selectedSubTotal = chargeSheetItems
    .map((section) => section.subtotal)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const selectedAmount = chargeSheetItems
    .map((section) => section.amount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const selectedDiscounts = chargeSheetItems
    .map((section) => section.discount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const selectedAdditionalDiscounts = chargeSheetItems
    .map((section) => section.additionalDiscount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const selectedItemDiscounts = chargeSheetItems
    .map((section) => section.itemDiscount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const selectedTaxes = chargeSheetItems
    .map((section) => section.totalTax)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const { isRedirectToInvoice, openPostInvoiceConfirmAlert } =
    useConfirmAlertForPostingInvoice(onPostInvoice)

  const { openDiscountReasonDialog } = useAddDiscountReasonDialog({
    onOk: (selectedDiscountReason?: DiscountReason) => {
      openPostInvoiceConfirmAlert({ discountReason: selectedDiscountReason })
    },
    discounts: selectedAdditionalDiscounts + selectedItemDiscounts,
  })

  const afterCheckingPendingOutstandingOrders = useCloseAfterCreation(() => {
    if (hasPendingActiveRx || hasPendingImaging || hasPendingLabTests) {
      const chewyActiveRx = t('Common:CHEWY_ACTIVE_RX')
      const imagingProcedure = t('Common:IMAGING_PROCEDURE')
      const labTest = t('Common:LAB_TEST')
      openNotFinalizedDialog({
        message: t('Dialogs:NOT_FINALIZED_DIALOG.MESSAGE', {
          items: LanguageUtils.getSentence(
            [
              hasPendingActiveRx ? chewyActiveRx : '',
              hasPendingImaging ? imagingProcedure : '',
              hasPendingLabTests ? labTest : '',
            ],
            SentenceFormatter.REGULAR,
            ' | ',
          ),
        }),
      })
    } else {
      openDiscountReasonDialog()
    }
  }, getIsCheckingPendingOutstandingOrdersWhilePostingInvoices)

  const handlePostInvoiceOld = () => {
    if (!checkFinalizedOrdersAndShowWarning()) {
      return
    }
    openDiscountReasonDialog()
  }

  const handlePostInvoice = () => {
    const originalSectionIds = R.pipe<
      Record<string, string>[],
      Record<string, string>,
      string[]
    >(
      R.pickBy((_, k) => R.includes(k, selectedSections)),
      R.values,
      // @ts-ignore
    )(R.pluck('id', sections))

    const checkPendingOutstandingOrdersOptions = {
      clientId: clientId!,
      sectionIds: originalSectionIds,
    }

    dispatch(
      checkPendingOutstandingOrdersForInvoices(
        checkPendingOutstandingOrdersOptions,
      ),
    )
    afterCheckingPendingOutstandingOrders()
  }

  const invoiceDisabled = R.pipe(
    R.filter((item) => item !== 'clientBalanceSection'),
    R.isEmpty,
  )(selectedSections)

  return (
    <>
      <ChargesHeader
        clientId={clientId}
        isError={Boolean(isChargeSheetError)}
        isLoading={isChargeSheetLoading}
        soapClientId={soapClientId}
        soapPatientId={soapPatientId}
        title={t('Payments:CHARGES')}
      />
      <Table>
        <TableHead className={classes.header}>
          <ColGroupList
            columnWidths={CHARGES_COLUMNS.map((col) => col.width)}
          />
          <TableRow>
            {CHARGES_COLUMNS.map(({ label, width, id }) => (
              <TableCell className={classes.tableTitle} key={id} width={width}>
                <Text strong variant="lowAccent2">
                  {label}
                </Text>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {CHARGES_COLUMNS.map(({ subLabels, width, id }) => (
              <TableCell
                className={classes.tableSubTitle}
                key={id}
                width={width}
              >
                {showPaidUsedRemainingLabel && subLabels && (
                  <Text variant="lowAccent5">
                    {subLabels.map((subLabel) => subLabel.label).join(' | ')}
                  </Text>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <Grid container item alignItems="center" justifyContent="center" mt={1}>
        {isChargeSheetLoading &&
        R.keys(chargesGroupedItemsWithClientBalance).length === 1 ? (
          <Grid my={4}>
            <CircularProgress color="primary" size={32} />
          </Grid>
        ) : (
          <ChargesPanel
            ComponentsActions={LineItemComponentsActions}
            Footer={ChargeSheetTableFooter}
            SubHeader={ChargeSheetTableSubHeader}
            chargesGroupedItems={chargesGroupedItemsWithClientBalance}
            chargesId={chargeSheet?.id}
            classes={{ chargeInformationPanel: classes.chargeInformationPanel }}
            clientId={clientId}
            hasHeader={false}
            sectionsIdsInOrder={sectionsIdsInOrder}
            onHeaderClick={onHeaderClick}
          />
        )}
      </Grid>
      <ChargeSheetFooter
        clientId={clientId}
        invoiceDisabled={invoiceDisabled}
        isRedirectToInvoice={isRedirectToInvoice}
        totals={{
          selectedDiscounts,
          selectedSectionAmount: selectedAmount,
          selectedSubTotal,
          selectedTaxes,
        }}
        onPostInvoice={
          isChewyRxFinalizationEnabled
            ? handlePostInvoice
            : handlePostInvoiceOld
        }
      />
    </>
  )
}

export default ViewChargeSheet
