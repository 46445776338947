import React from 'react'
import { Box, Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { HtmlNotesPreview, LanguageUtils, Text } from '@pbt/pbt-ui-components'
import { ChewySubmark } from '@pbt/pbt-ui-components/src/icons'

import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { EventProduct } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    disabled: {
      opacity: 0.5,
    },
    chewyIcon: {
      height: '2.4rem',
      width: '2.4rem',
      marginRight: theme.spacing(0.75),
    },
    cell: {
      padding: theme.spacing(1),
      border: theme.constants.tableBorder,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'EventProductsTableRow' },
)

interface EventProductsTableRowProps {
  product: EventProduct
}

const EventProductsTableRow = ({ product }: EventProductsTableRowProps) => {
  const classes = useStyles()

  const { disabled, qty, notes, details } = product

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  // const { isChewyActiveRx } = getPrescriptionType(
  //   item.prescriptionType,
  //   item.origin,
  // )
  // TODO: Add ^^ in when BE work is completed as a part of: https://chewyinc.atlassian.net/browse/CVC-7253
  const isChewyActiveRx = false // default to false for now

  const displayChewySubmark = isChewyActiveRx && isChewyCheckoutEnabled

  return (
    <TableRow className={classNames({ [classes.disabled]: disabled })}>
      <TableCell className={classes.cell}>
        <Box display="flex">
          {displayChewySubmark && (
            <ChewySubmark className={classes.chewyIcon} />
          )}
          <div>
            <Text variant="body2">
              {LanguageUtils.getTranslatedFieldName(product)}
            </Text>
            {notes && (
              <Grid item mt={1}>
                <HtmlNotesPreview
                  includeLabel
                  fontSize="1.4rem"
                  notes={notes}
                />
              </Grid>
            )}
            {details.map((detailLine) => (
              <Text key={detailLine} variant="body2">
                {detailLine}
              </Text>
            ))}
          </div>
        </Box>
      </TableCell>
      <TableCell className={classes.cell}>{qty}</TableCell>
    </TableRow>
  )
}

export default EventProductsTableRow
