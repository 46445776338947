import React from 'react'

import SoapWidget from '../../soapV2/SoapWidget'

/* TODO: M1 - Implement Chewy order component section */
export const InvoiceChewyItemsSection = () => (
  <SoapWidget
    ContainerBoxProps={{ mx: 1, my: 1.5, width: 'unset' }}
    title="Chewy.com Items"
  >
    lorem ipsum
  </SoapWidget>
)
