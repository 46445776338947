import * as R from 'ramda'
import {
  actionChannel,
  all,
  call,
  debounce,
  put,
  select,
  take,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'
import { GraphQLError } from '@pbt/pbt-ui-components/src/utils/errorTypes'

import * as API from '~/api'
import {
  InvoiceLineItem,
  ModifiedChargeSheetLineItemInput,
  RetailOrderLineItem,
} from '~/api/graphql/generated/types'
import { INVOICE_CHARGE_SHEET_TYPE } from '~/components/dashboard/charge-sheet/constants'
import { isRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import {
  ChargeSheetItemSection,
  Order,
  OrderFilter,
  PrescriptionV2,
} from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import { getClientChargesFromSubItemsMap } from '~/utils/chargeSheet'
import { getErrorMessage } from '~/utils/errors'

import { partialEditOrderSuccess } from '../actions/orders'
import { EDIT_RETAIL_ORDER_LINE_ITEM_SUCCESS } from '../actions/types/finance'
import {
  addChargeSheetItems,
  addChargeSheetItemsFailure,
  addChargeSheetItemsSuccess,
  checkPendingActiveRx,
  checkPendingActiveRxFailure,
  checkPendingActiveRxSuccess,
  checkPendingImaging,
  checkPendingImagingFailure,
  checkPendingImagingSuccess,
  checkPendingLabTests,
  checkPendingLabTestsFailure,
  checkPendingLabTestsSuccess,
  checkPendingOutstandingOrdersForInvoices,
  checkPendingOutstandingOrdersForInvoicesFailure,
  checkPendingOutstandingOrdersForInvoicesSuccess,
  deleteChargeSheetItems,
  deleteChargeSheetItemsFailure,
  deleteChargeSheetItemsSuccess,
  editChargeSheetItem,
  editChargeSheetItemFailure,
  editChargeSheetItemSuccess,
  editChargeSheetOrder,
  editChargeSheetOrderFailure,
  editChargeSheetOrderSuccess,
  editChargeSheetProducerItemBatch,
  editChargeSheetProducerItemBatchSuccess,
  editChargeSheetSectionPercentageDiscount,
  fetchBalanceChargeSheet,
  fetchBalanceChargeSheetFailure,
  fetchBalanceChargeSheetSuccess,
  fetchChargeSheetLineItem,
  fetchChargeSheetLineItemByLogId,
  fetchChargeSheetLineItemFailure,
  fetchChargeSheetLineItemSuccess,
  fetchChargeSheetOrderFilters,
  fetchChargeSheetOrderFiltersFailure,
  fetchChargeSheetOrderFiltersSuccess,
  fetchChargeSheetRetailOrderLineItem,
  fetchChargeSheetRetailOrderLineItemFailure,
  fetchChargeSheetRetailOrderLineItemSuccess,
  fetchClientChargeSheetItemsCount,
  fetchClientChargeSheetItemsCountFailure,
  fetchClientChargeSheetItemsCountSuccess,
  fetchClientFinanceCharges,
  fetchClientFinanceDataFailure,
  fetchClientFinanceDataSuccess,
  fetchOpenChargeSheet,
  fetchOpenChargeSheetFailure,
  fetchOpenChargeSheetSuccess,
  getChargeSheetSubItemsMap,
  getChargesList,
  getClientFinanceClientId,
  getCurrentChargeSheetLineItem,
  printChargeSheet,
  printChargeSheetFailure,
  printChargeSheetSuccess,
  refetchChargeSheet,
  updateClientFinanceCharges,
  updateSectionAdditionalDiscount,
  updateSectionAdditionalDiscountFailure,
  updateSectionAdditionalDiscountSuccess,
} from '../duck/clientFinanceData'
import { getStatus } from '../duck/errors'
import { registerWarnAlert } from '../duck/uiAlerts'
import { getCurrentBusinessIsOmniChannel } from '../reducers/auth'
import { getFeatureToggle } from '../reducers/constants'
import { getInvoiceV3Current } from '../reducers/invoiceV3'
import { getSoapId } from '../reducers/soap'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchClientFinanceDataSaga({
  payload,
}: ReturnType<typeof fetchClientFinanceCharges>) {
  const isIpoM1CheckoutEnabled: boolean = yield select(
    getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  )
  const isOmniChannelClinic: boolean = yield select(
    getCurrentBusinessIsOmniChannel,
  )
  const isChewyCheckoutEnabled = isOmniChannelClinic && isIpoM1CheckoutEnabled
  try {
    const { id, soapId, includeDeleted } = payload
    const { entities, result } = soapId
      ? yield requestAPI(
          API.fetchClientSoapFinanceData(isChewyCheckoutEnabled),
          id,
          soapId,
          includeDeleted,
        )
      : yield requestAPI(
          API.fetchClientFinanceData(isChewyCheckoutEnabled),
          id,
          includeDeleted,
        )
    const { chargeSheet, charges, chargesList, ...clientData } = result || {}
    yield put(
      fetchClientFinanceDataSuccess({
        clientId: clientData.id,
        chargeSheet,
        charges,
        chargesList,
      }),
    )
    yield call(updateEntities, {
      ...entities,
      users: { ...entities.users, [clientData.id]: clientData },
    })
  } catch (err) {
    const error = err as ApiError
    const errorMessage = getErrorMessage(error)
    yield put(registerWarnAlert(errorMessage))
    yield put(fetchClientFinanceDataFailure(error))
  }
}

export function* fetchClientChargeSheetItemsCountSaga({
  payload,
}: ReturnType<typeof fetchClientChargeSheetItemsCount>) {
  try {
    const { chargeSheetItemsCount } = yield requestAPI(
      API.fetchClientChargeSheetItemsCount,
      payload.clientId,
      payload.includeDeleted,
    )

    yield put(fetchClientChargeSheetItemsCountSuccess(chargeSheetItemsCount))
  } catch (error) {
    yield put(fetchClientChargeSheetItemsCountFailure(error))
  }
}

export function* printChargeSheetSaga({
  payload,
}: ReturnType<typeof printChargeSheet>) {
  try {
    const result = yield* requestAPI(
      API.printChargeSheet,
      payload.clientId,
      payload.expandedGroups,
    )
    yield put(printChargeSheetSuccess(result.content))
  } catch (error) {
    yield put(printChargeSheetFailure(error as ApiError))
  }
}

export function* addChargeSheetItemsSaga({
  payload,
}: ReturnType<typeof addChargeSheetItems>) {
  try {
    const { clientId, createItemInputs } = payload
    yield* requestAPI(API.addChargeSheetItems, clientId, createItemInputs)
    yield put(addChargeSheetItemsSuccess({ clientId }))
  } catch (error) {
    if (getStatus({ error, type: addChargeSheetItemsFailure.type }) === 409) {
      const {
        message,
        payload: { variables },
      } = error as GraphQLError
      const items = R.pipe<
        (typeof variables)[],
        InvoiceLineItem[],
        string[],
        string
      >(
        R.prop('createItemInputs'),
        R.pluck('name'),
        R.join(','),
      )(variables)
      yield put(registerWarnAlert(`${items}\n${message}`))
      yield put(refetchChargeSheet())
    } else {
      yield put(addChargeSheetItemsFailure(error))
    }
  }
}

export function* fetchChargeSheetBalanceSaga({
  payload,
}: ReturnType<typeof fetchBalanceChargeSheet>) {
  try {
    const {
      chargeSheet: { amount, amountNoFee, modificationDate },
    } = yield* requestAPI(
      API.fetchChargeSheetBalance,
      payload.id,
      payload.includeDeleted,
    )
    yield put(
      fetchBalanceChargeSheetSuccess({
        amount,
        amountNoFee,
        type: INVOICE_CHARGE_SHEET_TYPE,
        creationDate: modificationDate,
      }),
    )
  } catch (error) {
    yield put(fetchBalanceChargeSheetFailure(error))
  }
}

export function* fetchOpenChargeSheetSaga({
  payload,
}: ReturnType<typeof fetchOpenChargeSheet>) {
  try {
    const { subTotal, totalDiscount, totalTax } = yield* requestAPI(
      API.fetchOpenChargeSheet,
      payload.clientId,
    )
    yield put(
      fetchOpenChargeSheetSuccess({
        subTotal,
        totalDiscount,
        totalTax,
      }),
    )
  } catch (error) {
    yield put(fetchOpenChargeSheetFailure(error))
  }
}

export function* editChargeSheetItemSaga({
  payload,
}: ReturnType<typeof editChargeSheetItem>) {
  try {
    const { updateItemInput } = payload
    const data: InvoiceLineItem[] = yield requestAPI(
      API.editChargeSheetItemBatch,
      { updateItemInput },
    )
    yield put(editChargeSheetItemSuccess(data))
  } catch (error) {
    if (
      getStatus({
        error,
        type: updateSectionAdditionalDiscountFailure.type,
      }) === 409
    ) {
      const {
        message,
        payload: { data },
      } = error as GraphQLError
      const items = R.pipe<
        (typeof data)[],
        InvoiceLineItem[],
        string[],
        string
      >(
        R.prop('updateChargeSheetItemBatch'),
        R.pluck('name'),
        R.join(','),
      )(data)
      yield put(registerWarnAlert(`${items}\n${message}`))
      yield put(refetchChargeSheet())
    } else {
      yield put(editChargeSheetItemFailure(error))
    }
  }
}

export function* editChargeSheetItemProducerSaga({
  payload,
}: ReturnType<typeof editChargeSheetProducerItemBatch>) {
  const { updateItemProducerInput } = payload
  try {
    const data: InvoiceLineItem = yield* requestAPI(
      API.editChargeSheetLineItemProducerBatch,
      { input: updateItemProducerInput },
    )
    yield put(editChargeSheetItemSuccess([data]))
  } catch (error) {
    yield put(editChargeSheetItemFailure(error))
  }
}

export function* editChargeSheetItemProducerBatchSaga({
  payload,
}: ReturnType<typeof editChargeSheetProducerItemBatch>) {
  const { updateItemProducerInput } = payload
  try {
    const data: InvoiceLineItem[] = yield* requestAPI(
      API.editChargeSheetLineItemProducerBatch,
      {
        input: updateItemProducerInput,
      },
    )
    yield put(editChargeSheetItemSuccess(data))
    yield put(editChargeSheetProducerItemBatchSuccess())
  } catch (error) {
    yield put(editChargeSheetItemFailure(error))
  }
}

export function* deleteChargeSheetItemsSaga({
  payload,
}: ReturnType<typeof deleteChargeSheetItems>) {
  const { modifierId, items, clientId } = payload
  try {
    yield* requestAPI(API.deleteChargeSheetItems, modifierId, items)
    yield put(deleteChargeSheetItemsSuccess({ clientId }))
  } catch (error: any) {
    if (
      getStatus({ error, type: deleteChargeSheetItemsFailure.type }) === 409
    ) {
      const {
        message,
        payload: { variables },
      } = error
      const { deleteItemInputs } = variables as {
        deleteItemInputs: { expectedModificationDate: string; id: string }[]
      }
      const ids = R.pluck('id', deleteItemInputs)
      const chargesList: InvoiceLineItem[] | Nil = yield select(getChargesList)
      const subItemsMap: Record<string, ChargeSheetItemSection> | Nil =
        yield select(getChargeSheetSubItemsMap)

      const clientChargesNames: string[] =
        !R.isEmpty(chargesList) && !R.isNil(chargesList)
          ? R.pipe<(typeof chargesList)[], InvoiceLineItem[], string[]>(
              R.filter(({ id }: InvoiceLineItem) => R.includes(id, ids)),
              R.pluck('name'),
            )(chargesList)
          : !R.isEmpty(subItemsMap) && !R.isNil(subItemsMap)
            ? getClientChargesFromSubItemsMap(subItemsMap, ids)
            : ([] as string[])

      yield put(
        registerWarnAlert(`${R.join(',', clientChargesNames)}\n${message}`),
      )
      yield put(refetchChargeSheet())
    } else {
      yield put(deleteChargeSheetItemsFailure(error))
    }
  }
}

export function* refetchClientFinanceData() {
  const soapId: string = yield select(getSoapId)
  const clientId: string = yield select(getClientFinanceClientId)
  if (clientId) {
    yield put(fetchClientFinanceCharges({ id: clientId, soapId }))
  }
}

export function* fetchChargeSheetOrderFiltersSaga({
  payload,
}: ReturnType<typeof fetchChargeSheetOrderFilters>) {
  try {
    const { clientId, patientId } = payload
    const orderFilters: OrderFilter[] = yield requestAPI(
      API.fetchChargeSheetOrdersFilters,
      clientId,
      patientId,
    )
    yield put(fetchChargeSheetOrderFiltersSuccess(orderFilters))
  } catch (error) {
    yield put(fetchChargeSheetOrderFiltersFailure(error))
  }
}

export function* fetchChargeSheetLineItemSaga({
  payload,
}: ReturnType<
  typeof fetchChargeSheetLineItem | typeof fetchChargeSheetLineItemByLogId
>) {
  try {
    const { includeDeleted } = payload
    const fetchId = R.prop('id', payload) || R.prop('logId', payload)
    const chargeSheetLineItem: InvoiceLineItem = yield* requestAPI(
      R.has('id', payload)
        ? API.fetchChargeSheetLineItemById
        : API.fetchLineItemByLogIdExtended,
      fetchId,
      R.prop('logType', payload) || includeDeleted,
    )
    yield put(fetchChargeSheetLineItemSuccess(chargeSheetLineItem))
  } catch (error) {
    yield put(fetchChargeSheetLineItemFailure(error))
  }
}

export function* fetchChargeSheetRetailOrderLineItemSaga({
  payload,
}: ReturnType<typeof fetchChargeSheetRetailOrderLineItem>) {
  try {
    const fetchId = R.prop('id', payload)
    const chargeSheetLineItem: RetailOrderLineItem = yield* requestAPI(
      API.fetchChargeSheetRetailOrderLineItemById,
      fetchId,
    )
    yield put(fetchChargeSheetRetailOrderLineItemSuccess(chargeSheetLineItem))
  } catch (error) {
    yield put(fetchChargeSheetRetailOrderLineItemFailure(error))
  }
}

export function* editChargeSheetOrderStatusAndNotesSaga({
  payload,
}: ReturnType<typeof editChargeSheetOrder>) {
  try {
    const { declined, isChewyActiveRx } = payload.options || {}
    const updatedOrder = payload.order
    let modificationDate = payload.soapLogModificationDate
    const isEditPostedAllowed: boolean = yield select(
      getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
    )
    const currentInvoice: InvoiceV3 | null = yield select(getInvoiceV3Current)
    const { id: invoiceId, clientId } = currentInvoice || {}

    // For active RX status update flow we need to retrieve the next state from API
    if (isChewyActiveRx) {
      const { entities, result } = yield* requestAPI(
        declined ? API.undeclineActiveRx : API.declineActiveRx,
        { prescriptionId: payload.id },
      )
      const prescription: PrescriptionV2 | undefined =
        entities.prescriptionsV2?.[result]

      updatedOrder.stateId = prescription?.state?.id
      modificationDate = prescription?.updatedAt
    }

    const updatedOrderResponse: Order = yield* requestAPI(
      API.editChargeSheetOrder,
      payload.id,
      payload.type,
      updatedOrder,
      modificationDate,
      Boolean(isEditPostedAllowed),
    )
    if (invoiceId && clientId) {
      yield put(checkPendingLabTests({ invoiceId, clientId }))
    }
    yield put(partialEditOrderSuccess(updatedOrderResponse))
    yield put(editChargeSheetOrderSuccess([updatedOrderResponse]))
  } catch (error) {
    const status = getStatus({
      error,
      type: editChargeSheetOrder.type,
    })
    const {
      responseBody: { message, description },
    } = error as ApiError
    const errorMessage = message || description
    if (status === 409 && errorMessage) {
      yield put(registerWarnAlert(errorMessage))
    } else {
      yield put(editChargeSheetOrderFailure(error))
    }
  }
}

export function* updateSectionAdditionalDiscountSaga({
  payload,
}: ReturnType<typeof updateSectionAdditionalDiscount>) {
  try {
    const chargesSections = yield* requestAPI(
      API.updateSectionsAdditionalDiscount,
      {
        modifierId: payload.modifierId,
        updateSectionsDiscountInputs: payload.updateSectionsDiscountInputs,
      },
    )
    const clientId = R.pipe(R.head, R.prop('clientId'))(chargesSections)
    yield put(updateSectionAdditionalDiscountSuccess())
    if (clientId) {
      yield put(fetchClientFinanceCharges({ id: clientId }))
    }
  } catch (error: any) {
    if (
      getStatus({
        error,
        type: updateSectionAdditionalDiscountFailure.type,
      }) === 409
    ) {
      const { message } = error as GraphQLError
      yield put(registerWarnAlert(message))
      yield put(refetchChargeSheet())
    } else {
      yield put(
        updateSectionAdditionalDiscountFailure({ error: error as ApiError }),
      )
    }
  }
}

export function* checkPendingOutstandingOrdersForInvoicesSaga({
  payload,
}: ReturnType<typeof checkPendingOutstandingOrdersForInvoices>) {
  try {
    const {
      existsPendingActiveRxToBeFinalized,
      existsPendingImagingRequestsToBeFinalized,
      existsPendingLabTestsToBeFinalized,
    } = yield requestAPI(API.checkPendingOutstandingOrders, payload)
    yield put(
      checkPendingOutstandingOrdersForInvoicesSuccess({
        pending: {
          activeRx: existsPendingActiveRxToBeFinalized,
          imaging: existsPendingImagingRequestsToBeFinalized,
          labTests: existsPendingLabTestsToBeFinalized,
        },
      }),
    )
  } catch (error) {
    yield put(
      checkPendingOutstandingOrdersForInvoicesFailure({
        error: error as ApiError,
      }),
    )
  }
}

export function* checkPendingActiveRxSaga({
  payload,
}: ReturnType<typeof checkPendingActiveRx>) {
  try {
    const { existsPendingActiveRxToBeFinalized } = yield requestAPI(
      API.checkPendingActiveRx,
      payload,
    )
    yield put(checkPendingActiveRxSuccess(existsPendingActiveRxToBeFinalized))
  } catch (error) {
    yield put(
      checkPendingActiveRxFailure({
        error: error as ApiError,
      }),
    )
  }
}

export function* checkPendingImagingSaga({
  payload,
}: ReturnType<typeof checkPendingImaging>) {
  try {
    const { existsPendingImagingRequestsToBeFinalized } = yield requestAPI(
      API.checkPendingImaging,
      payload,
    )
    yield put(
      checkPendingImagingSuccess(existsPendingImagingRequestsToBeFinalized),
    )
  } catch (error) {
    yield put(
      checkPendingImagingFailure({
        error: error as ApiError,
      }),
    )
  }
}

export function* checkPendingLabTestsSaga({
  payload,
}: ReturnType<typeof checkPendingLabTests>) {
  try {
    const { existsPendingLabTestsToBeFinalized } = yield requestAPI(
      API.checkPendingLabTests,
      payload,
    )
    yield put(checkPendingLabTestsSuccess(existsPendingLabTestsToBeFinalized))
  } catch (error) {
    yield put(
      checkPendingLabTestsFailure({
        error: error as ApiError,
      }),
    )
  }
}

function* watchFetchClientChargeSheetItemsCount() {
  yield takeLeading(
    fetchClientChargeSheetItemsCount.type,
    fetchClientChargeSheetItemsCountSaga,
  )
}

function* watchPrintChargeSheetSaga() {
  yield takeLeading(printChargeSheet.type, printChargeSheetSaga)
}

function* watchAddChargeSheetItemsSaga() {
  yield takeLeading(addChargeSheetItems.type, addChargeSheetItemsSaga)
}

function* watchAddChargeSheetBalanceSaga() {
  yield takeLeading(fetchBalanceChargeSheet.type, fetchChargeSheetBalanceSaga)
}

function* watchAddOpenChargeSheetBalanceSaga() {
  yield takeLeading(fetchOpenChargeSheet.type, fetchOpenChargeSheetSaga)
}

function* watchEditChargeSheetItemQueueSaga() {
  const actions = [
    editChargeSheetItem.type,
    editChargeSheetOrder.type,
    deleteChargeSheetItems.type,
    updateClientFinanceCharges.type,
    fetchChargeSheetLineItem.type,
    fetchClientFinanceCharges.type,
    editChargeSheetSectionPercentageDiscount.type,
  ]

  const sagas: Record<string, (props: any) => Generator<any, void, any>> = {
    [editChargeSheetItem.type]: editChargeSheetItemSaga,
    [editChargeSheetSectionPercentageDiscount.type]: editChargeSheetItemSaga,
    [editChargeSheetOrder.type]: editChargeSheetOrderStatusAndNotesSaga,
    [deleteChargeSheetItems.type]: deleteChargeSheetItemsSaga,
    [updateClientFinanceCharges.type]: refetchClientFinanceData,
    [fetchChargeSheetLineItem.type]: fetchChargeSheetLineItemSaga,
    [fetchClientFinanceCharges.type]: fetchClientFinanceDataSaga,
  }

  let chargeSheetLineItems: (InvoiceLineItem | RetailOrderLineItem)[] = []
  const channel: string = yield actionChannel(actions)
  while (true) {
    const { payload, type, body } = yield take(channel)

    const currentLineItem: InvoiceLineItem | Nil = yield select(
      getCurrentChargeSheetLineItem,
    )

    const isCurrentLineItemShallowUpdated = R.includes(
      R.prop('id', currentLineItem),
      R.pluck('id', chargeSheetLineItems),
    )

    const isCurrentLineItemSameAsPayloadLineItem =
      R.prop('logId', currentLineItem) === R.prop('id', payload) ||
      R.path(['length', 'updateItemInput'], payload) === 1

    const canUseChargeSheetLineItem =
      isCurrentLineItemShallowUpdated && isCurrentLineItemSameAsPayloadLineItem

    const selectedScopeLineItem = canUseChargeSheetLineItem
      ? chargeSheetLineItems
      : isCurrentLineItemSameAsPayloadLineItem
        ? currentLineItem
        : {}
    const modifiedUpdateItemInputItems = R.map(
      (item) => ({
        id: item.id,
        modificationDate: item.modificationDate,
        soapLogModificationDate: isRetailOrderLineItem(item)
          ? item.soap?.updatedAt
          : item.soapLogModificationDate,
      }),
      chargeSheetLineItems,
    )

    const soapLogModificationDate = Array.isArray(selectedScopeLineItem)
      ? R.head(selectedScopeLineItem).soapLogModificationDate
      : R.prop('soapLogModificationDate', selectedScopeLineItem)

    const modifiedPayload = R.has('updateItemInput', payload)
      ? {
          updateItemInput: R.map(
            (item: ModifiedChargeSheetLineItemInput) => {
              const dates = R.find(
                R.propEq('id', item.id),
                modifiedUpdateItemInputItems,
              )

              return {
                ...item,
                expectedModification:
                  dates?.modificationDate || item.expectedModification,
                expectedSoapLogModification:
                  dates?.soapLogModificationDate ||
                  item.expectedSoapLogModification,
              }
            },
            R.propOr(
              {} as ModifiedChargeSheetLineItemInput,
              'updateItemInput',
              payload,
            ),
          ),
        }
      : R.has('soapLogModificationDate', payload)
        ? {
            ...payload,
            soapLogModificationDate:
              soapLogModificationDate || payload.soapLogModificationDate,
          }
        : payload

    yield call(sagas[type], {
      payload: modifiedPayload || body,
    })

    // We have to add this custom solution cause editOrderNotesOrStatus didn't return modificatinDate
    // thus we need to refetch invoice line item to know it
    if (
      (R.equals(editChargeSheetOrder.type, type) ||
        R.equals(updateClientFinanceCharges.type, type)) &&
      currentLineItem &&
      isCurrentLineItemSameAsPayloadLineItem
    ) {
      try {
        const chargeSheetLineItem = yield* requestAPI(
          API.fetchChargeSheetLineItemById,
          currentLineItem.id,
          payload?.includeDeleted,
        )
        if (chargeSheetLineItem) {
          chargeSheetLineItems = [chargeSheetLineItem]
        }
      } catch (error) {
        yield put(fetchChargeSheetLineItemFailure(error))
      }
    } else if (R.equals(editChargeSheetItem.type, type) && !currentLineItem) {
      const idsToFetch = R.pluck(
        'id',
        R.prop(
          'updateItemInput',
          modifiedPayload,
        ) as Array<ModifiedChargeSheetLineItemInput>,
      )
      chargeSheetLineItems = yield all(
        idsToFetch.map((id: string) =>
          requestAPI(
            API.fetchChargeSheetLineItemById,
            id,
            payload?.includeDeleted,
          ),
        ),
      )
    } else {
      chargeSheetLineItems = []
    }
  }
}

function* watchEditChargeSheetItemProdcuerBatchSaga() {
  yield takeLeading(
    editChargeSheetProducerItemBatch.type,
    editChargeSheetItemProducerBatchSaga,
  )
}

function* watchRefetchClientFinanceData() {
  yield debounce(
    Defaults.DEBOUNCE_ACTION_TIME,
    [
      addChargeSheetItemsSuccess.type,
      editChargeSheetProducerItemBatchSuccess.type,
      deleteChargeSheetItemsSuccess.type,
      refetchChargeSheet.type,
      EDIT_RETAIL_ORDER_LINE_ITEM_SUCCESS,
    ],
    refetchClientFinanceData,
  )
}

function* watchFetchOrderFilters() {
  yield takeLeading(
    fetchChargeSheetOrderFilters.type,
    fetchChargeSheetOrderFiltersSaga,
  )
}

function* watchFetchChargeSheetLineItem() {
  yield takeLeading(
    [fetchChargeSheetLineItem.type, fetchChargeSheetLineItemByLogId.type],
    fetchChargeSheetLineItemSaga,
  )
}

function* watchFetchChargeSheetRetailOrderLineItem() {
  yield takeLeading(
    [fetchChargeSheetRetailOrderLineItem.type],
    fetchChargeSheetRetailOrderLineItemSaga,
  )
}

function* wathcUpdateSectionAdditionalDiscount() {
  yield takeLeading(
    updateSectionAdditionalDiscount.type,
    updateSectionAdditionalDiscountSaga,
  )
}

function* watchCheckPendingOutstandingOrdersForInvoices() {
  yield takeLeading(
    checkPendingOutstandingOrdersForInvoices.type,
    checkPendingOutstandingOrdersForInvoicesSaga,
  )
}

function* watchCheckPendingActiveRx() {
  yield takeLeading(checkPendingActiveRx.type, checkPendingActiveRxSaga)
}

function* watchCheckPendingImaging() {
  yield takeLeading(checkPendingImaging.type, checkPendingImagingSaga)
}

function* watchCheckPendingLabTests() {
  yield takeLeading(checkPendingLabTests.type, checkPendingLabTestsSaga)
}

export default function* chargeSheetSaga() {
  yield all([
    watchFetchClientChargeSheetItemsCount(),
    watchPrintChargeSheetSaga(),
    watchAddChargeSheetItemsSaga(),
    watchAddChargeSheetBalanceSaga(),
    watchAddOpenChargeSheetBalanceSaga(),
    watchEditChargeSheetItemQueueSaga(),
    watchRefetchClientFinanceData(),
    watchFetchOrderFilters(),
    watchEditChargeSheetItemProdcuerBatchSaga(),
    watchFetchChargeSheetLineItem(),
    watchFetchChargeSheetRetailOrderLineItem(),
    wathcUpdateSectionAdditionalDiscount(),
    watchCheckPendingOutstandingOrdersForInvoices(),
    watchCheckPendingActiveRx(),
    watchCheckPendingImaging(),
    watchCheckPendingLabTests(),
  ])
}
