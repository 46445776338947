import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Document } from '~/types'

import { DocumentDetailsNew } from './DocumentDetailsNew'

interface DocumentDetailsLocationState {
  state: {
    newDocument: Document
  }
}

interface DocumentCreationProps {
  onClose: () => void
}

export const DocumentCreation = ({ onClose }: DocumentCreationProps) => {
  const location = useLocation() as DocumentDetailsLocationState
  const { newDocument } = location.state || {}
  // eslint-disable-next-line react/hook-use-state
  const [document] = useState(newDocument)
  if (!document) return null
  return <DocumentDetailsNew document={document} onClose={onClose} />
}
