import { CheckboxProps } from '@mui/material'
import moment from 'moment'
import * as R from 'ramda'
import { ControlButtonGroupItem, Nil, User } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { getHighValue } from '~/components/dashboard/invoices/invoiceUtils'
import { PrescriptionType } from '~/constants/prescription'
import { OrderType } from '~/constants/SOAPStates'
import {
  InventoryItem,
  LabTest,
  Order,
  OrderFilter,
  Price,
  Task,
  Variation,
} from '~/types'
import { InvoiceLineItem } from '~/types/entities/finance'

import { getPrescriptionType } from './prescription'

const KEY_PROPS: (keyof Order)[] = [
  'price',
  'inventory',
  'procedure',
  'labTest',
  'variation',
  'globalInventory',
  'globalVariation',
  'globalInventoryMapping',
  'globalVariationMapping',
  'taskTemplate',
]

export enum OrderStateKeys {
  INVENTORY_LOG_STATUS = 'inventoryLogStatusId',
  STATE = 'stateId',
}

export const CheckboxColor: Record<string, CheckboxProps['color']> = {
  PRIMARY: 'primary',
  DEFAULT: 'default',
}

export type GetItemColorCallback = (order: Order) => keyof typeof CheckboxColor

const isEqual = (item: Order, logItem: Order) => {
  const keyPropsEqual = KEY_PROPS.every((name) => {
    const getId = R.path([name, 'id'])
    return !logItem[name] || getId(item) === getId(logItem)
  })

  const parentsEqual = !item.parentId || item.parentId === logItem.parentId

  return keyPropsEqual && parentsEqual
}

export const findOrderItemByItem = (
  item: Order,
  list: Order[],
  withoutRefills = false,
) => {
  const arr = list.filter(
    (logItem) => isEqual(item, logItem) && (!withoutRefills || !logItem.parent),
  )
  // Get the last element; there can be cases where we have several identical lab tests in the list, as we can add the same test after declining the previous one
  return R.last(arr)
}

export const getOrderWithNotes = (
  order: Order,
  selectedOrders: Order[],
): Order => {
  const logItem = findOrderItemByItem(order, selectedOrders)
  return logItem
    ? {
        ...order,
        id: logItem.id,
        notes: logItem.notes,
        vaccinationDetails: logItem.vaccinationDetails,
        charge: logItem.charge,
        cover: logItem.cover,
      }
    : order
}

export const getOrderStateKey = (prescriptionType: PrescriptionType | Nil) =>
  getPrescriptionType(prescriptionType)?.isInHouse
    ? OrderStateKeys.INVENTORY_LOG_STATUS
    : OrderStateKeys.STATE
export const isOrderType = (order: Order, type: OrderType) =>
  order.type === type
export const isBundle = (order: Order) => isOrderType(order, OrderType.BUNDLE)
export const isProcedure = (order: Order) =>
  isOrderType(order, OrderType.PROCEDURE)
export const isImagingProcedure = (order: Order) =>
  order?.procedure?.category === 'Imaging'
export const isReminder = (order: Order) =>
  isOrderType(order, OrderType.REMINDER)
export const isWellnessPlanItem = (order: Order) =>
  isOrderType(order, OrderType.WPLANS) || isOrderType(order, OrderType.BENEFIT)
export const isLabTest = (order: Order) =>
  isOrderType(order, OrderType.LAB_TEST)
export const isTask = (order: Order) => isOrderType(order, OrderType.TASK)
export const isObsoleteGlobalItem = (
  order: Order,
  isFoodCatalogEnabled: boolean,
) =>
  isOrderType(order, OrderType.INVENTORY) &&
  order.inventory === undefined &&
  order[isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation']
    ?.obsolete === true

export const getCreateDefaultQuantityDrugExpDate = () =>
  moment().add(1, 'year').toISOString()

export const getInventoryItemCategoryId = (
  item: any,
  isFoodCatalogEnabled: boolean,
) =>
  item?.inventory?.categoryId ||
  item?.[isFoodCatalogEnabled ? 'globalInventoryMapping' : 'globalInventory']
    ?.categoryId ||
  item?.categoryId

export const hasButtons = (
  buttons: (ControlButtonGroupItem | boolean | Nil)[],
) => !R.isEmpty(buttons?.filter(R.identity))

export const isLabTestUnderOrder = (order: Order | Nil) =>
  Boolean(order?.labTest?.labOrderId)

export const getMissingPdmpReportingFields = (
  prescription: Order,
  client: User | Nil,
) => {
  const needPdmpReporting =
    prescription?.inventory?.pdmpReport &&
    getPrescriptionType(prescription.prescriptionType)?.isInHouse

  if (!needPdmpReporting) {
    return {}
  }

  return R.filter((item) => R.identity(item), {
    missingNdcNumber: !prescription.variation?.nationalDrugCode,
    missingClientAddress: [
      client?.address,
      client?.country,
      client?.city,
      client?.state,
      client?.zip,
    ].some(R.not),
    missingClientPhoneNumber: !client?.mobilePhone,
    missingClientGender: !client?.genderId,
    missingClientDOB: !client?.dateOfBirth,
  })
}

export const isOrderTask = (task: Task, order: Order) =>
  task?.orderId === order.id && task?.orderType === order.type
export const isOrder = (item: any) => Boolean(OrderType[item.type as OrderType])
export const getUniqueOrderId = (order: Order) => `${order.id}-${order.type}`
export const getOrderByUniqueId = (orderUniqueId: string, orders: Order[]) =>
  orders.find((order) => getUniqueOrderId(order) === orderUniqueId)

const RABIES_VACCINATION_CODE = '50001'

export const getIsRabiesVaccine = (order: Order) =>
  order?.procedure?.procedureCode === RABIES_VACCINATION_CODE

export const zeroUsedInvoiceItemExists = (
  appointmentLineItemsMap: Record<string, InvoiceLineItem> | Nil,
  item: Order | Nil,
) => {
  const priceId = item?.price?.id
  const lineItem =
    appointmentLineItemsMap && priceId
      ? appointmentLineItemsMap[priceId]
      : undefined

  return lineItem?.prepaid && !lineItem?.usedQuantity
}

export const getItemColor =
  (
    appointmentLineItemsMap: Record<string, InvoiceLineItem> | Nil,
    selectedOrders: Order[],
  ) =>
  (item: Order) => {
    const isSelected =
      findOrderItemByItem(item, selectedOrders || []) !== undefined
    const hasZeroUsedInInvoice = zeroUsedInvoiceItemExists(
      appointmentLineItemsMap,
      item,
    )
    return hasZeroUsedInInvoice && !isSelected
      ? CheckboxColor.DEFAULT
      : CheckboxColor.PRIMARY
  }

const isDefaultColor = R.equals(CheckboxColor.DEFAULT)
const prioritizeCheckboxColor = (hasDefaults: boolean) =>
  hasDefaults ? CheckboxColor.DEFAULT : CheckboxColor.PRIMARY
export const aggregateCheckboxColor = (
  getSingleItemColor: (order: Order) => CheckboxProps['color'],
) =>
  R.pipe(
    R.map(getSingleItemColor),
    R.any(isDefaultColor),
    prioritizeCheckboxColor,
  )

export const calculateAvailableQuantity = (order: Order) =>
  order?.prepaidBox
    ? parseFloat(`${order.prepaidBox?.remaining || 0}`) +
      parseFloat(`${order?.quantity || 0}`)
    : null

export const dropOrderPrepaidBox = (order: Order) => ({
  ...R.omit(['prepaid', 'usedQuantity'], order),
  quantity: 1,
})

const fieldsToOmit = ['items', 'usageCount']
export const getOrdersEqual = (orderOne: Order, orderTwo: Order) =>
  R.equals(R.omit(fieldsToOmit, orderOne), R.omit(fieldsToOmit, orderTwo))

export const hasOrderFilter = (type: OrderType, filters: OrderFilter[]) =>
  filters.some((filter) => filter.type === type)

export const hasPrice = (order: Order) => R.hasPath(['price', 'price'], order)

export const hasPriceOrAggregatedPrice = (order: Order) =>
  hasPrice(order) || R.any(hasPrice, order.items || [])

export const getIsRefill = (order: Order) =>
  Boolean(order?.parentId) || order.type === OrderType.REFILL

export const isSoapOutsidePharmacyOrder = ({
  soapId,
  invoiceId,
}: Pick<Order, 'soapId' | 'invoiceId'>) => soapId && !invoiceId

export const hasSelectedAllFromBundle = (
  order: Order,
  isCheckedItem: (item: Order) => boolean,
) => {
  const bundleItems = order.items || []
  return bundleItems.length > 0 && bundleItems.every(isCheckedItem)
}

export const getItemId = (
  item: Pick<
    Order,
    | 'price'
    | 'variation'
    | 'globalVariation'
    | 'globalInventory'
    | 'globalVariationMapping'
    | 'globalInventoryMapping'
  >,
) =>
  item.variation?.id ||
  item.globalVariationMapping?.id ||
  item.globalInventoryMapping?.id ||
  item.globalVariation?.id ||
  item.globalInventory?.id ||
  item.price.id

type UsedQuantityOptions = {
  isEstimate: boolean
  isPrePaidEnabled: boolean
}

export const dropUsedQuantity = (order: Order) =>
  R.omit(['usedQuantity'], order)

export const normalizeOrderUsedQuantity = (
  prepaidValue: number,
  order: Order,
  { isEstimate, isPrePaidEnabled }: UsedQuantityOptions,
) => {
  // Estimates don't have usedQuantity
  if (isEstimate) {
    return dropUsedQuantity(order)
  }

  // Invoices don't have range value at all, so we pick just the high value
  return {
    ...order,
    usedQuantity: isPrePaidEnabled
      ? prepaidValue
      : getHighValue(order.quantity || 1),
  }
}

export const addOrderInitialUsedQuantity = (
  order: Order,
  options: UsedQuantityOptions,
) => {
  const hasPrepaid = order.prepaid && options.isPrePaidEnabled
  const itemQuantity = order.quantity || 1
  const normalizedOrder = normalizeOrderUsedQuantity(0, order, options)

  return {
    ...normalizedOrder,
    quantity: itemQuantity,
    prepaid: hasPrepaid,
  }
}

export function convertInvoiceLineItemToSoapLog(
  invoiceLog: InvoiceLineItem,
): Order {
  const inventory: InventoryItem = {
    id: '',
    activeIngredient: '',
    ageMinimum: 0,
    ageUnitId: '',
    brandName: '',
    business: '',
    categoryId: '',
    compoundable: false,
    controlledSubstanceScheduleId: '',
    creationDate: '',
    description: '',
    genderRestrictions: [],
    globalInventoryItemId: '',
    globalInventoryItemMappingId: '',
    controlled: invoiceLog.inventoryControlled,
    modificationDate: '',
    name: '',
    pdmpReport: false,
    requiresPrescription: false,
    speciesIds: [],
    subcategoryId: '',
    variations: [],
    weightMinimum: 0,
  }

  const variation: Variation = {
    onHandStatusId: '',
    categoryId: '',
    subcategoryId: '',
    active: false,
    businessVariationIds: [],
    creationDate: '',
    description: '',
    globalVariationId: '',
    globalInventoryVariationMappingId: '',
    id: '',
    inStockAmount: '',
    inStockUnitsId: '',
    inventoryItemId: '',
    location: '',
    maxQuantity: 0,
    maxQuantityUnitId: '',
    modificationDate: '',
    name: '',
    nationalDrugCode: '',
    obsolete: false,
    onHandAmount: 1,
    onHandUnitsId: '',
    orderStatusId: '',
    prices: [],
    rabiesTagExpiration: '',
    rabiesTagExpirationOptionId: '',
    rabiesTagExpirationUnitId: '',
    reorderPoint: 1,
    reorderPointUnitId: '',
    reorderQuantity: 1,
    reorderQuantityUnitId: '',
    strengthColorId: '',
    strengthCombo: '',
    strengthUnitsId: '',
    vaccineDefaultAmount: 0,
    vaccineDefaultAmountId: '',
    vaccineDefaultDeliveryLocation: '',
    vaccineDefaultDeliveryLocationId: '',
    vaccineDefaultDoseTypeId: '',
    vaccineDeliveryMethodId: '',
    vaccineDurationOfImmunity: '',
    vaccineDurationOfImmunityOptionId: '',
    vaccineDurationOfImmunityUnitId: '',
    vaccineTypeId: '',
    vetcoveName: '',
    vetcoveSku: '',
    weightMaximum: 1,
    weightMinimum: 0,
    deliveryMethodId: invoiceLog.variationDeliveryMethodId,
    formId: invoiceLog.variationFormId,
    customForm: invoiceLog.variationCustomForm || '',
    perPackageUnitsId: invoiceLog.variationPerPackageUnitsId || '',
    strength: invoiceLog.variationStrength,
    perPackageAmount: invoiceLog.variationPerPackageAmount,
    packageTypeId: invoiceLog.variationPackageTypeId || '',
    count: 0,
    saleUnitOfMeasure: '',
  }

  return {
    ...R.omit(['logType'], invoiceLog),
    id: invoiceLog.logId,
    lineItem: invoiceLog,
    type: invoiceLog.logType,
    items: (invoiceLog.items || []).map(convertInvoiceLineItemToSoapLog),
    labTest: {
      id: invoiceLog.priceItemId,
      type: invoiceLog.labTestType,
      name: invoiceLog.name,
      vendorId: R.defaultTo(null)(invoiceLog.labTestVendorId),
      labOrderId: invoiceLog.labTestOrderId,
      vendorCode: invoiceLog.labTestVendorCode,
      testFor: invoiceLog.labTestTestFor,
      stateId: R.defaultTo(null)(
        invoiceLog.stateId || invoiceLog.stateEntity?.id,
      ),
      notes: invoiceLog.notes,
      bundleId: invoiceLog.bundleId,
      prepaid: invoiceLog.prepaid,
      specimen: invoiceLog.labTestSpecimen,
    } as LabTest,
    price: {
      id: invoiceLog.priceId,
      price: invoiceLog.price,
      taxable: invoiceLog.priceTaxable,
      discountAllowed: invoiceLog.discountAllowed,
      minCharge: invoiceLog.minCharge,
      cost: invoiceLog.cost,
      costUnitsSize: invoiceLog.costUnitsSize,
      dispensingFee: invoiceLog.dispensingFee,
      priceUnitsSize: invoiceLog.priceUnitsSize,
      unitId: invoiceLog.unitId,
      timeUnitId: invoiceLog.priceTimeUnitId,
      minTimeUnitSize: invoiceLog.priceMinTimeUnitSize,
      maxTimeUnitSize: invoiceLog.priceMaxTimeUnitSize,
      timeUnitSize: invoiceLog.priceTimeUnitSize,
    } as Price,
    soapId: invoiceLog.soapId,
    quantity: invoiceLog.quantity,
    usedQuantity: invoiceLog.usedQuantity,
    prepaidBox: {
      remaining: invoiceLog.prepaidRemaining,
    },
    prescriptionType: invoiceLog.prescriptionType,
    procedure: {
      id: invoiceLog.logId,
      procedureCode: invoiceLog.procedureCode,
      description: invoiceLog.procedureDescription,
      modalityId: invoiceLog.procedureModalityId,
    },
    inventory,
    variation,
    modificationDate: invoiceLog.soapLogModificationDate,
    stateId: invoiceLog.stateId || invoiceLog.stateEntity?.id,
  }
}

export function convertRetailOrderLineItemToSoapLog(
  invoiceLog: RetailOrderLineItem,
): Order {
  const inventory: InventoryItem = {
    id: '',
    activeIngredient: '',
    ageMinimum: 0,
    ageUnitId: '',
    brandName: '',
    business: '',
    categoryId: '',
    compoundable: false,
    controlledSubstanceScheduleId: '',
    creationDate: '',
    description: '',
    genderRestrictions: [],
    globalInventoryItemId: '',
    globalInventoryItemMappingId: '',
    controlled: false,
    modificationDate: '',
    name: '',
    pdmpReport: false,
    requiresPrescription: false,
    speciesIds: [],
    subcategoryId: '',
    variations: [],
    weightMinimum: 0,
  }

  const variation: Variation = {
    onHandStatusId: '',
    categoryId: '',
    subcategoryId: '',
    active: false,
    businessVariationIds: [],
    creationDate: '',
    description: '',
    globalVariationId: '',
    globalInventoryVariationMappingId: '',
    id: '',
    inStockAmount: '',
    inStockUnitsId: '',
    inventoryItemId: '',
    location: '',
    maxQuantity: 0,
    maxQuantityUnitId: '',
    modificationDate: '',
    name: '',
    nationalDrugCode: '',
    obsolete: false,
    onHandAmount: 1,
    onHandUnitsId: '',
    orderStatusId: '',
    prices: [],
    rabiesTagExpiration: '',
    rabiesTagExpirationOptionId: '',
    rabiesTagExpirationUnitId: '',
    reorderPoint: 1,
    reorderPointUnitId: '',
    reorderQuantity: 1,
    reorderQuantityUnitId: '',
    strengthColorId: '',
    strengthCombo: '',
    strengthUnitsId: '',
    vaccineDefaultAmount: 0,
    vaccineDefaultAmountId: '',
    vaccineDefaultDeliveryLocation: '',
    vaccineDefaultDeliveryLocationId: '',
    vaccineDefaultDoseTypeId: '',
    vaccineDeliveryMethodId: '',
    vaccineDurationOfImmunity: '',
    vaccineDurationOfImmunityOptionId: '',
    vaccineDurationOfImmunityUnitId: '',
    vaccineTypeId: '',
    vetcoveName: '',
    vetcoveSku: '',
    weightMaximum: 1,
    weightMinimum: 0,
    deliveryMethodId: null,
    formId: null,
    customForm: '',
    perPackageUnitsId: '',
    strength: null,
    perPackageAmount: null,
    packageTypeId: '',
    count: 0,
    saleUnitOfMeasure: '',
  }

  return {
    ...R.omit(['logType'], invoiceLog),
    id: invoiceLog.id,
    lineItem: invoiceLog,
    type: OrderType.PRESCRIPTION,
    items: undefined,
    price: {
      price: invoiceLog.price,
    } as Price,
    soapId: invoiceLog.soap?.id,
    quantity: invoiceLog.quantity,
    usedQuantity: 0,
    prepaidBox: null,
    prescriptionType: PrescriptionType.CHEWY,
    procedure: undefined,
    inventory,
    variation,
    modificationDate: invoiceLog.modificationDate,
    // TODO: M1 - Revisit after https://chewyinc.atlassian.net/browse/CVC-9380
    stateId: '',
  }
}

export const convertOrderToInvoiceLineItem = (
  item: Order,
  isFoodCatalogEnabled: boolean,
): InvoiceLineItem => ({
  bundleId: item.bundleId || null,
  categoryId: getInventoryItemCategoryId(item, isFoodCatalogEnabled),
  clientId: item.clientId || '',
  cost:
    R.path(['lineItem', 'cost'], item) || R.path(['price', 'cost'], item) || 0,
  costUnitsSize: R.path(['price', 'costUnitsSize'], item) || 0,
  creationDate:
    item.creationDate ||
    R.path(['variation', 'creationDate'], item) ||
    R.path(['inventory', 'createionDate'], item) ||
    '',
  declined: R.path(['lineItem', 'declined'], item) || false,
  discountAllowed: R.path(['price', 'discountAllowed'], item) || false,
  discountAmount: R.path(['lineItem', 'discountAmount'], item) || null,
  discountPerc: R.path(['lineItem', 'discountPerc'], item) || null,
  dispensingFee: R.path(['price', 'dispensingFee'], item) || 0,
  extendedPrice: R.path(['lineItem', 'extendedPrice'], item) || 0,
  group: item.group || '',
  groupName: R.path(['lineItem', 'groupName'], item) || item.group || '',
  id: R.path(['lineItem', 'id'], item) || '',
  inventoryControlled: Boolean(R.path(['inventory', 'controlled'], item)),
  invoiceId: item.invoiceId || '',
  items: R.map(
    (itm: Order) => convertOrderToInvoiceLineItem(itm, isFoodCatalogEnabled),
    R.propOr([], 'items', item),
  ),
  labTestOrderId: R.path(['labTest', 'labOrderId'], item) || '',
  labTestSpecimen: R.path(['labTest', 'specimen'], item) || '',
  labTestTestFor: R.path(['labTest', 'testFor'], item) || '',
  labTestType: R.path(['labTest', 'type'], item) || '',
  labTestVendorCode: R.path(['labTest', 'vendorCode'], item) || '',
  labTestVendorId: R.path(['labTest', 'vendorId'], item) || '',
  labTestVendorName: R.path(['labTest', 'vendorName'], item) || '',
  logId: item.id,
  logType: item.type,
  minCharge: R.path(['price', 'minCharge'], item) || null,
  modificationDate: item.modificationDate || '',
  name: item.name,
  notes: item.notes || '',
  orderNotes: item.notes || '',
  origin: item.origin,
  patientId: item.patientId || '',
  prepaid: Boolean(item.prepaid),
  prepaidRemaining: R.path(['prepaidBox', 'remaining'], item) || 0,
  prescriptionType: item.prescriptionType,
  price: item.price,
  priceId: R.path(['price', 'id'], item),
  priceItemId: R.path(['labTest', 'priceItemId'], item) || '',
  priceMaxTimeUnitSize: R.path(['price', 'maxTimeUnitSize'], item) || 0,
  priceMinTimeUnitSize: R.path(['price', 'minTimeUnitSize'], item) || 0,
  priceTaxable: R.path(['price', 'taxable'], item),
  priceTimeUnitId: R.path(['price', 'timeUnitId'], item),
  priceTimeUnitSize: R.path(['price', 'timeUnitSize'], item) || 0,
  priceUnits: R.path(['lineItem', 'priceUnits'], item),
  priceUnitsSize: R.path(['price', 'priceUnitsSize'], item),
  procedureCategoryId: R.path(['procedure', 'procedureCategoryId'], item) || '',
  procedureCode: R.path(['procedure', 'procedureCode'], item) || '',
  procedureDescription: R.path(['procedure', 'description'], item),
  procedureModalityId: R.path(['procedure', 'modalityId'], item) || '',
  procedureOrderId: R.path(['lineItem', 'procedureOrderId'], item) || '',
  quantity: item.quantity || 0,
  soapId: item.soapId || '',
  state: item.stateId || '',
  stateId: item.stateId || '',
  subTotal: R.path(['lineItem', 'subTotal'], item),
  taxAmount: R.path(['lineItem', 'taxAmount'], item),
  taxRate: R.path(['lineItem', 'taxRate'], item),
  taxed: Boolean(R.path(['lineItem', 'taxed'], item)),
  unitId: R.path(['price', 'unitId'], item) || '',
  updatedAt: R.path(['lineItem', 'updatedAt'], item) || '',
  usedQuantity: item.usedQuantity || null,
  variationCustomForm: R.path(['variation', 'customForm'], item),
  variationDeliveryMethodId: R.path(['variation', 'deliveryMethodId'], item),
  variationFormId: R.path(['variation', 'formId'], item),
  variationPackageTypeId: R.path(['variation', 'packageTypeId'], item),
  variationPerPackageAmount: R.path(['variation', 'perPackageAmount'], item),
  variationPerPackageUnitsId: R.path(['variation', 'perPackageUnitsId'], item),
  variationStrength: R.path(['variation', 'strength'], item),
  variationStrengthUnitsId: R.path(['variation', 'strengthUnitsId'], item),
  wplanLogId: R.path(['lineItem', 'wplanLogId'], item) || '',
})
