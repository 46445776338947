import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Nil,
  PermissionArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  patchAppointment,
  updateAppointmentNotes,
} from '~/store/actions/timetable'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getTimetableIsLoading,
  getTimetableIsUpdatingNotes,
} from '~/store/reducers/timetable'
import useFieldsChanged from '~/utils/useFieldsChanged'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
import SoapWidget from '../SoapWidget'
import { useIsSoapCustomizationEnabled } from '../utils/useIsSoapCustomizationEnabled'

export interface ReasonForVisitWidgetUnlockedProps {
  appointmentId: string
  appointmentNotes: string | Nil
}

const ReasonForVisitWidgetUnlocked = ({
  appointmentId,
  appointmentNotes,
}: ReasonForVisitWidgetUnlockedProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const isLoading = useSelector(getTimetableIsLoading)
  const isUpdatingNotes = useSelector(getTimetableIsUpdatingNotes)
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()
  const isRichTextNotesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_DIALOG_ADDITIONAL_FIELDS),
  )

  const [hasFocusedField, setHasFocusedField] = useState(false)

  const { fields, reset } = useFields([
    {
      name: 'notes',
      label: t('Common:REASON_FOR_VISIT'),
      initialValue: appointmentNotes || '',
    },
  ])

  const { notes } = fields

  useEffect(() => {
    if (!hasFocusedField) {
      reset()
    }
  }, [appointmentId])

  useFieldsChanged(() => {
    if (isSoapCustomizationEnabled) {
      dispatch(
        updateAppointmentNotes({
          id: appointmentId,
          notes: notes.value,
          debounced: true,
        }),
      )
    } else {
      dispatch(
        patchAppointment(
          { id: appointmentId, notes: notes.value },
          undefined,
          true,
        ),
      )
    }
  }, fields)

  return (
    <SoapWidget
      ContentBoxProps={{ pl: 2, pr: 5, py: 1 }}
      id="reason-for-visit-widget"
      title={notes.label}
    >
      {isRichTextNotesEnabled ? (
        <NotesTemplateInput
          isSoap
          singleLine
          disabled={!appointmentPermissions.update}
          field={notes}
          maxHeight={150}
          minHeight={30}
        />
      ) : (
        <PuiTextField
          multiline
          disabled={!appointmentPermissions.update}
          field={notes}
          id="primary-complaint-input"
          isLoading={
            isSoapCustomizationEnabled ? isLoading || isUpdatingNotes : false
          }
          maxRows={6}
          minRows={1}
          onBlur={() => {
            setHasFocusedField(false)
          }}
          onFocus={() => {
            setHasFocusedField(true)
          }}
        />
      )}
    </SoapWidget>
  )
}

export default ReasonForVisitWidgetUnlocked
